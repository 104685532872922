import z from "zod";
import { objectMetaSchema } from "./generic";

export const bankTransactionSchema = z.object({
  id: z.string(),
  accountName: z.string(),
  institutionName: z.string(),
  status: z.enum(["PENDING", "POSTED"]),
  accruedAt: z.string(),
  amount: z.number(),
  name: z.string(),
  companyName: z.string().nullable(),
  payerGroupId: z.string().nullable(),
  traceNumber: z.string().nullable(),
  markedPostedByPracticeAt: z.string().nullable(),
  ...objectMetaSchema.shape,
});
export type BankTransaction = z.infer<typeof bankTransactionSchema>;

export const bankAccountSchema = z.object({
  name: z.string(),
  type: z.string(),
  mask: z.string().nullable(),
  ignoredAt: z.string().nullable(),
});
export type BankAccount = z.infer<typeof bankAccountSchema>;

export const bankSourceSchema = z.object({
  id: z.number(),
  logo: z.string().nullable(),
  createdAt: z.string(),
  institutionName: z.string(),
  status: z.enum(["ACTIVE", "USER_ERROR", "ERROR"]),
  vendor: z.enum(["PLAID", "MX"]),

  accounts: z.array(bankAccountSchema),
});
export type BankSource = z.infer<typeof bankSourceSchema>;

export const bankInstitutionSchema = z.object({
  id: z.string(),
  name: z.string(),
  vendor: z.enum(["PLAID", "MX"]),
  vendorId: z.string(),
  logo: z.string().nullable(),
  primaryColor: z.string().nullable(),
  url: z.string().nullable(),
});
export type BankInstitution = z.infer<typeof bankInstitutionSchema>;
