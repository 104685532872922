import type {
  Collection,
  CollectionToModel,
  MutationMessages,
} from "@lassie/types";
import { type EntityState, current } from "@reduxjs/toolkit";
import { type TagDescription, createApi } from "@reduxjs/toolkit/query/react";
import type { Patch } from "immer";
import { toast } from "sonner";
import {
  PostedStatusLabel,
  TransactionStatusLabel,
} from "../../lib/client-types";
import { sendMutation } from "../../lib/mutation";
import { socket } from "../../lib/socket-proxy";
import { syncWorker } from "../../lib/workers";
import type { AppDispatch, RootState, store } from "../root";
import {
  type PatientLedger,
  type PatientLedgerError,
  type Visit,
  getPatientLedgerFromDisk,
  isVisit,
} from "../selectors";
import { baseQuery } from "./rpc";
import {
  QUERY_TAGS,
  type QueryTag,
  QueryTagId,
  createTagProvider,
} from "./tags";
import type { DiskAction } from "./types";

export const diskApi = createApi({
  reducerPath: "disk",
  baseQuery: baseQuery(),
  tagTypes: QUERY_TAGS,
  endpoints: (builder) => ({
    /*
     * Patients
     */
    patients: builder.query<
      DiskAction.Patients["response"],
      DiskAction.Patients["options"]
    >({
      providesTags: createTagProvider("patients", {
        listIds: [QueryTagId.RECENT],
      }),
      query: (options) => ({
        name: "patients",
        options,
      }),
    }),
    /*
     * Ledger
     */
    ledger: builder.query<
      PatientLedger | PatientLedgerError,
      DiskAction.Ledger["options"]
    >({
      providesTags: (result, _error, arg) => {
        const tags: TagDescription<QueryTag>[] = [
          {
            type: "ledger",
            id: arg.patientId,
          },
        ];

        if (!result || result.error !== null) {
          return tags;
        }

        const tasks = result.ledgerItems.flatMap((item) => {
          if (isVisit(item)) {
            return item.eobs.flatMap((eob) => {
              if (!eob.task) {
                return [];
              }

              return [
                {
                  type: "tasks" as const,
                  id: eob.task.id,
                },
              ];
            });
          }

          return [];
        });

        tags.push(
          ...[
            { type: "patients" as const, id: QueryTagId.ALL },
            { type: "claims" as const, id: QueryTagId.ALL },
            { type: "claimProcedures" as const, id: QueryTagId.ALL },
            { type: "procedures" as const, id: QueryTagId.ALL },
            { type: "adjustments" as const, id: QueryTagId.ALL },
            { type: "insurancePayments" as const, id: QueryTagId.ALL },
            { type: "patientPayments" as const, id: QueryTagId.ALL },
            { type: "eobs" as const, id: QueryTagId.ALL },
            { type: "tasks" as const, id: QueryTagId.ALL },
            ...tasks,
          ],
        );

        return tags;
      },
      query: ({ patientId }) => ({
        name: "ledger",
        options: { patientId },
        forceRefetch: true,
      }),
      transformResponse: (response) => {
        if (!!response && "family" in response && "bookEntries" in response) {
          return getPatientLedgerFromDisk(response);
        }

        throw new Error("Invalid response. Expected patient ledger.");
      },
    }),
    /*
     * Payments
     */
    getAllPayments: builder.query<DiskAction.GetAllPayments["response"], void>({
      query: () => ({
        name: "allPayments",
        options: undefined,
      }),
      providesTags: createTagProvider("eobs"),
    }),
    payments: builder.query<
      DiskAction.Payments["response"],
      DiskAction.Payments["options"]
    >({
      query: (options) => ({
        name: "payments",
        options,
      }),
      providesTags: createTagProvider("eobs", {
        listIds: [QueryTagId.RECENT],
      }),
    }),
    unifiedPayments: builder.query<
      DiskAction.UnifiedPayments["response"],
      DiskAction.UnifiedPayments["options"]
    >({
      query: (options) => ({
        name: "unifiedPayments",
        options,
      }),
      providesTags: createTagProvider("eobs", {
        listIds: [QueryTagId.RECENT],
        otherTags: [
          { type: "tasks", id: QueryTagId.ALL },
          { type: "bankTransactions", id: QueryTagId.ALL },
        ],
      }),
    }),
    needsReviewCount: builder.query<
      DiskAction.NeedsReviewCount["response"],
      DiskAction.NeedsReviewCount["options"]
    >({
      query: (options) => ({
        name: "needsReviewCount",
        options,
      }),
      providesTags: createTagProvider("eobs", {
        listIds: [QueryTagId.RECENT],
        otherTags: [
          { type: "tasks", id: QueryTagId.ALL },
          { type: "bankTransactions", id: QueryTagId.ALL },
          { type: "needs-review-count", id: QueryTagId.ALL },
        ],
      }),
    }),
    getPayment: builder.query<
      DiskAction.GetPayment["response"],
      { paymentId: string }
    >({
      query: ({ paymentId }) => ({
        name: "payment",
        options: { paymentId },
      }),
      providesTags: createTagProvider("eobs", {
        listIds: [QueryTagId.RECENT],
        otherTags: [{ type: "tasks", id: QueryTagId.ALL }],
        fromArg: true,
      }),
    }),
    posted: builder.query<
      DiskAction.Posted["response"],
      DiskAction.Posted["options"]
    >({
      query: (options) => ({
        name: "posted",
        options,
      }),
      providesTags: createTagProvider("eobs"),
    }),
    payerGroups: builder.query<DiskAction.PayerGroups["response"], void>({
      query: () => ({
        name: "payerGroups",
        options: undefined,
      }),
    }),
    /*
     * Manages sync event streams
     */
    realtime: builder.query<
      EntityState<CollectionToModel[Collection], number>,
      DiskAction.Realtime["options"]
    >({
      query: ({ token }) => {
        return {
          name: "realtime",
          options: { token },
        };
      },
      async onCacheEntryAdded(
        arg,
        { dispatch, cacheDataLoaded, cacheEntryRemoved },
      ) {
        socket.setAccessToken(arg.token);

        try {
          await cacheDataLoaded;

          // TODO: re-implement event sync
          // const listener = async (event: SyncMessage | DataCommandMessage) => {
          //   if (!Array.isArray(event?.data)) {
          //     if (event.data.action === "reset") {
          //       // on reset, re-bootstrap provided models
          //       // TODO: make bootstrap dispatchable
          //       dispatch(diskApi.util.invalidateTags(event.data.models));

          //       return;
          //     }

          //     // TODO: migrate message types to zod schemas for parsing
          //     logger.warn("Incorrectly formatted sync message:", event);
          //     return;
          //   }

          //   const actions = event.data.filter(Boolean);

          //   logger.log("actions", actions);

          //   for (const action of actions) {
          //     const table = Tables[action.model as keyof typeof Tables];

          //     if (action.action === "delete") {
          //       logger.log(
          //         // @ts-expect-error
          //         `[sync] deleting ${action.model} with id ${action.payload?.id ?? action.payload?.ehrClaimId}`,
          //       );
          //       if ("id" in action.payload) {
          //         // @ts-expect-error
          //         await db.delete(table).where(eq(table.id, action.payload.id));
          //       } else {
          //         logger.error("[sync] unknown delete action", action, table);
          //       }
          //     } else {
          //       logger.log(
          //         // @ts-expect-error
          //         `[sync] upserting ${action.model} with id ${action.payload?.[getPrimaryKey(action.model)]}`,
          //       );

          //       const payload = {
          //         ...action.payload,
          //         // biome-ignore lint/style/noNonNullAssertion: TEMP
          //         practiceUuid: LocalStorage.get("selectedPractice")!,
          //       };

          //       await db
          //         .insert(table)
          //         .values(payload)
          //         .onConflictDoUpdate({
          //           // @ts-expect-error
          //           target: getPrimaryKey(action.model),
          //           set: payload,
          //         });
          //     }

          //     const tags = actions.map((action) => action.model);

          //     dispatch(diskApi.util.invalidateTags(tags));
          //   }
          // };

          // socket.on("sync", listener);
        } catch (error) {
          // @see https://redux-toolkit.js.org/rtk-query/usage/streaming-updates
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
          logger.error(error);
        }
        await cacheEntryRemoved;

        logger.log("[realtime] cache entry removed");
      },
    }),
    patientInbox: builder.query<DiskAction.PatientInbox["response"], void>({
      query: () => ({
        name: "patientInbox",
        options: undefined,
      }),
      providesTags: [
        "ledger-inbox",
        { type: "patients", id: QueryTagId.ALL },
        { type: "tasks", id: QueryTagId.ALL },
      ],
      onQueryStarted: async (_arg, { queryFulfilled, dispatch }) => {
        const result = await queryFulfilled;

        if (!result?.data?.length) {
          return;
        }
      },
    }),
    completedPatientInbox: builder.query<
      DiskAction.CompletedPatientInbox["response"],
      DiskAction.CompletedPatientInbox["options"]
    >({
      query: (options) => ({
        name: "completedPatientInbox",
        options,
      }),
      providesTags: [
        "ledger-inbox",
        "completed-ledger-inbox",
        { type: "patients", id: QueryTagId.ALL },
        { type: "tasks", id: QueryTagId.ALL },
      ],
    }),
    family: builder.query<
      DiskAction.Family["response"],
      DiskAction.Family["options"]
    >({
      providesTags: createTagProvider("patients", {
        otherTags: ["ledger"],
        formatResult: (result) => result?.family ?? null,
      }),
      query: (options) => ({
        name: "family",
        options,
      }),
    }),

    balances: builder.query<
      DiskAction.Balances["response"],
      DiskAction.Balances["options"]
    >({
      providesTags: createTagProvider("patients", {
        listIds: [QueryTagId.RECENT],
      }),
      query: (options) => ({
        name: "balances",
        options,
      }),
    }),

    /**
     * Mutations
     */
    mutation: builder.mutation<
      DiskAction.Mutation["response"],
      DiskAction.Mutation["options"]
    >({
      queryFn: async (mutation) => {
        const mutationPromise = sendMutation(mutation as MutationMessages);

        const response = await mutationPromise;

        logger.log(response);

        if (!response.success) {
          return { error: response.error };
        }

        return {
          data: null,
        };
      },
      onQueryStarted: async (arg, ctx) => {
        const { dispatch, queryFulfilled, getState } = ctx;

        let startTime = performance.now();
        const logNow = () => {
          const logTime = performance.now();
          logger.log(`[mutation] Has took ${logTime - startTime}ms`);
          startTime = logTime;
        };

        const patches: ReturnType<typeof dispatch>[] = [];

        const eobPaymentId =
          "eobPaymentId" in arg.payload ? arg.payload.eobPaymentId : undefined;
        const bankTransactionId =
          "bankTransactionId" in arg.payload
            ? arg.payload.bankTransactionId
            : undefined;

        // Task query updaters

        const eobPaymentOrBankTransactionPatches =
          createEobPaymentOrBankTransactionPatch(
            eobPaymentId,
            bankTransactionId,
            arg.action,
            ctx as any,
          );

        patches.push(...eobPaymentOrBankTransactionPatches);

        if ("patientId" in arg.payload && arg.action === "resolve-balance") {
          const familyId = arg.metadata?.familyId;

          if (!familyId || typeof familyId !== "string") {
            logger.warn("[mutation] familyId is undefined, could not update");
            return;
          }

          logger.log("[mutation] updating family balance for", familyId);
          logNow();
          const familyPatch = dispatch(createFamilyPatch(familyId));
          const balancesPatches = createBalancesPatch(
            familyId,
            arg.action,
            arg.metadata,
            ctx as any,
          );
          patches.push(familyPatch, ...balancesPatches);
        }

        if (
          "taskId" in arg.payload &&
          (arg.action === "complete" || arg.action === "uncomplete")
        ) {
          if (arg.metadata?.paymentId) {
            logger.info("[mutation] updating payment task", arg.payload.taskId);
            const taskPaymentPatch = dispatch(
              createPaymentTaskPatch(
                arg.payload.taskId,
                arg.action,
                arg.metadata,
              ),
            );

            patches.push(taskPaymentPatch);

            if (arg.metadata?.taskType) {
              const paymentPatches = createUnifiedPaymentTaskPatch(
                arg.payload.taskId,
                arg.action,
                arg.metadata,
                ctx as any,
              );
              patches.push(...paymentPatches);
            }
          }

          if (arg.metadata?.patientId) {
            logger.info("[mutation] updating patient task", arg.payload.taskId);
            const taskPatientPatch = dispatch(
              createLedgerTaskPatch(
                arg.payload.taskId,
                arg.action,
                arg.metadata,
              ),
            );
            patches.push(taskPatientPatch);

            const patientInboxPatch = dispatch(
              createPatientInboxTaskPatch(
                arg.payload.taskId,
                arg.action,
                arg.metadata,
              ),
            );
            patches.push(patientInboxPatch);
          }
        }

        logNow();

        const tags: any[] = [];

        if (
          "bankTransactionId" in arg.payload ||
          "eobPaymentId" in arg.payload ||
          (arg.metadata && "paymentId" in arg.metadata)
        ) {
          tags.push({ type: "needs-review-count", id: QueryTagId.ALL });
        }

        if ("taskId" in arg.payload) {
          tags.push({
            type: "tasks",
            id: arg.payload.taskId,
          });

          if (arg.metadata?.paymentId) {
            // if this is a payment task we need to invalidate all task mentions
            tags.push({ type: "tasks", id: QueryTagId.ALL });
          }

          if (arg.metadata?.patientId) {
            tags.push({ type: "ledger", id: arg.metadata.patientId });
            if (arg.action === "uncomplete") {
              tags.push("ledger-inbox");
            }
            tags.push("completed-ledger-inbox");
          }
          if (arg.metadata?.paymentId) {
            tags.push({ type: "payments", id: arg.metadata.paymentId });
          }
        } else if (
          "patientId" in arg.payload &&
          arg.action === "resolve-balance"
        ) {
          // tags.push({ type: "patients", id: arg.payload.patientId });
          // tags.push({ type: "patients", id: QueryTagId.RECENT });

          if (arg.metadata?.familyId) {
            tags.push({ type: "family", id: arg.metadata.familyId });
          }
        }

        logger.log("[visit] INVALIDATING tags", tags);

        try {
          logger.log("[mutation] waiting for query to fulfill");
          logNow();
          await queryFulfilled;

          if ("taskId" in arg.payload) {
            logger.log("[mutation] Fulfilled, writing back to db");
            if (arg.action === "complete") {
              await syncWorker.queryWithParams(
                `UPDATE ${arg.model} SET completedAt = ?, updatedAt = ? WHERE id = ?`,
                [
                  new Date().toISOString(),
                  new Date().toISOString(),
                  arg.payload.taskId,
                ],
              );
            } else if (arg.action === "uncomplete") {
              await syncWorker.queryWithParams(
                `UPDATE ${arg.model} SET completedAt = NULL, updatedAt = ? WHERE id = ?`,
                [new Date().toISOString(), arg.payload.taskId],
              );
            }
          }
          if ("eobPaymentId" in arg.payload) {
            if (arg.action === "received") {
              await syncWorker.queryWithParams(
                `UPDATE ${arg.model} SET receivedByPracticeAt = ?, updatedAt = ? WHERE id = ?`,
                [
                  new Date().toISOString(),
                  new Date().toISOString(),
                  arg.payload.eobPaymentId,
                ],
              );
            }
            if (arg.action === "mark-posted") {
              await syncWorker.queryWithParams(
                `UPDATE ${arg.model} SET markedPostedByPracticeAt = ?, updatedAt = ? WHERE id = ?`,
                [
                  new Date().toISOString(),
                  new Date().toISOString(),
                  arg.payload.eobPaymentId,
                ],
              );
            }
            if (arg.action === "unmark-posted") {
              await syncWorker.queryWithParams(
                `UPDATE ${arg.model} SET markedPostedByPracticeAt = NULL, updatedAt = ? WHERE id = ?`,
                [new Date().toISOString(), arg.payload.eobPaymentId],
              );
            }
          }
          if ("bankTransactionId" in arg.payload) {
            if (arg.action === "mark-posted") {
              await syncWorker.queryWithParams(
                `UPDATE ${arg.model} SET markedPostedByPracticeAt = ?, updatedAt = ? WHERE id = ?`,
                [
                  new Date().toISOString(),
                  new Date().toISOString(),
                  arg.payload.bankTransactionId,
                ],
              );
            }
            if (arg.action === "unmark-posted") {
              await syncWorker.queryWithParams(
                `UPDATE ${arg.model} SET markedPostedByPracticeAt = NULL, updatedAt = ? WHERE id = ?`,
                [new Date().toISOString(), arg.payload.bankTransactionId],
              );
            }
          }
          if ("patientId" in arg.payload) {
            if (arg.action === "resolve-balance") {
              await syncWorker.queryWithParams(
                `UPDATE ${arg.model} SET balance = json_set(balance, '$.resolved', 1, '$.lastResolvedAt', ?), updatedAt = ? WHERE id = ?`,
                [
                  new Date().toISOString(),
                  new Date().toISOString(),
                  arg.payload.patientId,
                ],
              );
            }
          }

          logNow();
        } catch (error) {
          logger.error("[mutation] error:", error);

          for (const patch of patches) {
            try {
              (patch as any).undo();
            } catch (undoError) {
              logger.error("[mutation] error undoing patch:", undoError);
            }
          }

          const errorMessage =
            typeof error === "object" && error !== null && "error" in error
              ? (error.error as string)
              : "Unknown error";

          toast.error(errorMessage || "Error marking task.");
        } finally {
          logger.log("[mutation] complete, invalidating state");
          dispatch(diskApi.util.invalidateTags(tags));
        }

        logNow();
      },
    }),

    // server only queries
    insuranceLogins: builder.query<
      DiskAction.InsuranceLogins["response"],
      void
    >({
      providesTags: ["insuranceLogins"],
      query: () => ({
        name: "insuranceLogins",
        options: undefined,
      }),
    }),

    bankAccounts: builder.query<DiskAction.BankAccounts["response"], void>({
      providesTags: ["bankAccounts"],
      query: () => ({
        name: "bankAccounts",
        options: undefined,
      }),
    }),

    createBankLink: builder.mutation<
      DiskAction.CreateBankLink["response"],
      DiskAction.CreateBankLink["options"]
    >({
      query: (options) => ({
        name: "createBankLink",
        options,
      }),
    }),

    completeBankLink: builder.mutation<
      DiskAction.CompleteBankLink["response"],
      DiskAction.CompleteBankLink["options"]
    >({
      query: (options) => ({
        name: "completeBankLink",
        options,
      }),
    }),

    removeBankLink: builder.mutation<
      DiskAction.RemoveBankLink["response"],
      DiskAction.RemoveBankLink["options"]
    >({
      query: (options) => ({
        name: "removeBankLink",
        options,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        logger.info(`[remove-bank] Removing bank link ${arg.bankSourceId}`);

        await queryFulfilled;

        dispatch(
          diskApi.util.updateQueryData("bankAccounts", undefined, (draft) => {
            draft.bankSources = draft.bankSources.filter(
              (source) => source.id !== arg.bankSourceId,
            );
          }),
        );
      },
    }),

    alerts: builder.query<DiskAction.Alerts["response"], void>({
      query: () => ({
        name: "alerts",
        options: undefined,
      }),
    }),

    search: builder.query<
      DiskAction.Search["response"],
      DiskAction.Search["options"]
    >({
      query: (options) => ({
        name: "search",
        options,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        dispatch(
          diskApi.util.updateQueryData("recentSearches", undefined, (draft) => {
            const existingSearchIdx = draft?.findIndex(
              (search) => search.query === arg.query,
            );

            if (existingSearchIdx !== -1) {
              draft[existingSearchIdx].updatedAt = new Date().toISOString();
              return;
            }

            // otherwise add it to the list
            draft?.push({
              id: -1 * Math.random(),
              query: arg.query,
              updatedAt: new Date().toISOString(),
            });
          }),
        );

        await queryFulfilled;
        dispatch(diskApi.util.invalidateTags(["recent-searches"]));
      },
    }),

    recentSearches: builder.query<DiskAction.RecentSearches["response"], void>({
      query: () => ({
        name: "recentSearches",
        options: undefined,
      }),
      providesTags: ["recent-searches"],
    }),

    clearRecentSearch: builder.mutation<
      DiskAction.ClearRecentSearch["response"],
      DiskAction.ClearRecentSearch["options"]
    >({
      query: (options) => ({
        name: "clearRecentSearch",
        options,
      }),
      onQueryStarted: async (arg, { dispatch }) => {
        dispatch(
          diskApi.util.updateQueryData("recentSearches", undefined, (draft) => {
            if (!draft) {
              logger.warn("[mutation] draft is undefined, could not update");
              return;
            }

            if (arg.id === "all") {
              // clear all
              draft.splice(0, draft.length);
              return;
            }

            const queryIdx = draft.findIndex((search) => search.id === arg.id);
            if (queryIdx === -1) {
              logger.warn("[mutation] search not found in recent searches");
              return;
            }

            draft.splice(queryIdx, 1);
          }),
        );
      },
    }),
  }),
});

export const useMutation = diskApi.useMutationMutation;
export const {
  useLedgerQuery,
  usePatientsQuery,
  useCompletedPatientInboxQuery,
  usePaymentsQuery,
  useRecentSearchesQuery,
  useClearRecentSearchMutation,
  useGetPaymentQuery,
  usePayerGroupsQuery,
  useRealtimeQuery,
  usePrefetch,
  usePatientInboxQuery,
  useFamilyQuery,
  useGetAllPaymentsQuery,
  usePostedQuery,
  useBalancesQuery,
  useInsuranceLoginsQuery,
  useAlertsQuery,
  useBankAccountsQuery,
  useSearchQuery,
  useCreateBankLinkMutation,
  useCompleteBankLinkMutation,
  useRemoveBankLinkMutation,
  useUnifiedPaymentsQuery,
  useNeedsReviewCountQuery,
  util,
} = diskApi;

// patches
type MutationContext = {
  dispatch: AppDispatch;
  getState: () => RootState;
  queryFulfilled: Promise<{ data: null } | { error: any }>;
};

function createFamilyPatch(familyId: string) {
  return diskApi.util.updateQueryData("family", { familyId }, (draft) => {
    if (!draft.family) {
      logger.warn("[mutation] draft is undefined, could not update");
      return draft;
    }

    for (const patient of draft.family) {
      if (patient.balance) {
        patient.balance.resolved = true;
        patient.balance.lastResolvedAt = new Date().toISOString();
      }
    }
  });
}

function createPaymentTaskPatch(
  taskId: string,
  action: "complete" | "uncomplete",
  metadata: MutationMessages["metadata"],
) {
  const paymentId = metadata?.paymentId as string;

  if (!paymentId) {
    throw new Error("invariant: paymentId is required for payment task patch");
  }

  return diskApi.util.updateQueryData("getPayment", { paymentId }, (draft) => {
    if (!draft) {
      logger.warn("[mutation] draft is undefined, could not update");
      return draft;
    }

    const tasks = draft.tasks;
    const taskIdx = tasks.findIndex((t) => t.id === taskId);

    if (taskIdx === -1) {
      logger.warn("[mutation] task not found, could not update");
      return draft;
    }

    logger.log("[mutation] updating task", taskId, action);

    if (action === "complete") {
      tasks[taskIdx].completedAt = new Date().toISOString();
    } else if (action === "uncomplete") {
      tasks[taskIdx].completedAt = null;
    }

    return draft;
  });
}

function createLedgerTaskPatch(
  taskId: string,
  action: "complete" | "uncomplete",
  metadata: MutationMessages["metadata"],
) {
  const patientId = metadata?.patientId as string;

  return diskApi.util.updateQueryData("ledger", { patientId }, (draft) => {
    if (!draft) {
      logger.warn("[mutation] draft is undefined, could not update");
      return draft;
    }

    const visitIdx = draft.ledgerItems.findIndex(
      (li) =>
        "eobs" in li &&
        typeof li.eobs === "object" &&
        li.eobs !== null &&
        li.eobs.some((eob) => "task" in eob && eob.task?.id === taskId),
    );

    if (visitIdx === -1) {
      logger.warn("[mutation] visit not found, could not update");
      return draft;
    }

    const visit = draft.ledgerItems[visitIdx] as Visit;

    const eobIdx = visit.eobs.findIndex(
      (eob) => "task" in eob && eob.task?.id === taskId,
    );

    if (eobIdx === -1) {
      logger.warn("[mutation] visit does not have eob, could not update");
      return draft;
    }

    const eob = visit.eobs[eobIdx];

    if (!eob.task) {
      logger.warn("[mutation] eob does not have task, could not update");
      return draft;
    }

    if (action === "complete") {
      eob.task.completedAt = new Date().toISOString();
    } else if (action === "uncomplete") {
      eob.task.completedAt = null;
    }
  });
}

function createPatientInboxTaskPatch(
  taskId: string,
  action: "complete" | "uncomplete",
  metadata: MutationMessages["metadata"],
) {
  return diskApi.util.updateQueryData("patientInbox", undefined, (draft) => {
    if (!draft) {
      logger.warn("[mutation] draft is undefined, could not update");
      return draft;
    }

    const taskIdx = draft.findIndex((t) => t.id === taskId);

    if (taskIdx === -1) {
      logger.warn("[mutation] task not found, could not update");
      return draft;
    }

    if (action === "complete") {
      draft[taskIdx].completedAt = new Date().toISOString();
    } else if (action === "uncomplete") {
      draft[taskIdx].completedAt = null;
    }
  });
}

function createUnifiedPaymentTaskPatch(
  taskId: string,
  action: "complete" | "uncomplete",
  metadata: MutationMessages["metadata"],
  { dispatch, getState }: MutationContext,
) {
  const paymentId = metadata?.paymentId as string;
  const taskType = metadata?.taskType as string;

  const isPostClaimTask = taskType === "POST_CLAIM";

  if (!paymentId) {
    throw new Error("invariant: paymentId is required for payment task patch");
  }

  /*
   * Get unified payments queries
   */
  const queries = getState().disk.queries;
  const unifiedPaymentsQueries = Object.keys(queries).filter((query: string) =>
    query.startsWith("unifiedPayments("),
  );
  const upQueryArgs = unifiedPaymentsQueries.map(
    (query) => queries?.[query]?.originalArgs,
  );

  const patches: any[] = [];

  for (const args of upQueryArgs) {
    const patch = dispatch(
      diskApi.util.updateQueryData("unifiedPayments", args as any, (draft) => {
        if (!draft.unifiedPayments) {
          logger.warn("[mutation] draft is undefined, could not update");
          return draft;
        }

        const paymentIdx = draft.unifiedPayments.findIndex(
          (up) => up.payment?.id === paymentId,
        );

        if (paymentIdx === -1) {
          return draft;
        }

        const payment = draft.unifiedPayments[paymentIdx];

        if (action === "complete") {
          payment.pendingTasks -= 1;

          if (isPostClaimTask) {
            payment.pendingPostClaimTasks -= 1;

            if (payment.pendingPostClaimTasks === 0) {
              payment.status.postedStatus = PostedStatusLabel.POSTED;
            }
          }
        } else if (action === "uncomplete") {
          payment.pendingTasks += 1;

          if (isPostClaimTask) {
            payment.pendingPostClaimTasks += 1;

            if (payment.pendingPostClaimTasks > 0) {
              payment.status.postedStatus = PostedStatusLabel.PARTIALLY_POSTED;
            }
          }
        }
      }),
    );

    patches.push(patch);
  }

  return patches;
}

function createEobPaymentOrBankTransactionPatch(
  eobPaymentId: string | undefined,
  bankTransactionId: string | undefined,
  action: MutationMessages["action"],
  { dispatch, getState }: MutationContext,
) {
  const patches: any[] = [];

  if (!eobPaymentId && !bankTransactionId) {
    return patches;
  }

  const currentTimestamp = new Date().toISOString();

  /*
   * Get unified payments queries
   */
  const queries = getState().disk.queries;
  const unifiedPaymentsQueries = Object.keys(queries).filter((query: string) =>
    query.startsWith("unifiedPayments("),
  );
  const upQueryArgs = unifiedPaymentsQueries.map(
    (query) => queries?.[query]?.originalArgs,
  );

  /*
   * Update unified payments queries
   */
  for (const args of upQueryArgs) {
    const unifiedPaymentPatch = dispatch(
      diskApi.util.updateQueryData("unifiedPayments", args as any, (draft) => {
        if (!draft.unifiedPayments) {
          logger.warn("[mutation] draft is undefined, could not update");
          return draft;
        }

        // Handle EOB Payment updates
        if (eobPaymentId) {
          const eobPaymentIndex = draft.unifiedPayments.findIndex(
            (up) => up?.payment?.id === eobPaymentId,
          );

          if (eobPaymentIndex !== -1) {
            const up = draft.unifiedPayments[eobPaymentIndex];

            if (up.payment) {
              if (action === "received") {
                up.payment.receivedByPracticeAt = currentTimestamp;
                up.status.transactionStatus =
                  TransactionStatusLabel.RECEIVED_BY_PRACTICE;

                if (up.payment.paymentMethod !== "ACH") {
                  // paper checks get marked at queued
                  up.status.postedStatus = PostedStatusLabel.QUEUED;
                }
              } else if (action === "mark-posted") {
                up.payment.markedPostedByPracticeAt = currentTimestamp;
                up.status.postedStatus = PostedStatusLabel.POSTED;
              } else if (action === "unmark-posted") {
                up.payment.markedPostedByPracticeAt = null;
                up.status.postedStatus = PostedStatusLabel.NOT_POSTED;
              }

              if ((args as any).tab !== "All") {
                logger.log("[mutation] marking payment removed", eobPaymentId);
                up.removed = true;
              }
            }
          }
        }

        // Handle Bank Transaction updates separately
        if (bankTransactionId) {
          const bankTxIndex = draft.unifiedPayments.findIndex(
            (up) => up?.bankTransaction?.id === bankTransactionId,
          );

          if (bankTxIndex !== -1) {
            const up = draft.unifiedPayments[bankTxIndex];

            if (up) {
              if (action === "mark-posted") {
                if (up.bankTransaction) {
                  up.bankTransaction.markedPostedByPracticeAt =
                    currentTimestamp;
                }
                up.status.postedStatus = PostedStatusLabel.POSTED;
              } else if (action === "unmark-posted") {
                if (up.bankTransaction) {
                  up.bankTransaction.markedPostedByPracticeAt = null;
                }
                up.status.postedStatus = PostedStatusLabel.NOT_POSTED;
              }
              if ((args as any).tab !== "All") {
                logger.log(
                  "[mutation] marking payment removed (banktx)",
                  bankTransactionId,
                );
                draft.unifiedPayments[bankTxIndex].removed = true;
              }
            }
          } else {
            logger.warn(
              `[mutation] bank transaction with id ${bankTransactionId} not found, could not update`,
            );
          }
        }
      }),
    );

    patches.push(unifiedPaymentPatch);
  }

  // Handle EOB Payment query updates
  if (eobPaymentId) {
    logger.log("[mutation] updating eob payment", eobPaymentId);

    const eobPaymentPatch = dispatch(
      diskApi.util.updateQueryData(
        "getPayment",
        { paymentId: eobPaymentId },
        (draft) => {
          if (!draft) {
            logger.warn("[mutation] draft is undefined, could not update");
            return draft;
          }

          if (action === "received") {
            draft.payment.receivedByPracticeAt = currentTimestamp;
          } else if (action === "mark-posted") {
            draft.payment.markedPostedByPracticeAt = currentTimestamp;
          } else if (action === "unmark-posted") {
            draft.payment.markedPostedByPracticeAt = null;
          }
        },
      ),
    );

    patches.push(eobPaymentPatch);
    logger.log("[mutation] updating eob payment ledger for", eobPaymentId);
  }

  return patches;
}

function createBalancesPatch(
  familyId: string,
  action: MutationMessages["action"],
  metadata: MutationMessages["metadata"],
  { dispatch, getState }: MutationContext,
) {
  const amount = metadata?.amount as number | undefined;
  const type = metadata?.type as "debit" | "credit" | undefined;

  const patches: any[] = [];

  if (!familyId) {
    return patches;
  }

  /*
   * Get balances queries
   */
  const queries = getState().disk.queries;
  const balanceQueries = Object.keys(queries).filter((query: string) =>
    query.startsWith("balances("),
  );
  const balancesQueryArgs = balanceQueries.map(
    (query) => queries?.[query]?.originalArgs,
  );

  for (const args of balancesQueryArgs) {
    const patch = dispatch(
      diskApi.util.updateQueryData("balances", args as any, (draft) => {
        if (!draft) {
          logger.warn("[mutation] draft is undefined, could not update");
          return draft;
        }

        if (amount) {
          if (type === "debit") {
            draft.debitsAmount -= amount;
          }

          if (type === "credit") {
            draft.creditsAmount -= amount;
          }
        }

        if (type === "debit") {
          draft.debitsCount -= 1;
        }

        if (type === "credit") {
          draft.creditsCount -= 1;
        }

        const patientIdx = draft.patients.findIndex(
          (p) => p.familyId === familyId,
        );

        if (patientIdx === -1) {
          return draft;
        }

        const patient = draft.patients[patientIdx];

        patient.removed = true;
      }),
    );

    patches.push(patch);
  }

  return patches;
}
