import type { Ehr } from "@lassie/types";
import type { Client } from "../lib/client-types";
import type { DiskAction } from "./disk/types";
import { combineClaimsAndProcedures } from "./visit-graph";

export const CLAIM_TYPE_ORDER = [
  "PRIMARY",
  "SECONDARY",
  "CAPITATION",
  "PRE_AUTH",
  "OTHER",
] as const satisfies Client.Claim["claimType"][];

export type Visit = {
  claims: Client.Claim[];
  procedures: Client.Procedure[];
  claimProcedures: Ehr.ClaimProcedure[];
  insurancePayments: Client.InsurancePayment[];
  adjustments: Client.Adjustment[];
  eobs: Client.Eob[];
};

export type LedgerItem =
  | Ehr.PatientPayment
  | Client.Procedure
  | Visit
  | Client.Adjustment;

export type LedgerBalanceEntry = number | "NO_RENDER";

export type PatientLedger = {
  patient: Client.Patient;
  family: Ehr.Patient[];
  ledgerItems: LedgerItem[];
  error: null;
};

export type PatientLedgerError = {
  error: string;
  patient: null;
  family: Ehr.Patient[];
  ledgerItems: [];
};

export function isLedgerProcedure(item: LedgerItem): item is Client.Procedure {
  return "dateOfService" in item;
}

export function isVisit(item: LedgerItem): item is Visit {
  if (!item) return false;
  return "procedures" in item;
}

export function isPatientPayment(item: LedgerItem): item is Ehr.PatientPayment {
  return "details" in item;
}

export function isLedgerAdjustment(
  item: LedgerItem,
): item is Client.Adjustment {
  return "adjustmentAmount" in item;
}

export function getDateFromLedgerItem(item: LedgerItem): string {
  if (isVisit(item)) {
    const procedureDate = item.procedures[0]?.dateOfService;

    const claimDates = item.claims
      .map((c) => c.sentDate)
      .filter((d) => d !== null && d !== undefined)
      .sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

    const earliestClaimDate = claimDates[0];

    return procedureDate || earliestClaimDate;
  }

  if (isPatientPayment(item)) {
    // patient payments
    return item.details.paymentDate;
  }

  if (isLedgerAdjustment(item)) {
    // claim procedure
    return item.adjustmentDate;
  }

  // claim procedure
  return item.dateOfService;
}

/**
 * Sums values of an array of objects by a key
 */
export function sumByKey<
  T extends object,
  TMatchKey extends keyof T,
  TSumKey extends keyof T,
>(array: T[], matchKey: TMatchKey, sumKey: TSumKey): T[] {
  // Create an empty object to store the sums
  const sums: { [key: string]: number } = {};

  // Iterate through each object in the array
  array.forEach((obj) => {
    // Extract the matching key and sum key values from the object
    const matchValue = obj[matchKey] as unknown as string; // Convert to string for use as an object key
    const sumValue = obj[sumKey] as number;

    // If the match value is not in the sums object, initialize it
    if (!sums[matchValue]) {
      sums[matchValue] = 0;
    }

    // Add the sum value to the corresponding match value key in the sums object
    sums[matchValue] += sumValue;
  });

  // Convert the sums object back into an array of objects
  return Object.keys(sums).map((key) => ({
    ...array[0],
    [sumKey]: sums[key],
  })) as T[];
}

export const getPatientLedgerFromDisk = (
  state: DiskAction.Ledger["response"],
): PatientLedger | PatientLedgerError => {
  const {
    patient,
    family,
    claims,
    procedures,
    claimProcedures,
    adjustments,
    insurancePayments,
    patientPayments: rawPatientPayments,
    tasks,
    eobs,
  } = state;

  const patientPayments: Ehr.PatientPayment[] = [];

  const paymentByDetails: Map<string, Ehr.PatientPayment[]> = new Map();

  rawPatientPayments.forEach((p) => {
    if (!paymentByDetails.get(p.details.id)) {
      paymentByDetails.set(p.details.id, []);
    }

    paymentByDetails.get(p.details.id)?.push(p);
  });

  for (const payments of paymentByDetails.values()) {
    let combinedPaymentAmount = 0;

    for (const payment of payments) {
      combinedPaymentAmount += payment.paymentAmount;
    }

    patientPayments.push({
      ...payments[0],
      details: {
        ...payments[0].details,
        paymentAmount: combinedPaymentAmount,
      },
      paymentAmount: combinedPaymentAmount,
    });
  }

  const visits: Visit[] = [];

  const combined = combineClaimsAndProcedures(claims, procedures);

  combined.forEach((group) => {
    // TODO: fix this naming...
    const groupProcedures = group.procedures; // procedures relating to this claim
    const claimClaimProcedures = claimProcedures.filter((cp) =>
      group.claims.some((c) => c.id === cp.claimId),
    ); // claim procedures relating to this claim
    const claimAdjustments = adjustments.filter((a) =>
      group.claims.some((c) => c.id === a.claimId),
    );

    const procedureAdjustments = adjustments.filter(
      (a) =>
        groupProcedures.some((p) => p.id === a.procedureId) &&
        !a.claimProcedureId,
    );

    const claimInsurancePayments = insurancePayments
      .filter((p) => group.claims.some((c) => c.id === p.claimId))
      .filter((p) => Boolean(p.details.id))
      .map((p) => ({
        ...p,
        detailsId: p.details.id,
      }));

    if (groupProcedures.length === 0) {
      return;
    }

    const visitAdjustments = [...claimAdjustments, ...procedureAdjustments];

    visits.push({
      claims: group.claims,
      claimProcedures: claimClaimProcedures,
      procedures: groupProcedures,
      insurancePayments: claimInsurancePayments,
      adjustments: visitAdjustments,
      eobs: eobs
        .filter((eob) => group.claims.some((c) => c.id === eob.ehrClaimId))
        .map((eob) => ({
          ...eob,
          task: tasks.find((t) => t.claimId === eob.claim.id) ?? null,
        })),
    });
  });

  /*
   * Filter out all procedures that are already on a visit
   */
  const remainingProcedures = procedures.filter(
    (p) => !visits.some((v) => v.procedures.some((p2) => p2.id === p.id)),
  );

  const proceduresGroupedByDate = remainingProcedures.reduce(
    (acc, procedure) => {
      const date = procedure.dateOfService;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(procedure);
      return acc;
    },
    {} as Record<string, typeof remainingProcedures>,
  );

  const ledgerProcedures: Client.Procedure[] = [];

  Object.keys(proceduresGroupedByDate).forEach((date) => {
    if (proceduresGroupedByDate[date].length < 2) {
      // if there is only one procedure on a date, its standalone
      ledgerProcedures.push(...proceduresGroupedByDate[date]);
      return;
    }

    // otherwise, its a visit
    visits.push({
      claims: [],
      claimProcedures: [],
      procedures: proceduresGroupedByDate[date],
      insurancePayments: [],
      adjustments: [],
      eobs: [],
    });
  });

  // adjustments are standalone if they are not associated with a claim
  const ledgerAdjustments = adjustments.filter((a) => !a.claimId);

  const ledgerItems = [
    ...patientPayments,
    ...ledgerProcedures,
    ...ledgerAdjustments,
    ...visits,
  ];

  // sort all ledger items
  ledgerItems.sort((a, b) => {
    const dateA = getDateFromLedgerItem(a);
    const dateB = getDateFromLedgerItem(b);

    if (dateA === dateB) {
      // patient payments should always come after visits
      if (isPatientPayment(a) && !isPatientPayment(b)) {
        return -1;
      }

      if (!isPatientPayment(a) && isPatientPayment(b)) {
        return 1;
      }
    }

    return new Date(dateA).getTime() - new Date(dateB).getTime();
  });

  // filter plans
  const filteredPlans = patient.plans.filter((plan) => !isNullishPlan(plan));

  try {
    assertUniqueProcedures(ledgerItems);
  } catch (e) {
    logger.error("[selector] DUPLICATE PROCEDURES", e);
  }

  return {
    patient: {
      ...patient,
      plans: filteredPlans,
    },
    family,
    ledgerItems,
    error: null,
  };
};

function isNullishPlan(plan: Ehr.Plan) {
  return !plan.payerName && !plan.groupName && !plan.groupNumber;
}

function assertUniqueProcedures(ledgerItems: LedgerItem[]) {
  const seenProcedures = new Set<string>();

  for (const item of ledgerItems) {
    if (isLedgerProcedure(item)) {
      if (seenProcedures.has(item.id)) {
        throw new Error(
          `Duplicate standalone procedure ${item.id} has been seen twice`,
        );
      }
      seenProcedures.add(item.id);
    }

    if (isVisit(item)) {
      item.procedures.forEach((p) => {
        if (seenProcedures.has(p.id)) {
          throw new Error(
            `Duplicate procedure(s) on visit, ${p.id} has been seen twice`,
          );
        }
        seenProcedures.add(p.id);
      });
    }
  }

  return true;
}
