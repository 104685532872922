import { EmptyState } from "@lassie/ui/empty-state";
import { IconFileUnknown } from "@tabler/icons-react";
import { useNavigate } from "@tanstack/react-router";

export const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-2">
      <EmptyState
        size="lg"
        icon={<IconFileUnknown size={40} />}
        title="Not Found"
        description="The page you are looking for does not exist or may have been moved."
        actions={[
          {
            children: "Go to homepage",
            onClick: () => navigate({ to: "/" }),
          },
        ]}
      />
    </div>
  );
};
