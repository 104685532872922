import type { Collection, Ehr, PostingMetadata } from "@lassie/types";
import { and, eq, inArray, isNotNull, isNull, sql } from "drizzle-orm";
import type { Client } from "../../lib/client-types";
import { db } from "../../lib/drizzle/db";
import { Tables } from "../../lib/drizzle/schema";
import { LocalStorage } from "../../lib/local-storage";
import { Timer } from "../../lib/timer";
import type { DiskAction } from "./types";

const DEBUG = false;

export const LEDGER_COLLECTIONS = [
  "procedures",
  "claims",
  "adjustments",
  "insurancePayments",
  "patientPayments",
  "claimProcedures",
  "bookEntries",
] as const satisfies Collection[];

export async function ledgerFetcher(patientIds: string[]) {
  if (!patientIds.length) {
    // don't create a db lock if there are no ids
    return [];
  }

  const timer = new Timer("ledgerFetcher", DEBUG);

  const patients = await db
    .select({
      id: Tables.patients.id,
      firstName: Tables.patients.firstName,
      lastName: Tables.patients.lastName,
      dateOfBirth: Tables.patients.dateOfBirth,
      plans: Tables.patients.plans,
      bookId: Tables.patients.bookId,
      familyId: Tables.patients.familyId,
      createdAt: Tables.patients.createdAt,
      updatedAt: Tables.patients.updatedAt,
      deletedAt: Tables.patients.deletedAt,
      balance: sql<number>`(
        SELECT SUM(be.debitAmount) - SUM(be.creditAmount)
        FROM ${Tables.bookEntries} be
          WHERE be.bookId = patients.bookId
        )`,
    })
    .from(Tables.patients)
    .where(
      and(
        inArray(Tables.patients.id, patientIds),
        eq(
          Tables.patients.practiceUuid,
          // biome-ignore lint/style/noNonNullAssertion: TEMP
          LocalStorage.get("selectedPractice")!,
        ),
      ),
    );

  const familyMembers = await db
    .select()
    .from(Tables.patients)
    .where(
      and(
        inArray(
          Tables.patients.familyId,
          patients.map((patient) => patient.familyId),
        ),
        isNull(Tables.patients.deletedAt),
        eq(
          Tables.patients.practiceUuid,
          // biome-ignore lint/style/noNonNullAssertion: TEMP
          LocalStorage.get("selectedPractice")!,
        ),
      ),
    )
    .execute();

  timer.check("queried patients");

  const ledgers = patients.map((patient) => ({
    patientId: patient.id,
    patient,
    family: familyMembers.filter(
      (familyMember) => familyMember.familyId === patient.familyId,
    ),
  }));

  timer.check("mapped ledgers");

  const result = {} as {
    claims: Ehr.Claim[];
    procedures: Client.Procedure[];
    claimProcedures: Ehr.ClaimProcedure[];
    insurancePayments: Ehr.InsurancePayment[];
    patientPayments: Ehr.PatientPayment[];
    bookEntries: Ehr.BookEntry[];
    adjustments: Client.Adjustment[];
    tasks: Client.Task[];
    eobs: Client.Eob[];
  } satisfies Record<(typeof LEDGER_COLLECTIONS)[number], unknown[]>;

  for (const table of LEDGER_COLLECTIONS) {
    if (table === "procedures") {
      /**
       * Embed procedure codes onto procedures
       */
      // @ts-expect-error -- left join results in nullish provider
      //                     but we'll filter them out later
      const procedures: Client.Procedure[] = await db
        .select({
          id: Tables.procedures.id,
          createdAt: Tables.procedures.createdAt,
          updatedAt: Tables.procedures.updatedAt,
          deletedAt: Tables.procedures.deletedAt,
          journalEntryId: Tables.procedures.journalEntryId,
          dateOfService: Tables.procedures.dateOfService,
          claimIds: Tables.procedures.claimIds,
          ledgerAmount: Tables.procedures.ledgerAmount,
          ehrNumber: Tables.procedures.ehrNumber,
          patientId: Tables.procedures.patientId,
          tooth: Tables.procedures.tooth,

          provider: {
            firstName: Tables.providers.firstName,
            lastName: Tables.providers.lastName,
            abbreviation: Tables.providers.abbreviation,
          },

          // code
          procedureCode: Tables.procedureCodes.procedureCode,
          description: Tables.procedureCodes.description,
          friendlyDescription: Tables.procedureCodes.friendlyDescription,
        })
        .from(Tables.procedures)
        .leftJoin(
          Tables.procedureCodes,
          eq(Tables.procedures.procedureCodeId, Tables.procedureCodes.id),
        )
        .leftJoin(
          Tables.providers,
          eq(Tables.procedures.providerId, Tables.providers.id),
        )
        .where(
          and(
            inArray(Tables.procedures.patientId, patientIds),
            isNull(Tables.procedures.deletedAt),
          ),
        )
        .execute();

      result[table] = procedures;
    } else if (table === "adjustments") {
      /**
       * Embed provider onto adjustments
       */
      // @ts-expect-error -- left join results in nullish provider
      //                     but we'll filter them out later
      const adjustments: Client.Adjustment[] = await db
        .select({
          id: Tables.adjustments.id,
          claimId: Tables.adjustments.claimId,
          claimProcedureId: Tables.adjustments.claimProcedureId,
          patientId: Tables.adjustments.patientId,
          // skip providerId
          adjustmentAmount: Tables.adjustments.adjustmentAmount,
          adjustmentDirection: Tables.adjustments.adjustmentDirection,
          adjustmentDate: Tables.adjustments.adjustmentDate,
          adjustmentType: Tables.adjustments.adjustmentType,
          note: Tables.adjustments.note,
          procedureId: Tables.adjustments.procedureId,
          createdAt: Tables.adjustments.createdAt,
          updatedAt: Tables.adjustments.updatedAt,
          deletedAt: Tables.adjustments.deletedAt,
          journalEntryId: Tables.adjustments.journalEntryId,
          provider: {
            firstName: Tables.providers.firstName,
            lastName: Tables.providers.lastName,
            abbreviation: Tables.providers.abbreviation,
          },
        })
        .from(Tables.adjustments)
        .leftJoin(
          Tables.providers,
          eq(Tables.adjustments.providerId, Tables.providers.id),
        )
        .where(
          and(
            inArray(Tables.adjustments.patientId, patientIds),
            isNull(Tables.adjustments.deletedAt),
          ),
        )
        .execute();

      result[table] = adjustments;
    } else if (table === "bookEntries") {
      // const bookEntries: Client.BookEntry[] = await db
      //   .select({
      //     id: Tables.bookEntries.id,
      //     creditAmount: Tables.bookEntries.creditAmount,
      //     debitAmount: Tables.bookEntries.debitAmount,
      //     journalEntryId: Tables.bookEntries.journalEntryId,
      //     journalEntryType: Tables.bookEntries.journalEntryType,
      //     entryDate: Tables.bookEntries.entryDate,
      //     bookId: Tables.bookEntries.bookId,
      //     createdAt: Tables.bookEntries.createdAt,
      //     updatedAt: Tables.bookEntries.updatedAt,
      //     deletedAt: Tables.bookEntries.deletedAt,
      //     modelId: sql<string | null>`
      //       CASE
      //         WHEN ${Tables.bookEntries.journalEntryType} = 'EHR_PATIENT_PAYMENT' THEN ${Tables.patientPayments.id}
      //         WHEN ${Tables.bookEntries.journalEntryType} = 'EHR_INSURANCE_PAYMENT' THEN ${Tables.insurancePayments.id}
      //         WHEN ${Tables.bookEntries.journalEntryType} = 'EHR_PROCEDURE' THEN ${Tables.procedures.id}
      //         WHEN ${Tables.bookEntries.journalEntryType} = 'EHR_ADJUSTMENT' THEN ${Tables.adjustments.id}
      //         ELSE NULL
      //       END`.as("modelId"),
      //   })
      //   .from(Tables.bookEntries)
      //   .leftJoin(
      //     Tables.patientPayments,
      //     and(
      //       eq(Tables.bookEntries.journalEntryType, "EHR_PAYMENT"),
      //       eq(
      //         Tables.bookEntries.journalEntryId,
      //         Tables.patientPayments.journalEntryId,
      //       ),
      //     ),
      //   )
      //   .leftJoin(
      //     Tables.insurancePayments,
      //     and(
      //       eq(Tables.bookEntries.journalEntryType, "EHR_PAYMENT"),
      //       eq(
      //         Tables.bookEntries.journalEntryId,
      //         Tables.insurancePayments.journalEntryId,
      //       ),
      //     ),
      //   )
      //   .leftJoin(
      //     Tables.procedures,
      //     and(
      //       eq(Tables.bookEntries.journalEntryType, "EHR_PROCEDURE"),
      //       eq(
      //         Tables.bookEntries.journalEntryId,
      //         Tables.procedures.journalEntryId,
      //       ),
      //     ),
      //   )
      //   .leftJoin(
      //     Tables.adjustments,
      //     and(
      //       eq(Tables.bookEntries.journalEntryType, "EHR_ADJUSTMENT"),
      //       eq(
      //         Tables.bookEntries.journalEntryId,
      //         Tables.adjustments.journalEntryId,
      //       ),
      //     ),
      //   )
      //   .where(
      //     and(
      //       isNotNull(Tables.bookEntries.journalEntryId),
      //       inArray(
      //         Tables.bookEntries.bookId,
      //         patients.map((patient) => patient.bookId),
      //       ),
      //     ),
      //   )
      //   .orderBy(asc(Tables.bookEntries.entryDate))
      //   .execute();

      // result[table] = bookEntries;
      result[table] = [];
    } else if (table === "claims") {
      const claims = await db
        .select({
          claimData: Tables.claims,
          postingMetadata: Tables.eobClaims.postingMetadata,
        })
        .from(Tables.claims)
        .leftJoin(
          Tables.eobClaims,
          eq(Tables.claims.id, Tables.eobClaims.ehrClaimId),
        )
        .where(
          and(
            inArray(Tables.claims.patientId, patientIds),
            isNull(Tables.claims.deletedAt),
          ),
        )
        .execute();

      const flattenedClaims = claims.map((claim) => ({
        ...claim.claimData,
        postingMetadata: claim.postingMetadata ?? [],
      }));

      result[table] = flattenedClaims;
    } else {
      let queryResult = await db
        .select()
        .from(Tables[table])
        .where(
          and(
            inArray(Tables[table].patientId, patientIds),
            isNull(Tables[table].deletedAt),
          ),
        )
        .execute();

      if (table === "patientPayments" || table === "insurancePayments") {
        queryResult = queryResult
          .filter(
            (payment) =>
              // assert that the results payments
              "details" in payment &&
              "paymentAmount" in payment &&
              typeof payment.details === "object" &&
              payment.details !== null,
          )
          .map((payment) => ({
            ...payment,
            // drizzle can return an array for json columns
            details:
              "details" in payment
                ? Array.isArray(payment.details)
                  ? payment.details[0]
                  : payment.details
                : undefined,
          }));
      }

      // TODO: fix type mapping
      result[table] = queryResult as any;
    }
  }

  timer.check("queried tables");

  // Find and map tasks to the claims
  const patientClaimsIds = result.claims.map((claim) => ({
    claimId: claim.id,
    patientId: claim.patientId,
  }));

  const patientIdToTasks = {} as Record<string, Client.Task[]>;
  const patientIdToEobs = {} as Record<string, Client.Eob[]>;

  if (patientClaimsIds) {
    const eobResults = await db
      .select({
        eobClaim: Tables.eobClaims,
        eobPayment: Tables.eobPayments,
        ehrClaimId: Tables.eobClaims.ehrClaimId,
        task: Tables.tasks,
      })
      .from(Tables.eobClaims)
      .leftJoin(Tables.tasks, eq(Tables.tasks.claimId, Tables.eobClaims.id))
      .innerJoin(
        Tables.eobPayments,
        eq(Tables.eobClaims.paymentId, Tables.eobPayments.id),
      )
      .where(
        inArray(
          Tables.eobClaims.ehrClaimId,
          patientClaimsIds.map((claim) => claim.claimId),
        ),
      )
      .execute();

    for (const eobResult of eobResults) {
      const patientId = patientClaimsIds.find(
        (claim) => claim.claimId === eobResult.ehrClaimId,
      )?.patientId;

      if (patientId) {
        if (!patientIdToEobs[patientId]) {
          patientIdToEobs[patientId] = [];
        }

        patientIdToEobs[patientId].push({
          claim: eobResult.eobClaim,
          ehrClaimId: eobResult.ehrClaimId ?? "",
          payment: eobResult.eobPayment,
          task: eobResult.task,
        });
      }
    }

    timer.check("mapped eobs");

    const tasksWithEhrClaimsId = await db
      .select({
        task: Tables.tasks,
        ehrClaimId: Tables.eobClaims.ehrClaimId,
      })
      .from(Tables.tasks)
      .innerJoin(
        Tables.eobClaims,
        eq(Tables.tasks.claimId, Tables.eobClaims.id),
      )
      .where(
        and(isNotNull(Tables.tasks.claimId), isNull(Tables.tasks.deletedAt)),
      )
      .execute();

    for (const taskWithEhrClaimId of tasksWithEhrClaimsId) {
      const patientIdMatch = patientClaimsIds.find((claim) => {
        if (!taskWithEhrClaimId.ehrClaimId) {
          // this shouldn't happen
          // console.error("NO EOB TO EHR CLAIMS", taskAndEob);
          return false;
        }

        return claim.claimId === taskWithEhrClaimId.ehrClaimId;
      });

      const patientId = patientIdMatch?.patientId;

      if (patientId) {
        if (!patientIdToTasks[patientId]) {
          patientIdToTasks[patientId] = [];
        }

        patientIdToTasks[patientId].push(taskWithEhrClaimId.task);
      }
    }
  }

  timer.check("mapped tasks");

  const finalLedgers = ledgers.map((ledger) => {
    const response = {
      ...ledger,
    } as unknown as DiskAction.Ledger["response"];

    for (const [collection, data] of Object.entries(result)) {
      // TODO: clean up
      if (collection === "eobs" || collection === "tasks") {
        // handled separately below
        continue;
      }

      if (collection === "bookEntries") {
        // @ts-expect-error -- we know this is a valid collection
        response.bookEntries = data.filter(
          (item) => "bookId" in item && item.bookId === ledger.patient.bookId,
        );
      } else {
        // @ts-expect-error -- we know this is a valid collection
        response[collection] = data.filter(
          (item) => "patientId" in item && item.patientId === ledger.patientId,
        );
      }
    }

    response.tasks = patientIdToTasks[ledger.patientId] ?? [];
    response.eobs = patientIdToEobs[ledger.patientId] ?? [];

    return response as DiskAction.Ledger["response"];
  });

  timer.check("finalized ledgers");
  timer.elapsed();

  return finalLedgers;
}
