import { ALL_COLLECTIONS } from "@lassie/types";
import type { EntityId } from "@reduxjs/toolkit";
import type { TagDescription } from "@reduxjs/toolkit/query";

export const QUERY_TAGS = [
  ...ALL_COLLECTIONS,
  "tasks",
  "eobs",
  "ledger",
  "ledger-inbox",
  "payments-inbox",
  "completed-ledger-inbox",
  "recent-searches",
  "needs-review-count",
] as const;

export type QueryTag = (typeof QUERY_TAGS)[number];

export enum QueryTagId {
  /** Use when changing order of recent patients/payments */
  RECENT = "RECENT",
  /** Use when invalidating all of a collection */
  ALL = "ALL",
}

type TagProviderOptions<ResponseType> = {
  /**
   * The list of listIds that should be included in the tag provider.
   *
   * @default []
   */
  listIds?: QueryTagId[];

  /**
   * If true, the tag provider will include tags from the argument.
   *
   * @default false
   */
  fromArg?: boolean;

  /**
   * the ALL tag is always included by default, so if you are using this tag provider
   * in a situation where you want to avoid invalidating when the entire
   * collection is invalidated, set this to `true`.
   *
   * Thing carefully about this flag. Opting out of including `all` tags
   * when invalidating a collection can cause unexpected behavior.
   *
   * @default false
   */
  ignoreAll?: boolean;

  /**
   * Other tags that should be included in the tag provider.
   *
   * e.g. 'ledger-inbox', etc
   * @default []
   */
  otherTags?: TagDescription<QueryTag>[];

  /**
   * A function that formats the result before it is used to generate tags.
   *
   * @default undefined
   */
  formatResult?: (result: ResponseType) => unknown;
};

export function createTagProvider<ResponseType, ArgumentType>(
  tag: QueryTag,
  options: TagProviderOptions<ResponseType> = {
    listIds: [],
    fromArg: false,
    ignoreAll: false,
    otherTags: [],
  },
) {
  return (
    result: ResponseType,
    error: unknown,
    arg: ArgumentType,
  ): TagDescription<QueryTag>[] => {
    const tags: TagDescription<QueryTag>[] = [];

    if (options.otherTags) {
      tags.push(...options.otherTags);
    }

    if (!options.ignoreAll) {
      // Always include the ALL tag unless the user opts out
      tags.push({ type: tag, id: QueryTagId.ALL });
    }

    // Include generic list id tags (e.g. RECENT)
    if (options.listIds) {
      for (const listId of options.listIds) {
        tags.push({ type: tag, id: listId });
      }
    }

    let formattedResult: unknown = result;

    if (options.formatResult) {
      formattedResult = options.formatResult(result);
    }

    if (isEntityCollection(formattedResult)) {
      formattedResult.forEach((item) => {
        if ("id" in item) {
          tags.push({ type: tag, id: item.id });
        }
      });
    }
    // If the result is a single object with an id, add an id-specific tag
    if (hasEntityId(formattedResult)) {
      tags.push({ type: tag, id: formattedResult.id });
    }

    if (
      // if the user specifies that we should get tags from the arg
      // we check if the arg is an object with and entityId property
      options.fromArg &&
      hasEntityId(arg)
    ) {
      tags.push({ type: tag, id: arg.id });
    }

    return tags;
  };
}

function hasEntityId(obj: unknown): obj is { id: EntityId } {
  return (
    typeof obj === "object" &&
    obj !== null &&
    "id" in obj &&
    (typeof obj.id === "string" || typeof obj.id === "number")
  );
}

function isEntityCollection(obj: unknown): obj is { id: EntityId }[] {
  return Array.isArray(obj) && obj.length > 0 && hasEntityId(obj[0]);
}
