import { Icon, type IconName, type IconProps } from "./icons";

type InsuranceIconGroup = Exclude<
  IconName,
  "amex" | "visa" | "mastercard" | "discover"
>;

type InsuranceInput =
  | {
      insurance: InsuranceIconGroup;
    }
  | {
      match: string;
    };

type InsuranceIconProps = InsuranceInput & Omit<IconProps, "name">;

export const InsuranceIcon = (props: InsuranceIconProps) => {
  let insurance: InsuranceIconGroup;

  if ("insurance" in props) {
    insurance = props.insurance;
  } else {
    insurance = matchInsurance(props.match);
  }

  return (
    <Icon {...props} name={insurance} data-sentry-unblock data-sentry-unmask />
  );
};

export const KNOWN_DELTA_ACRONYMS = [
  "DDWI",
  "DDAZ",
  "DDCA",
  "DDMN",
  "DDMA",
  "DDIL",
  "DDWA",
  "DDNJ",
  "DDCO",
  "DDPAR",
];

function matchInsurance(target: string): InsuranceIconGroup {
  const normalized = target.toLowerCase();
  if (normalized.startsWith("zelis payments")) {
    return "zelis";
  }
  if (normalized.includes("united concordia")) {
    return "genericInsurance";
  }

  if (normalized.includes("lincoln")) {
    return "lincoln";
  }

  if (normalized.includes("sunlife") || normalized.includes("sun life")) {
    return "sunlife";
  }

  if (normalized.includes("standard insurance company")) {
    return "standard";
  }

  if (normalized.includes("dentalxchange") && !normalized.includes("delta")) {
    return "dentalXChange";
  }

  if (normalized.includes("geha")) {
    return "geha";
  }
  if (normalized.includes("beam")) {
    return "beam";
  }

  if (normalized.includes("cigna")) {
    return "cigna";
  }
  if (normalized.includes("aetna")) {
    return "aetna";
  }
  if (normalized.includes("ameritas")) {
    return "ameritas";
  }
  if (normalized.includes("change healthcare")) {
    return "changeHealthcare";
  }
  if (
    normalized.includes("delta") ||
    KNOWN_DELTA_ACRONYMS.includes(normalized.toUpperCase())
  ) {
    return "deltaDental";
  }

  if (normalized.includes("principal")) {
    return "principal";
  }

  if (normalized.includes("humana")) {
    return "humana";
  }

  if (normalized.includes("metlife") || normalized.includes("met life")) {
    return "metlife";
  }

  if (normalized.includes("optum")) {
    return "optum";
  }

  if (
    normalized.includes("uhc") ||
    normalized.includes("umr") ||
    normalized.includes("united")
  ) {
    return "uhc";
  }

  if (normalized.includes("guardian")) {
    return "guardian";
  }

  if (
    normalized.includes("blue cross") ||
    normalized.includes("blue shield") ||
    normalized.includes("bluecross") ||
    normalized.includes("bcbs") ||
    normalized.includes("anthem") ||
    (normalized.includes("horizon") && normalized.includes("nj")) || // horizon nj is bcbs
    normalized.split(" ").some((word) => word === "fep") // bcbs fep
  ) {
    return "blueCross";
  }

  return "genericInsurance";
}
