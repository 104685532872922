import z from "zod";
import { deletableSchema, objectMetaSchema } from "./generic";

export const userSchema = z.object({
  uuid: z.string(),
  email: z.string(),
  type: z.enum(["INTERNAL", "EXTERNAL"]),
});
export type User = z.infer<typeof userSchema>;

export const practiceSchema = z.object({
  id: z.number(),
  name: z.string(),
  ehrType: z.enum(["DENTRIX", "OPENDENTAL", "EAGLESOFT"]),
  timezone: z.string(),
  practiceGroupId: z.string(),
  uuid: z.string(),
});
export type Practice = z.infer<typeof practiceSchema>;

export const taskSchema = z.object({
  id: z.string(),
  note: z.string().nullable(),
  type: z.enum([
    "FILE_SECONDARY_CLAIM",
    "ADD_SECONDARY_PLAN",
    "REFILE_CLAIM",
    "ADJUST_CLAIM",
    "POST_CLAIM",
    "RESOLVE_PAYMENT",
    "REVIEW_CLAIM",
    "ONBOARDING",
    "OTHER",
  ]),
  claimId: z.string().nullable(),
  paymentId: z.string().nullable(),
  bankTransactionId: z.string().nullable(),
  completedAt: z.string().nullable(),
  ...objectMetaSchema.shape,
  ...deletableSchema.shape,
});
export type Task = z.infer<typeof taskSchema>;

export const postingAdjustmentSchema = z.object({
  procedureEhrNumber: z.number(),
  case: z.string(),
  amount: z.number(),
  direction: z.enum(["CREDIT", "DEBIT"]),
});
export type PostingAdjustment = z.infer<typeof postingAdjustmentSchema>;

/*
 * EOB Models
 */
export const eobProcedureNoteSchema = z.object({
  code: z.string(),
  note: z.string(),
  groupCode: z.string().nullable(),
  reasonCode: z.string().nullable(),
});
export type EobProcedureNote = z.infer<typeof eobProcedureNoteSchema>;

export const postingMetadataSchema = z.object({
  amount: z.number(),
  target: z.enum(["PRIMARY", "SECONDARY"]),
  category: z.string(),
  provider: z.string(),
  procedureCode: z.string(),
  adjustmentCase: z.string().nullable(),
  ehrProcedureId: z.number(),
});
export type PostingMetadata = z.infer<typeof postingMetadataSchema>;

const eobBaseProcedureSchema = z.object({
  tooth: z.string().nullable(),
  surface: z.string().nullable(),
  notes: z.array(eobProcedureNoteSchema),
  procedureCode: z.string(),
  dateOfService: z.string(),
  primaryAmount: z.number(),
  patientAmount: z.number(),
  paymentAmount: z.number(),
  acceptedAmount: z.number(),
  submittedAmount: z.number(),
  deductibleAmount: z.number(),
});

const returnedEobProcedureSchema = eobBaseProcedureSchema.extend({
  returned: z.null(),
  submitted: z.array(eobBaseProcedureSchema),
});

export type ReturnedEobProcedure = z.infer<typeof returnedEobProcedureSchema>;

const submittedEobProcedureSchema = eobBaseProcedureSchema.extend({
  returned: z.array(eobBaseProcedureSchema),
  submitted: z.null(),
});

export type SubmittedEobProcedure = z.infer<typeof submittedEobProcedureSchema>;

const standardEobProcedureSchema = eobBaseProcedureSchema.extend({
  returned: z.null(),
  submitted: z.null(),
});

export const eobProcedureSchema = z.union([
  returnedEobProcedureSchema,
  submittedEobProcedureSchema,
  standardEobProcedureSchema,
]);
export type EobProcedure = z.infer<typeof eobProcedureSchema>;

export const eobClaimSchema = z.object({
  id: z.string(),
  paymentId: z.string(),
  patientName: z.string(),
  patientDateOfBirth: z.string().nullable(),
  claimNumber: z.string(),
  groupName: z.string().nullable(),
  groupNumber: z.string().nullable(),
  dateOfService: z.string().nullable(),
  subscriberName: z.string().nullable(),
  subscriberId: z.string().nullable(),
  subscriberDateOfBirth: z.string().nullable(),
  paymentAmount: z.number(),
  acceptedAmount: z.number(),
  primaryAmount: z.number(),
  patientAmount: z.number(),
  deductibleAmount: z.number(),
  adjustmentAmount: z.number(),
  submittedAmount: z.number(),
  status: z.enum([
    "POSTED_BY_US",
    "POSTED_BY_PRACTICE",
    "SKIPPED",
    "ATTEMPTED",
    "CREATED",
  ]),
  pdfPath: z.string().nullable(),
  processedAt: z.string().nullable(),

  // ehr
  ehrClaimId: z.string().nullable(),
  ehrPatientId: z.string().nullable(),

  // flattened
  procedures: z.array(eobProcedureSchema).nullable(),
  postingMetadata: z.array(postingMetadataSchema).nullable(),

  ...objectMetaSchema.shape,
});
export type EobClaim = z.infer<typeof eobClaimSchema>;

export const eobPaymentSchema = z.object({
  id: z.string(),
  bankTransactionId: z.string().nullable(),
  paymentNumber: z.string(),
  paymentAmount: z.number(),
  paymentDate: z.string(),
  adjustmentAmount: z.number(),
  paymentMethod: z.enum(["ACH", "CHECK", "VCC", "NON_PAYMENT"]),
  payerName: z.string(),
  payerGroupId: z.string(),
  pdfPath: z.string().nullable(),
  receivedByPracticeAt: z.string().nullable(),
  markedPostedByPracticeAt: z.string().nullable(),
  ...objectMetaSchema.shape,
});
export type EobPayment = z.infer<typeof eobPaymentSchema>;

export const eobSchema = z.object({
  id: z.string(), // claim id
  ehrClaimId: z.string(),
  claim: eobClaimSchema,
  payment: eobPaymentSchema,
});
export type Eob = z.infer<typeof eobSchema>;

export const payerGroupSchema = z.object({
  id: z.string(),
  name: z.string(),
  postingStartDate: z.string().nullable(),
  ...objectMetaSchema.shape,
});
export type PayerGroup = z.infer<typeof payerGroupSchema>;

export const insuranceLoginSchema = z.object({
  id: z.string(),
  source: z.string(),
  sourceName: z.string(),
  username: z.string(),
  password: z.string(),
  error: z.string().nullable(),
});
export type InsuranceLogin = z.infer<typeof insuranceLoginSchema>;

// alerts
export enum AlertSeverity {
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
  SUCCESS = "success",
}

export interface PracticeAlert {
  title: string;
  description: string;
  severity: AlertSeverity;
}
