import type { Socket as SocketEvents } from "@lassie/types";
import * as Comlink from "comlink";
import type { SocketProxy } from "../workers/shared-socket";
import SocketSharedWorkerUrl from "../workers/shared-socket?sharedworker&url";

if (!globalThis.SharedWorker) {
  throw new Error("SharedWorker not supported");
}

const sharedWorker = new SharedWorker(SocketSharedWorkerUrl, {
  type: "module",
  name: "lassie.shared-socket",
});

type EventCallback = (...args: any[]) => void;

export class SharedSocketClient {
  private worker: SharedWorker;
  private proxy: Comlink.Remote<SocketProxy>;
  private eventListeners: Record<string, EventCallback[]> = {};
  private unsubscribe: (() => void) | null = null;

  constructor() {
    this.worker = sharedWorker;
    this.worker.port.start();
    this.proxy = Comlink.wrap<SocketProxy>(this.worker.port);

    this.setupSubscription();
  }

  private async setupSubscription() {
    try {
      this.unsubscribe = await this.proxy.subscribe(
        Comlink.proxy((eventName: string, ...args: any[]) => {
          logger.info("[shared-socket] event", eventName, ...args);
          this.handleEvent(eventName, ...args);
        }),
      );
    } catch (error) {
      console.error("[shared-socket] setupSubscription", error);
    }
  }

  async connected(): Promise<boolean> {
    return this.proxy.connected();
  }

  async connect(): Promise<void> {
    await this.proxy.connect();
  }

  async disconnect(): Promise<void> {
    await this.proxy.disconnect();
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = null;
    }
  }

  async setAccessToken(token: string): Promise<void> {
    await this.proxy.setAccessToken(token);
  }

  async emit<Event extends keyof SocketEvents.ClientEvents>(
    event: Event,
    ...args: Parameters<SocketEvents.ClientEvents[Event]>
  ): Promise<void> {
    if (!this.proxy) {
      throw new Error("Proxy not initialized");
    }

    const maybeAck = args[args.length - 1];
    const otherArgs = args.slice(0, -1);
    let ack = undefined;

    if (typeof maybeAck === "function") {
      ack = Comlink.proxy(maybeAck);
    } else {
      // the last argument isn't a function, but still we need to include it in otherArgs

      // TODO @siraj
      // @ts-expect-error - fix me
      otherArgs.push(maybeAck);
    }

    try {
      // TODO @siraj
      // @ts-expect-error - fix me
      await this.proxy.emit(event, ...otherArgs, ack);
    } catch (error) {
      console.error("Error in emit:", error);
      throw error;
    }
  }

  on<Event extends keyof SocketEvents.ServerEvents>(
    event: Event,
    callback: SocketEvents.ServerEvents[Event],
  ): void {
    if (!this.eventListeners[event]) {
      this.eventListeners[event] = [];
    }

    this.eventListeners[event].push(callback);
  }

  off<Event extends keyof SocketEvents.ServerEvents>(
    event: Event,
    callback: SocketEvents.ServerEvents[Event],
  ): void {
    const index = this.eventListeners[event].indexOf(callback);
    if (index !== -1) {
      this.eventListeners[event].splice(index, 1);
    }
  }

  private handleEvent(eventName: string, ...args: any[]): void {
    const listeners = this.eventListeners[eventName];

    if (listeners) {
      listeners.forEach((callback) => callback(...args));
    }
  }
}

export const socket = new SharedSocketClient();
