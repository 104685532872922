import { Button } from "@lassie/ui/button";
import {
  IconCopy,
  IconCopyCheck,
  IconLayoutSidebar,
  IconLayoutSidebarFilled,
} from "@tabler/icons-react";
import { cx } from "class-variance-authority";
import { forwardRef, memo, useState } from "react";
import { useSidebarContext } from "../context/sidebar-context";

function TopBarWrapper({
  children,
  className,
}: { children: React.ReactNode; className?: string }) {
  return (
    <div
      className={cx(
        "flex h-14 items-center justify-between border-slate-200 border-b px-8 text-base",
        className,
      )}
    >
      {children}
    </div>
  );
}

function Left({
  children,
  className,
}: { children: React.ReactNode; className?: string }) {
  return (
    <div className={cx("flex items-center gap-2.5", className)}>
      <SidebarToggleButton />
      {children}
    </div>
  );
}

function Right({ children }: { children: React.ReactNode }) {
  return <div className="flex items-center gap-7">{children}</div>;
}

function CopyLinkButton() {
  const [isCopied, setIsCopied] = useState(false);

  return (
    <Button
      data-sentry-unmask
      variant="ghost"
      intent="muted"
      size="sm"
      className={cx(
        "last:-mr-3 transition-opacity duration-100",
        isCopied && "hover:!bg-transparent opacity-70",
      )}
      prefix={isCopied ? <IconCopyCheck size={18} /> : <IconCopy size={18} />}
      onClick={() => {
        navigator.clipboard.writeText(window.location.href);
        setIsCopied(true);

        setTimeout(() => {
          setIsCopied(false);
        }, 750);
      }}
    >
      Copy Link
    </Button>
  );
}

const SidebarToggleButton = memo(function SidebarToggleButton({
  className,
}: {
  className?: string;
}) {
  const { isOpen, setIsOpen } = useSidebarContext();

  return (
    <Button
      className={cx(
        "-ml-2 !text-slate-500 hover:!text-slate-600 xl:hidden",
        className,
      )}
      variant="ghost"
      intent="muted"
      size="icon"
      prefix={
        isOpen ? (
          <IconLayoutSidebarFilled size={18} />
        ) : (
          <IconLayoutSidebar size={18} />
        )
      }
      onClick={() => setIsOpen(!isOpen)}
      tooltip={"Toggle sidebar"}
    />
  );
});

const ButtonWrapper = forwardRef<HTMLDivElement, { children: React.ReactNode }>(
  ({ children, ...props }, ref) => {
    return (
      <div ref={ref} className="flex items-center gap-1.5" {...props}>
        {children}
      </div>
    );
  },
);

export const TopBar = {
  Wrapper: TopBarWrapper,
  Left: Left,
  Right: Right,
  ButtonWrapper: ButtonWrapper,
  CopyLinkButton: CopyLinkButton,
};
