import { format } from "date-fns";

import { IconCalendar } from "@tabler/icons-react";
import { cx } from "class-variance-authority";
import { Button } from "../button/button";
import { Calendar } from "../calendar/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../popover/popover";

export type DateInputProps = {
  value: Date | undefined;
  onChange: (value: Date | undefined) => void;
  placeholder?: string;
  useFriendlyDates?: boolean;
  required?: boolean;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "value" | "onChange">;

export function DatePicker({
  className,
  value: date,
  onChange: setDate,
  required = false,
  placeholder,
  useFriendlyDates = true,
}: DateInputProps) {
  return (
    <div className={cx("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            prefix={
              <IconCalendar
                size={18}
                strokeWidth={2}
                className="text-slate-500"
              />
            }
            className={cx("justify-start text-left", !date && "text-slate-600")}
          >
            {getDateRangeLabel(date, useFriendlyDates, placeholder)}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="!p-0 w-auto bg-white" align="start">
          <Calendar
            autoFocus
            mode="single"
            defaultMonth={date}
            selected={date}
            required={required}
            onDayClick={(day) => setDate(day)}
            onSelect={setDate}
            numberOfMonths={1}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}

function getDateRangeLabel(
  date: Date | undefined,
  useFriendlyDates: boolean,
  placeholder?: string,
) {
  if (!date) {
    return placeholder ?? "Pick a date";
  }

  return getDateLabel(date, useFriendlyDates);
}

export function getDateLabel(
  date: Date | undefined,
  useFriendlyDates: boolean,
) {
  if (useFriendlyDates) {
    const isSameMonth = date?.getMonth() === new Date().getMonth();
    const isSameYear = date?.getFullYear() === new Date().getFullYear();
    const isSameDate = date?.getDate() === new Date().getDate();

    if (isSameMonth && isSameYear && isSameDate) {
      return `Today, ${format(date, "LLL d")}`;
    }

    if (
      isSameMonth &&
      isSameYear &&
      date?.getDate() === new Date().getDate() - 1
    ) {
      return `Yesterday, ${format(date, "LLL d")}`;
    }

    return date ? format(date, "EEE, LLL d, y") : "Pick a date";
  }

  return date ? format(date, "LLL d, y") : "Pick a date";
}
