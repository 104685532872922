import {
  type Client,
  EobStatusLabel,
  PostedStatusLabel,
  TransactionStatusLabel,
} from "../../lib/client-types";

const SEVEN_DAYS_MS = 7 * 24 * 60 * 60 * 1000;

function isPaymentRecent(paymentDate: string) {
  return new Date(paymentDate).getTime() > Date.now() - SEVEN_DAYS_MS;
}

export function getTransactionStatusLabel(
  bankTransaction: Client.BankTransaction | null,
  eobPayment: Client.EobPayment | null,
  status: Client.EobPaymentStatus,
  beforeLassie: boolean,
) {
  if (bankTransaction) {
    // found in bank
    return TransactionStatusLabel.RECEIVED_IN_BANK;
  }

  if (!eobPayment) {
    // we must have an eob payment if there is no bank transaction
    throw new Error("No bank transaction or eob payment");
  }

  if (eobPayment.receivedByPracticeAt !== null) {
    // if the practiced marked as received
    return TransactionStatusLabel.RECEIVED_BY_PRACTICE;
  }

  if (beforeLassie) {
    return TransactionStatusLabel.BEFORE_LASSIE;
  }

  if (
    isPaymentRecent(eobPayment.paymentDate) ||
    eobPayment.paymentMethod !== "ACH"
  ) {
    // if its within two weeks, it's awaiting
    return TransactionStatusLabel.AWAITING;
  }

  // otherwise, it's missing
  return TransactionStatusLabel.MISSING;
}

export function getEobStatusLabel(
  bankTransaction: Client.BankTransaction | null,
  eobPayment: Client.EobPayment | null,
  status: Client.EobPaymentStatus | null,
  beforeLassie: boolean,
  pdfOnly: boolean,
) {
  if (eobPayment) {
    if (!status) {
      throw new Error("No eob payment status, but there is an eob payment");
    }

    if (status.complete) {
      return EobStatusLabel.PROCESSED;
    }

    if (pdfOnly) {
      return EobStatusLabel.PDF_ONLY;
    }

    return EobStatusLabel.PROCESSING;
  }

  if (!bankTransaction) {
    // we must have a bank transaction if there is no eob payment
    throw new Error("No bank transaction, but there is no eob payment");
  }

  if (beforeLassie) {
    return EobStatusLabel.BEFORE_LASSIE;
  }

  if (isPaymentRecent(bankTransaction.accruedAt)) {
    return EobStatusLabel.AWAITING;
  }

  return EobStatusLabel.MISSING;
}

export function getPostedStatusLabel(
  bankTransaction: Client.BankTransaction | null,
  eobPayment: Client.EobPayment | null,
  status: Client.EobPaymentStatus | null,
  beforeLassie: boolean,
) {
  if (!eobPayment) {
    if (!bankTransaction) {
      // we must have a bank transaction if there is no eob payment
      throw new Error("No bank transaction, but there is no eob payment");
    }

    if (bankTransaction.markedPostedByPracticeAt !== null) {
      return PostedStatusLabel.POSTED;
    }

    if (beforeLassie) {
      return PostedStatusLabel.BEFORE_LASSIE;
    }

    return PostedStatusLabel.NOT_POSTED;
  }

  if (!status) {
    throw new Error("No eob payment status, but there is an eob payment");
  }

  if (status.fullyPosted || eobPayment.markedPostedByPracticeAt !== null) {
    return PostedStatusLabel.POSTED;
  }

  if (status.partiallyPosted) {
    return PostedStatusLabel.PARTIALLY_POSTED;
  }

  if (beforeLassie) {
    return PostedStatusLabel.BEFORE_LASSIE;
  }

  if (
    new Date(eobPayment.paymentDate) >= new Date() &&
    eobPayment.paymentMethod === "ACH"
  ) {
    return PostedStatusLabel.QUEUED;
  }

  if (
    eobPayment.receivedByPracticeAt !== null &&
    eobPayment.paymentMethod !== "ACH"
  ) {
    return PostedStatusLabel.QUEUED;
  }

  return PostedStatusLabel.NOT_POSTED;
}

// Transaction status label
// missing transaction and not received and EOB date before start date: BEFORE_LASSIE
// missing transaction and not received and EOB date after start date and old: MISSING
// missing transaction and not received and EOB date after start date and recent: AWAITING
// missing transaction and received: RECEIVED_BY_PRACTICE
// transaction: RECEIVED_IN_BANK

// EOB status icon
// missing EOB and transaction date before start date: BEFORE_LASSIE
// missing EOB and transaction date after start date and old: MISSING
// missing EOB and transaction date after start date and recent: AWAITING
// EOB and incomplete: INCOMPLETE
// EOB and complete: COMPLETE

// Posted status label
// missing EOB and transaction date before start date: BEFORE_LASSIE
// missing EOB and transaction date after start date: NOT_POSTED
// EOB and not posted: NOT_POSTED
// EOB and partially posted: PARTIALLY_POSTED
// EOB and posted: POSTED
