import { useCallback, useEffect } from "react";

interface RefreshDetectorOptions {
  threshold?: number;
  timeWindow?: number;
  onRapidRefresh?: (count: number, timeWindow: number) => void;
}

export const useRefreshDetector = ({
  threshold = 1,
  timeWindow = 5000,
  onRapidRefresh,
}: RefreshDetectorOptions = {}) => {
  useEffect(() => {
    const performanceEntry = performance.getEntriesByType(
      "navigation",
    )[0] as PerformanceNavigationTiming;
    const isReload =
      "type" in performanceEntry && performanceEntry.type === "reload";

    if (isReload) {
      const now = Date.now();
      const lastRefresh = Number(sessionStorage.getItem("lastRefresh")) || 0;
      const refreshCount = Number(sessionStorage.getItem("refreshCount")) || 0;

      if (now - lastRefresh <= timeWindow) {
        // Within time window, increment count
        const newCount = refreshCount + 1;
        sessionStorage.setItem("refreshCount", String(newCount));

        if (newCount >= threshold) {
          onRapidRefresh?.(newCount, timeWindow);
        }
      } else {
        // Outside time window, reset count
        sessionStorage.setItem("refreshCount", "1");
      }

      sessionStorage.setItem("lastRefresh", String(now));
    }
  }, [threshold, timeWindow, onRapidRefresh]);
};
