import { useLocation, useNavigate, useSearch } from "@tanstack/react-router";
import { useCallback, useMemo } from "react";
import { z } from "zod";
import { INBOX_TO_SLUG, InboxType } from "../lib/patient-inbox";

export const TRANSACTION_STATUS = ["Received", "Not Received"] as const;
export const PAYMENT_METHOD = ["ACH", "CHECK", "VCC"] as const;

export const TASKS_FILTERS_SCHEMA = z.object({
  tab: z.nativeEnum(InboxType).catch(InboxType.ALL),
  patientName: z.string().optional().catch(undefined),
  payerGroupId: z.string().optional().catch(undefined),
  claimNumber: z.string().optional().catch(undefined),
  page: z.number().catch(1),
});

export const TASKS_FILTER_SEARCH_PARAMS_SCHEMA = TASKS_FILTERS_SCHEMA.omit({
  tab: true,
}).catch({
  page: 1,
  patientName: undefined,
  claimNumber: undefined,
  payerGroupId: undefined,
});

export type TasksFilters = z.infer<typeof TASKS_FILTER_SEARCH_PARAMS_SCHEMA>;
export type TasksQueryFilters = Omit<TasksFilters, "page">;

/**
 * Parses the current tab from the router location
 */
export function useTasksTab(): InboxType {
  const { pathname } = useLocation();
  const tab = pathname.split("/tasks/").at(-1);

  return useMemo(() => {
    for (const [type, slug] of Object.entries(INBOX_TO_SLUG)) {
      if (slug === `/tasks/${tab}`) {
        return type as InboxType;
      }
    }

    return InboxType.ALL;
  }, [pathname]);
}

export function useTasks() {
  const navigate = useNavigate();
  const tab = useTasksTab();
  const search = useSearch({ from: "/_dashboard/patients/tasks" });

  // Stable navigation functions
  const setFilters = useCallback(
    (newFilters: Partial<TasksQueryFilters>) => {
      navigate({
        from: "/patients/tasks",
        to: window.location.pathname,
        search: (prev) => ({
          ...prev,
          patientName: newFilters.patientName,
          claimNumber: newFilters.claimNumber,
          payerGroupId: newFilters.payerGroupId,
          page: 1,
        }),
        replace: true,
      });
    },
    [navigate],
  );

  const setPage = useCallback(
    (page: number) => {
      navigate({
        from: "/patients/tasks",
        to: window.location.pathname,
        search: (prev) => ({ ...prev, page }),
      });
    },
    [navigate],
  );

  const clearFilters = useCallback(() => {
    navigate({
      from: "/patients/tasks",
      to: window.location.pathname,
      search: { page: 1 },
    });
  }, [navigate]);

  return {
    // Current state
    tab,
    page: search.page,
    filters: search,

    // Actions
    setFilters,
    setPage,
    clearFilters,
  };
}
