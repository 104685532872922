import type { User } from "@lassie/types";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { BootstrapStatus, LoadingVariant } from "../components/splash";
import {
  type BootstrapResult,
  Initializer,
  type RecoveredResult,
} from "../lib/initializer";
import {
  InitializerError,
  type InitializerErrorBlob,
} from "../lib/initializer-types";
import { initIntercom } from "../lib/intercom";
import { LocalStorage } from "../lib/local-storage";
import { socket } from "../lib/socket-proxy";
import { bootstrapped } from "../store/actions";
import { diskApi, useRealtimeQuery } from "../store/disk/api";
import { QUERY_TAGS, QueryTagId } from "../store/disk/tags";
import {
  desyncedSetSelectedPractice,
  setPractices,
  setUser,
} from "../store/slices/user";
import { usePolling } from "./use-polling";

type UseInitializerOptions = {
  accessToken?: string;
};

function useInitializer({ accessToken }: UseInitializerOptions) {
  const dispatch = useDispatch();
  const [error, setError] = useState<InitializerErrorBlob | null>(null);
  const [status, setStatus] = useState<BootstrapStatus>(
    BootstrapStatus.LOADING,
  );
  const [loadingVariant, setLoadingVariant] = useState<LoadingVariant>(
    LoadingVariant.RECOVERY,
  );

  useRealtimeQuery(accessToken ? { token: accessToken } : skipToken);
  usePolling();

  const initializerRef = useRef<Initializer | null>(null);

  const onSyncFinished = useCallback(
    ({ practices, error }: BootstrapResult) => {
      if (error) {
        setError(error);
        return;
      }

      if (!practices || practices.length === 0) {
        setError({
          type: InitializerError.NO_PRACTICES_FOUND,
          message: "No practices found",
        });
        return;
      }

      dispatch(
        bootstrapped([
          "patients",
          "procedureCodes",
          "tasks",
          "providers",
          "payerGroups",
          "eobPayments",
          "eobClaims",
        ]),
      );
      dispatch(setPractices(practices));

      // move selected practice from local storage to redux at end of init
      const maybeSelectedPractice = LocalStorage.get("selectedPractice");
      if (maybeSelectedPractice) {
        dispatch(desyncedSetSelectedPractice(maybeSelectedPractice));
      }

      if (initializerRef.current?.mode === LoadingVariant.RECOVERY) {
        // queue query refetch
        dispatch(
          diskApi.util.invalidateTags([
            ...QUERY_TAGS,
            ...QUERY_TAGS.map((tag) => ({ type: tag, id: QueryTagId.ALL })),
          ]),
        );
      } else {
        // hard cache reset
        dispatch(diskApi.util.resetApiState());
      }
    },
    [dispatch],
  );

  // Initialize the Initializer class
  useEffect(() => {
    if (accessToken === undefined) {
      return;
    }

    initializerRef.current = new Initializer({
      accessToken,
      onSyncFinished,
      onStatusChange: setStatus,
      onModeChange: setLoadingVariant,
      socket,
      onRecover: (data: RecoveredResult) => {
        dispatch(setPractices(data.practices));
        dispatch(setUser(data.user_data));

        if (!IS_DEMO) {
          // prevent intercom from being initialized in demo
          initIntercom(data.intercom_user);
        }
      },

      onError: (error) => {
        setStatus(BootstrapStatus.ERROR);
        setError(error);
      },
    });

    const initializer = initializerRef.current;

    // Initial setup
    initializer.initialize().then(() => setStatus(initializer.status));

    // Cleanup on unmount
    return () => {
      initializerRef.current = null;
    };
  }, [accessToken, onSyncFinished]);

  return {
    status,
    loadingVariant,
    error,
    initializer: initializerRef.current,
  };
}

export default useInitializer;
