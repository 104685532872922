import { useEffect, useState } from "react";
import { LocalStorage } from "../lib/local-storage";

const debugFlag = LocalStorage.get("__lassie_debug");
const isDebugDefault =
  (IS_DEVELOPMENT && debugFlag !== false) || debugFlag === true;

export function useIsDebug() {
  const [debug, setDebug] = useState(isDebugDefault);

  useEffect(() => {
    window.addEventListener("storage", (e) => {
      if (e.key === "__lassie_debug" && e.newValue === "true") {
        setDebug(e.newValue === "true");
      } else {
        setDebug(isDebugDefault);
      }
    });
  }, []);

  return debug;
}
