import { IconAlt, IconArrowBigUp, IconCommand } from "@tabler/icons-react";
import { type VariantProps, cva, cx } from "class-variance-authority";
import { forwardRef } from "react";
import useUserAgent from "use-user-agent";

const modifierToIcon = {
  shift: IconArrowBigUp,
  meta: IconCommand,
  alt: IconAlt,
};

type Modifier = keyof typeof modifierToIcon;
type KbdKey = Modifier | string;

function Key({
  kbdKey,
  isAppleDevice,
  className,
}: { kbdKey: KbdKey; isAppleDevice: boolean; className?: string }) {
  const Icon = modifierToIcon[kbdKey as Modifier];

  if (kbdKey === "meta" && !isAppleDevice) {
    // prefer control for meta on Windows keyboards
    return <span className={className}>Ctrl</span>;
  }

  if (Icon) {
    return (
      <span className={className}>
        <Icon size={12} strokeWidth={1.75} />
      </span>
    );
  }

  return <span className={className}>{kbdKey}</span>;
}

const kbdKeyVariants = cva(
  [
    "inline-flex h-6 items-center justify-center text-center font-[650] font-mono text-xs uppercase",
  ],
  {
    variants: {
      theme: {
        light: "border-slate-200 bg-slate-100 text-slate-700",
        dark: "border-slate-700 bg-slate-800 text-slate-200",
      },
      group: {
        true: "first:!pl-1 last:!pr-1 px-0.5",
        false: "rounded border border-b-[1.5px] px-1 shadow-sm",
      },
    },
    defaultVariants: {
      theme: "light",
      group: false,
    },
  },
);

export type KbdProps = VariantProps<typeof kbdKeyVariants> & {
  keys: KbdKey[];
  group?: boolean;
} & React.HTMLAttributes<HTMLSpanElement>;

export const Kbd = forwardRef<HTMLSpanElement, KbdProps>(
  ({ className, theme, keys, group = false, ...props }, ref) => {
    const { data } = useUserAgent(navigator.userAgent, {
      useHighEntropyResult: false,
    });

    const isAppleDevice = data?.device?.vendor === "Apple";

    return (
      <kbd
        className={cx(
          "inline-flex items-center",
          group
            ? "gap-0 overflow-hidden rounded border border-b-[1.5px] shadow-sm"
            : "gap-1",
        )}
        ref={ref}
        {...props}
      >
        {keys.map((kbdKey) => (
          <Key
            className={kbdKeyVariants({ theme, className, group })}
            kbdKey={kbdKey}
            isAppleDevice={isAppleDevice}
            key={kbdKey}
          />
        ))}
      </kbd>
    );
  },
);

Kbd.displayName = "Kbd";
