import { z } from "zod";

export const versionSchema = z.object({
  major: z.number(),
  minor: z.number(),
  patch: z.number(),
});

export type Version = z.infer<typeof versionSchema>;

/**
 * The current version of the client. Version changes require
 * bootstrapping the client to update schemas + data.
 *
 * Increment for breaking changes.
 */
export const CLIENT_VERSION = {
  major: 1,
  minor: 6,
  patch: 0,
} satisfies Version;

export enum VersionComparison {
  NEEDS_FULL_UPGRADE = "NEEDS_FULL_UPGRADE",
  UP_TO_DATE = "UP_TO_DATE",
  MINOR_UPGRADE = "MINOR_UPGRADE",
}

export const compareVersions = (local: Version, remote: Version) => {
  if (local.major !== remote.major) {
    return VersionComparison.NEEDS_FULL_UPGRADE;
  }

  if (local.minor !== remote.minor) {
    // TODO: as iteration slows down, we should support
    // minor upgrades without a full bootstrap.
    return VersionComparison.NEEDS_FULL_UPGRADE;
  }

  return VersionComparison.UP_TO_DATE;
};
