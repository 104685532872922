import { and, eq, exists, inArray, isNull } from "drizzle-orm";
import { db } from "../../lib/drizzle/db";
import { Tables } from "../../lib/drizzle/schema";
import { LocalStorage } from "../../lib/local-storage";

export async function paymentFetcher(paymentIds: string[]) {
  if (!paymentIds.length) {
    return [];
  }

  const allPaymentsAndBankTransactions = await db
    .select({
      payment: Tables.eobPayments,
      bankTransaction: Tables.bankTransactions,
    })
    .from(Tables.eobPayments)
    .leftJoin(
      Tables.bankTransactions,
      eq(Tables.eobPayments.bankTransactionId, Tables.bankTransactions.id),
    )
    .where(
      and(
        inArray(Tables.eobPayments.id, paymentIds),
        eq(
          Tables.eobPayments.practiceUuid,
          // biome-ignore lint/style/noNonNullAssertion: TEMP
          LocalStorage.get("selectedPractice")!,
        ),
      ),
    )
    .execute();

  const allClaims = await db
    .select({
      claims: Tables.eobClaims,
      hasClaimProcedures: exists(
        db
          .select()
          .from(Tables.claimProcedures)
          .where(
            and(
              eq(Tables.claimProcedures.claimId, Tables.eobClaims.ehrClaimId),
              isNull(Tables.claimProcedures.deletedAt),
            ),
          ),
      ),
    })
    .from(Tables.eobClaims)
    .where(inArray(Tables.eobClaims.paymentId, paymentIds))
    .execute();

  const allTasks = await db
    .select()
    .from(Tables.tasks)
    .where(
      and(
        inArray(Tables.tasks.paymentId, paymentIds),
        isNull(Tables.tasks.deletedAt),
      ),
    )
    .execute();

  const results = allPaymentsAndBankTransactions.map(
    ({ payment, bankTransaction }) => {
      const claims = allClaims
        .filter((claim) => claim.claims.paymentId === payment.id)
        .map((claim) => ({
          ...claim.claims,
          hasClaimProcedures: claim.hasClaimProcedures as boolean,
        }));

      const tasks = allTasks.filter((task) => task.paymentId === payment.id);
      return { paymentId: payment.id, payment, claims, bankTransaction, tasks };
    },
  );

  return results;
}
