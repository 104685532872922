import {
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  IconChevronUp,
} from "@tabler/icons-react";
import { cx } from "class-variance-authority";
import { DayPicker, UI, getDefaultClassNames } from "react-day-picker";
import { buttonVariants } from "../button/button";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

const defaultClassNames = getDefaultClassNames();

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      mode={props.mode}
      showOutsideDays={showOutsideDays}
      className={cx("select-none px-2 py-3", className)}
      classNames={{
        ...defaultClassNames,
        root: cx(defaultClassNames.root, "flex text-sm"),
        caption: "flex justify-center py-3 mb-4 relative items-center",
        month_caption: "text-sm font-medium text-slate-600 my-2 text-center",
        nav: "mt-0.5",
        nav_button:
          "h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300",
        [UI.PreviousMonthButton]: cx(
          "absolute left-6",
          buttonVariants({
            variant: "secondary",
            size: "icon",
            intent: "muted",
          }),
          "hover:bg-slate-100",
        ),
        [UI.NextMonthButton]: cx(
          "absolute right-6",
          buttonVariants({
            variant: "secondary",
            size: "icon",
            intent: "muted",
          }),
          "hover:bg-slate-100",
        ),
        [UI.MonthGrid]: "w-full border-collapse",
        weekdays: "flex font-medium mt-4 mb-2",
        weekday: "w-9 font-normal text-sm text-slate-400",
        [UI.Weeks]: "flex flex-col gap-1",
        [UI.Week]: "flex w-full",
        [UI.Day]:
          "flex h-9 w-9 items-stretch justify-center p-0 font-medium text-slate-600 hover:rounded-md hover:bg-slate-100",

        [UI.DayButton]: "flex-1",
        today:
          "[&:not([aria-selected])]:rounded-md [&:not([aria-selected])]:bg-blue-100 [&:not([aria-selected])]:text-blue-700",
        outside: "day-outside aria-selected:!bg-slate-100 !text-slate-400",
        disabled: "text-slate-500 opacity-50",
        hidden: "invisible",
        selected: cx(
          "hover:!bg-brand-100 !text-brand-800 !hover:bg-brand-100 bg-brand-100 font-semibold focus:bg-brand-100",
          props.mode === "single"
            ? "!rounded-md"
            : "first:!rounded-l-md last:!rounded-r-md hover:rounded-none",
        ),
        range_start: "!rounded-l-md rounded-r-none",
        range_end: "!rounded-r-md rounded-l-none",
        [UI.Month]: "flex flex-col gap-1 mx-3",
        [UI.Months]: "flex flex-col sm:flex-row",
      }}
      components={{
        Chevron: ({ ...props }) => {
          if (props.orientation === "right") {
            return <IconChevronRight size={18} strokeWidth={2} />;
          }
          if (props.orientation === "left") {
            return <IconChevronLeft size={18} strokeWidth={2} />;
          }
          if (props.orientation === "up") {
            return <IconChevronUp size={18} strokeWidth={2} />;
          }
          return <IconChevronDown size={18} strokeWidth={2} />;
        },
      }}
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
