import { useLocation, useNavigate, useSearch } from "@tanstack/react-router";
import { addDays } from "date-fns";
import { useCallback } from "react";
import { z } from "zod";
import { formatDateRange } from "./posted-context";

export const DATE_RANGE_SCHEMA = z
  .string()
  .optional()
  .transform((value) => {
    if (!value) {
      return undefined;
    }

    const [from, to] = value.split("-to-");

    if (!from || !to) {
      return undefined;
    }

    return {
      from: addDays(new Date(from), 1),
      to: addDays(new Date(to), 1),
    };
  })
  .catch(undefined);

export const UNIFIED_PAYMENTS_TABS = [
  "All",
  "Needs Review",
  "Missing Transaction",
  "Paper Checks",
] as const;
export const TRANSACTION_STATUS = ["Received", "Not Received"] as const;
export const PAYMENT_METHOD = ["ACH", "CHECK", "VCC"] as const;

export const PAYMENTS_FILTERS_SCHEMA = z.object({
  tab: z.enum(UNIFIED_PAYMENTS_TABS).catch("All"),
  dateRange: z.string().optional().catch(undefined),
  payerGroupId: z.string().optional().catch(undefined),
  paymentMethod: z.enum(PAYMENT_METHOD).optional().catch(undefined),
  paymentNumber: z.string().optional().catch(undefined),
  paymentAmount: z.string().optional().catch(undefined),
  transactionStatus: z.enum(TRANSACTION_STATUS).optional().catch(undefined),
  page: z.number().catch(1),
});

export const PAYMENTS_FILTER_SEARCH_PARAMS_SCHEMA =
  PAYMENTS_FILTERS_SCHEMA.omit({
    tab: true,
  }).catch({
    page: 1,
    dateRange: undefined,
    payerGroupId: undefined,
    paymentMethod: undefined,
    paymentNumber: undefined,
    paymentAmount: undefined,
    transactionStatus: undefined,
  });

export type UnifiedPaymentsFilters = z.infer<
  typeof PAYMENTS_FILTER_SEARCH_PARAMS_SCHEMA
>;

export type UnifiedPaymentsQueryFilters = Omit<
  UnifiedPaymentsFilters,
  "dateRange" | "page"
>;

export type UnifiedPaymentsTab = (typeof UNIFIED_PAYMENTS_TABS)[number];

/**
 * Parses the current tab from the router location
 */
export function usePaymentsTab() {
  const { pathname } = useLocation();
  const tab = pathname.split("/payments").at(-1);
  return tab ? tab.replace("/", "") : "all";
}

export function useUnifiedPayments() {
  const navigate = useNavigate();
  const tab = usePaymentsTab();
  const search = useSearch({ from: "/_dashboard/payments/_inbox" });

  // Stable navigation functions
  const setFilters = useCallback(
    (newFilters: Partial<UnifiedPaymentsQueryFilters>) => {
      navigate({
        from: "/payments",
        to: window.location.pathname,
        search: (prev) => ({
          ...prev,
          payerGroupId: newFilters.payerGroupId,
          transactionStatus: newFilters.transactionStatus,
          paymentAmount: newFilters.paymentAmount,
          paymentMethod: newFilters.paymentMethod,
          paymentNumber: newFilters.paymentNumber,
          page: 1,
        }),
        replace: true,
      });
    },
    [navigate],
  );

  const setPage = useCallback(
    (page: number) => {
      navigate({
        from: "/payments",
        to: window.location.pathname,
        search: (prev) => ({ ...prev, page }),
      });
    },
    [navigate],
  );

  const setDateRange = useCallback(
    (range: { from: Date; to: Date } | undefined) => {
      navigate({
        from: "/payments",
        to: window.location.pathname,
        search: (prev) => ({
          ...prev,
          dateRange: range ? formatDateRange(range) : undefined,
          page: 1,
        }),
        replace: true,
      });
    },
    [navigate],
  );

  const clearFilters = useCallback(() => {
    navigate({
      from: "/payments",
      to: window.location.pathname,
      search: { page: 1 },
    });
  }, [navigate]);

  return {
    // Current state
    tab,
    page: search.page,
    dateRange: DATE_RANGE_SCHEMA.parse(search.dateRange),
    filters: search,

    // Actions
    setFilters,
    setPage,
    setDateRange,
    clearFilters,
  };
}
