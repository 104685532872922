import { type Auth0ContextInterface, useAuth0 } from "@auth0/auth0-react";
import { TooltipProvider } from "@lassie/ui/tooltip";
import { captureException, captureMessage } from "@sentry/react";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { lazy } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { AuthProvider } from "./components/auth";
import { ErrorFallback } from "./components/common/error-fallback";
import { NotFound } from "./components/common/not-found";
import { Toaster } from "./components/toaster";
import { SidebarProvider } from "./context/sidebar-context";
import { useIsDebug } from "./hooks/use-is-debug";
import { PatientInboxFlowProvider } from "./hooks/use-patient-inbox-flow";
import { PaymentInboxFlowProvider } from "./hooks/use-payment-inbox-flow";
import { useRefreshDetector } from "./hooks/use-refresh-detector";
import { LocalStorage } from "./lib/local-storage";
import { initSentry } from "./lib/sentry";
import { routeTree } from "./routeTree.gen";
import { LifecycleManager } from "./store/lifecycle-manager";
import { store } from "./store/root";

const MockIntercom = IS_DEMO
  ? lazy(() =>
      import("./components/demo/intercom-mock").then((module) => ({
        default: module.MockIntercomWidget,
      })),
    )
  : () => null;

const router = createRouter({
  routeTree,
  defaultPreload: "intent",
  defaultStaleTime: 5000,
  context: {
    // auth will initially be undefined
    auth: {} as Auth0ContextInterface,
  },
  defaultErrorComponent: ErrorFallback,
  defaultNotFoundComponent: NotFound,
});

initSentry({
  enabled: !IS_DEVELOPMENT && !IS_DEMO,
  router,
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

/**
 * lazy load the debugger in development mode
 */
const Debugger = lazy(() =>
  import("./components/internal/debugger").then((module) => ({
    default: module.Debugger,
  })),
);

function RouterWithAuth() {
  const auth = useAuth0();
  return (
    <RouterProvider
      router={router}
      context={{ auth }}
      defaultPreload="intent"
    />
  );
}

// TODO: should be provided by the environment
const isStaging = window.location.hostname.endsWith("lassie.dev");

function App() {
  const isDebug = useIsDebug();

  useRefreshDetector({
    threshold: 3,
    timeWindow: 10000,
    onRapidRefresh: (count, timeWindow) => {
      if (IS_PRODUCTION) {
        captureMessage(`Rapid refresh detected: ${count} in ${timeWindow}ms`, {
          level: "warning",
        });
      }
    },
  });

  return (
    <HelmetProvider>
      <Helmet>
        {IS_DEVELOPMENT && <link rel="icon" href="/favicon-dev.svg" />}
        {isStaging && <link rel="icon" href="/favicon-staging.svg" />}
      </Helmet>

      <TooltipProvider delayDuration={50}>
        <SidebarProvider>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={(e) => captureException(e)}
          >
            <AuthProvider>
              <Provider store={store}>
                <Toaster />
                <LifecycleManager>
                  <PatientInboxFlowProvider>
                    <PaymentInboxFlowProvider>
                      <RouterWithAuth />
                    </PaymentInboxFlowProvider>
                  </PatientInboxFlowProvider>
                </LifecycleManager>
                <Debugger isDebug={isDebug} />
                <MockIntercom />
              </Provider>
            </AuthProvider>
          </ErrorBoundary>
        </SidebarProvider>
      </TooltipProvider>
    </HelmetProvider>
  );
}

export default App;
