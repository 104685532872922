import { TZDateMini } from "@date-fns/tz";
import type { EobClaim, PayerGroup, Task } from "@lassie/types";
import {
  endOfDay,
  isAfter,
  startOfDay,
  subDays,
  subMonths,
  subYears,
} from "date-fns";
import { fromZonedTime } from "date-fns-tz";
import {
  type SQL,
  type SQLWrapper,
  and,
  asc,
  between,
  count,
  desc,
  eq,
  exists,
  gt,
  gte,
  inArray,
  isNotNull,
  isNull,
  like,
  lt,
  lte,
  not,
  or,
  sql,
} from "drizzle-orm";
import type { SubqueryWithSelection } from "drizzle-orm/sqlite-core";
import {
  getEobStatusLabel,
  getPostedStatusLabel,
  getTransactionStatusLabel,
} from "../../components/payments/payment-status";
import {
  DATE_RANGE_SCHEMA,
  type UnifiedPaymentsFilters,
} from "../../context/unified-payments-context";
import {
  type Client,
  PostedStatusLabel,
  TransactionStatusLabel,
} from "../../lib/client-types";
import { db } from "../../lib/drizzle/db";
import { Tables } from "../../lib/drizzle/schema";
import { LocalStorage } from "../../lib/local-storage";
import { Timer } from "../../lib/timer";
import { syncWorker } from "../../lib/workers";
import {
  ledgersFromDisk,
  ledgersFromNetwork,
  paymentsFromDisk,
  paymentsFromNetwork,
} from "./batcher";
import {
  handleAlerts,
  handleBankAccounts,
  handleClearRecentSearch,
  handleCompleteBankLink,
  handleCreateBankLink,
  handleInsuranceLogins,
  handleRecentSearches,
  handleRemoveBankLink,
  handleSearch,
} from "./server";
import type { DiskAction } from "./types";

async function handlePatients(
  options: DiskAction.Patients["options"],
): Promise<DiskAction.Patients["response"]> {
  const { query, pagination, sort } = options;

  // pagination
  const offsetBy = (pagination.page - 1) * pagination.size;

  // sorting
  const sortKey = sort?.key || "lastViewedAt";
  const sortOrder = sort?.order || "desc";
  const sortOrderFn = sortOrder === "desc" ? desc : asc;

  // filtering
  const firstName = query?.firstName ? query.firstName : null;
  const lastName = query?.lastName ? query.lastName : null;

  const patientsResult = await db
    .select()
    .from(Tables.patients)
    .limit(pagination.size)
    .offset(offsetBy)
    .where(() => {
      const conditions: SQLWrapper[] = [
        isNull(Tables.patients.deletedAt),
        // biome-ignore lint/style/noNonNullAssertion: TEMP
        eq(Tables.patients.practiceUuid, LocalStorage.get("selectedPractice")!),
      ];

      if (firstName) {
        conditions.push(like(Tables.patients.firstName, `%${firstName}%`));
      }

      if (lastName) {
        conditions.push(like(Tables.patients.lastName, `%${lastName}%`));
      }

      return and(...conditions);
    })
    .orderBy(sortOrderFn(Tables.patients[sortKey]))
    .execute();

  const patients = patientsResult.map((patient) => {
    return {
      ...patient,
      ledgerBalance: 314,
    };
  });

  return { patients };
}

async function handlePatientLedger(
  options: DiskAction.Ledger["options"],
): Promise<DiskAction.Ledger["response"]> {
  const patientId = options.patientId;

  const hasPrefetched = await syncWorker.hasPrefetchedLedger(patientId);

  if (!hasPrefetched) {
    await ledgersFromNetwork.fetch(patientId);
  }

  const ledger = await ledgersFromDisk.fetch(patientId);

  return ledger;
}

async function handleAllPayments(
  _options: DiskAction.GetAllPayments["options"],
): Promise<DiskAction.GetAllPayments["response"]> {
  const payments = await db
    .select()
    .from(Tables.eobPayments)
    .where(
      eq(
        Tables.eobPayments.practiceUuid,
        // biome-ignore lint/style/noNonNullAssertion: TEMP
        LocalStorage.get("selectedPractice")!,
      ),
    )
    .limit(50)
    .orderBy(desc(Tables.eobPayments.paymentDate))
    .execute();

  return { payments };
}

async function handlePayments(
  options: DiskAction.Payments["options"],
): Promise<DiskAction.Payments["response"]> {
  const { query, pagination, sort } = options;

  // pagination
  const offsetBy = (pagination.page - 1) * pagination.size;

  // sorting
  const sortKey = sort?.key || "paymentDate";
  const sortOrder = sort?.order || "desc";
  const sortOrderFn = sortOrder === "desc" ? desc : asc;

  const payments = await db
    .select()
    .from(Tables.eobPayments)
    .limit(pagination.size)
    .offset(offsetBy)
    .where(() => {
      const conditions: SQLWrapper[] = [
        eq(
          Tables.eobPayments.practiceUuid,
          // biome-ignore lint/style/noNonNullAssertion: TEMP
          LocalStorage.get("selectedPractice")!,
        ),
      ];

      if (query?.traceNumber) {
        conditions.push(
          like(Tables.eobPayments.paymentNumber, `%${query.traceNumber}%`),
        );
      }

      if (query?.amount) {
        // if the user specifies an amount with a decimal, we assume they want an exact match
        const isCentSpecific = query.amount.includes(".");

        const cents = Number(query.amount) * 100;

        if (!Number.isNaN(cents)) {
          if (isCentSpecific) {
            // look for an exact match
            conditions.push(eq(Tables.eobPayments.paymentAmount, cents));
          } else {
            conditions.push(gte(Tables.eobPayments.paymentAmount, cents));
            // add a dollar since we're not looking for floats
            conditions.push(lt(Tables.eobPayments.paymentAmount, cents + 100));
          }
        }
      }

      if (query?.dateRange) {
        const { from, to } = query.dateRange;

        if (from) {
          conditions.push(
            gte(Tables.eobPayments.paymentDate, from.toISOString()),
          );

          const end = to ? endOfDay(to) : endOfDay(from);

          conditions.push(
            lte(Tables.eobPayments.paymentDate, end.toISOString()),
          );
        }
      }

      if (query?.payerGroupId) {
        conditions.push(
          eq(Tables.eobPayments.payerGroupId, query.payerGroupId),
        );
      }

      return and(...conditions);
    })
    .orderBy(sortOrderFn(Tables.eobPayments[sortKey]))
    .execute();
  return { payments };
}

async function handlePayment(
  options: DiskAction.GetPayment["options"],
): Promise<DiskAction.GetPayment["response"]> {
  const paymentId = options.paymentId;

  // all payments should be disk now
  const payment = await paymentsFromDisk.fetch(paymentId);

  return payment;
}

async function handlePatientInbox(
  _options: DiskAction.PatientInbox["options"],
): Promise<DiskAction.PatientInbox["response"]> {
  const timer = new Timer("Inbox RPC Timer");

  const tasks = await db
    .select({
      id: Tables.tasks.id,
      type: Tables.tasks.type,
      note: Tables.tasks.note,
      createdAt: Tables.tasks.createdAt,
      updatedAt: Tables.tasks.updatedAt,
      completedAt: Tables.tasks.completedAt,
      eobClaim: Tables.eobClaims,
      eobPayment: Tables.eobPayments,
      patient: Tables.patients,
    })
    .from(Tables.tasks)
    .where(
      and(
        isNull(Tables.tasks.deletedAt),
        isNull(Tables.tasks.completedAt),
        not(eq(Tables.tasks.type, "POST_CLAIM")),
        eq(
          Tables.tasks.practiceUuid,
          // biome-ignore lint/style/noNonNullAssertion: TEMP
          LocalStorage.get("selectedPractice")!,
        ),
        exists(
          db
            .select()
            .from(Tables.claimProcedures)
            .where(
              and(
                eq(Tables.claimProcedures.claimId, Tables.eobClaims.ehrClaimId),
                isNull(Tables.claimProcedures.deletedAt),
              ),
            ),
        ),
      ),
    )
    .innerJoin(
      Tables.eobClaims,
      and(
        eq(Tables.tasks.claimId, Tables.eobClaims.id),
        isNotNull(Tables.eobClaims.ehrPatientId),
      ),
    )
    .innerJoin(
      Tables.eobPayments,
      eq(Tables.eobClaims.paymentId, Tables.eobPayments.id),
    )
    .innerJoin(
      Tables.patients,
      eq(Tables.eobClaims.ehrPatientId, Tables.patients.id),
    )
    .orderBy(desc(Tables.tasks.updatedAt))
    .execute();

  timer.elapsed();

  return tasks as Client.PatientInboxTask[];
}

async function handleCompletedPatientInbox(
  options: DiskAction.CompletedPatientInbox["options"],
): Promise<DiskAction.CompletedPatientInbox["response"]> {
  const { pagination } = options;
  const offsetBy = (pagination.page - 1) * pagination.size;

  const timer = new Timer("Completed Inbox RPC Timer");

  const result = await db
    .select({
      total: sql<number>`COUNT(*) OVER()`.as("total"),
      tasks: {
        id: Tables.tasks.id,
        type: Tables.tasks.type,
        note: Tables.tasks.note,
        createdAt: Tables.tasks.createdAt,
        updatedAt: Tables.tasks.updatedAt,
        completedAt: Tables.tasks.completedAt,
        claimId: Tables.tasks.claimId,
        paymentId: Tables.tasks.paymentId,
      },
      patient: Tables.patients,
      eobClaim: Tables.eobClaims,
      eobPayment: Tables.eobPayments,
    })
    .from(Tables.tasks)
    .limit(pagination.size)
    .offset(offsetBy)
    .innerJoin(
      Tables.patients,
      eq(Tables.eobClaims.ehrPatientId, Tables.patients.id),
    )
    .innerJoin(
      Tables.eobClaims,
      and(
        eq(Tables.tasks.claimId, Tables.eobClaims.id),
        isNotNull(Tables.eobClaims.ehrPatientId),
      ),
    )
    .innerJoin(
      Tables.eobPayments,
      eq(Tables.eobClaims.paymentId, Tables.eobPayments.id),
    )
    .where(() => {
      const { patientName, payerGroupId, claimNumber } = options.filters;

      const conditions: SQLWrapper[] = [
        isNull(Tables.tasks.deletedAt),
        isNotNull(Tables.tasks.completedAt),
        eq(
          Tables.tasks.practiceUuid,
          // biome-ignore lint/style/noNonNullAssertion: TEMP
          LocalStorage.get("selectedPractice")!,
        ),
      ];

      if (patientName) {
        conditions.push(like(Tables.patients.firstName, `${patientName}`));
      }

      if (payerGroupId) {
        conditions.push(eq(Tables.eobPayments.payerGroupId, payerGroupId));
      }

      if (claimNumber) {
        conditions.push(like(Tables.eobClaims.claimNumber, `%${claimNumber}%`));
      }

      return and(...conditions);
    })
    .orderBy(desc(Tables.tasks.completedAt))
    .execute();

  timer.elapsed();

  const total = result?.[0]?.total ?? 0;
  const tasks = result.map((r) => ({
    ...r.tasks,
    patient: r.patient,
    eobClaim: r.eobClaim,
    eobPayment: r.eobPayment,
  }));

  return { tasks, total };
}

const getPostedByStatuses = (
  postedBy?: DiskAction.Posted["options"]["postedBy"],
): EobClaim["status"][] => {
  if (postedBy === "Lassie") {
    return ["POSTED_BY_US"];
  }
  if (postedBy === "Practice") {
    return ["POSTED_BY_PRACTICE", "SKIPPED"];
  }
  return ["POSTED_BY_US", "POSTED_BY_PRACTICE", "SKIPPED"];
};

async function handlePosted(
  options: DiskAction.Posted["options"],
): Promise<DiskAction.Posted["response"]> {
  const timer = new Timer("Posted RPC Timer");

  const selectedPractice = LocalStorage.get("selectedPractice");

  if (!selectedPractice) {
    throw new Error("[Invariant] No selected practice");
  }

  const {
    dateRange,
    postedBy,
    groupBy,
    payerGroupId,
    patientName,
    claimNumber,
    paymentNumber,
    postedAmount,
    timezone,
  } = options;
  const { from: startDate, to: endDate } = dateRange;

  const results = await db
    .select({
      claim: Tables.eobClaims,
      task: Tables.tasks,
    })
    .from(Tables.eobClaims)
    .leftJoin(
      Tables.tasks,
      and(
        eq(Tables.eobClaims.status, "SKIPPED"),
        eq(Tables.eobClaims.id, Tables.tasks.claimId),
        isNotNull(Tables.tasks.completedAt),
      ),
    )
    .where(() => {
      const conditions: (SQLWrapper | SQL<unknown> | undefined)[] = [
        inArray(Tables.eobClaims.status, getPostedByStatuses(postedBy)),
        isNotNull(Tables.eobClaims.processedAt),
        eq(Tables.eobClaims.practiceUuid, selectedPractice),
      ];

      // dates
      const tzStartDate = new TZDateMini(startDate.toISOString(), timezone);
      const targetStartDate = startOfDay(tzStartDate);

      const start = or(
        and(
          not(eq(Tables.eobClaims.status, "SKIPPED")),
          gte(Tables.eobClaims.processedAt, targetStartDate.toISOString()),
        ),
        and(
          eq(Tables.eobClaims.status, "SKIPPED"),
          gte(Tables.tasks.completedAt, targetStartDate.toISOString()),
        ),
      );

      const tzEndDate = new TZDateMini(endDate.toISOString(), timezone);
      const targetEndDate = endOfDay(tzEndDate);

      const end = or(
        and(
          not(eq(Tables.eobClaims.status, "SKIPPED")),
          lte(Tables.eobClaims.processedAt, targetEndDate.toISOString()),
        ),
        and(
          eq(Tables.eobClaims.status, "SKIPPED"),
          lte(Tables.tasks.completedAt, targetEndDate.toISOString()),
        ),
      );

      conditions.push(start, end);

      if (patientName) {
        conditions.push(like(Tables.eobClaims.patientName, `%${patientName}%`));
      }

      return and(...conditions);
    });

  timer.check("POSTED QUERY");

  // Step 3: Get all relevant payments
  const paymentIds = [...new Set(results.map((r) => r.claim.paymentId))];

  const paymentResults = await db
    .select({
      payment: Tables.eobPayments,
      task: Tables.tasks,
    })
    .from(Tables.eobPayments)
    .leftJoin(Tables.tasks, eq(Tables.eobPayments.id, Tables.tasks.paymentId))
    .where(() => {
      const conditions: SQLWrapper[] = [
        inArray(Tables.eobPayments.id, paymentIds),
      ];

      if (payerGroupId) {
        conditions.push(eq(Tables.eobPayments.payerGroupId, payerGroupId));
      }

      return and(...conditions);
    });

  timer.check("PAYMENT QUERY");

  // Step 4: Process and group the data in memory
  const paymentMap = new Map(
    paymentResults.map((result) => [
      result.payment.id,
      {
        ...result.payment,
        task: result.task,
      },
    ]),
  );

  if (groupBy === "Payments") {
    timer.check("Grouping by payments...");

    const groupedClaimsByPayment = results.reduce(
      (acc, { claim, task }) => {
        const isPosted =
          claim.status === "POSTED_BY_PRACTICE" ||
          claim.status === "POSTED_BY_US" ||
          (claim.status === "SKIPPED" &&
            task?.completedAt !== null &&
            claim.paymentAmount !== 0);

        if (isPosted) {
          // biome-ignore lint/style/noNonNullAssertion: in query
          const processedDate = new Date(claim.processedAt!).toDateString();
          const key = `${claim.paymentId}-${processedDate}`;
          const claimPayment = paymentMap.get(claim.paymentId);

          if (!claimPayment) {
            if (!payerGroupId) {
              console.warn(
                `[Posted/payments] payment not found: ${claim.paymentId} with no payerGroupId filter`,
              );
            }
            return acc;
          }

          const isPostedByUs = claim.status === "POSTED_BY_US";
          const isPostedByPractice =
            claim.status === "POSTED_BY_PRACTICE" ||
            (claim.status === "SKIPPED" &&
              task?.completedAt !== null &&
              claim.paymentAmount !== 0);

          const taskCompletedAt = task?.completedAt;
          const claimsProcessedAt =
            claim.status === "SKIPPED" &&
            taskCompletedAt !== null &&
            taskCompletedAt !== undefined
              ? taskCompletedAt
              : processedDate;

          if (!acc[key]) {
            acc[key] = {
              claimsProcessedAt,
              claimPaymentAmount: 0,
              eobPayment: claimPayment,
              postedByLassie: 0,
              postedByPractice: 0,
            };
          }

          acc[key].claimPaymentAmount += claim.paymentAmount;
          if (isPostedByUs) {
            acc[key].postedByLassie += claim.paymentAmount;
          } else if (isPostedByPractice) {
            acc[key].postedByPractice += claim.paymentAmount;
          }
        }

        return acc;
      },
      {} as Record<string, Client.PostedPayment>,
    );

    let payments = [...Object.values(groupedClaimsByPayment)].sort(
      (a, b) =>
        new Date(b.claimsProcessedAt).getTime() -
        new Date(a.claimsProcessedAt).getTime(),
    );

    if (paymentNumber) {
      // TODO sort by relevance
      payments = payments.filter((p) =>
        p.eobPayment.paymentNumber
          .toLowerCase()
          .includes(paymentNumber.toLowerCase()),
      );
    }

    if (postedAmount) {
      const postedCents =
        Number(postedAmount.replace("$", "").replace(",", "")) * 100;
      if (!Number.isNaN(postedCents)) {
        const remainderCents = postedCents % 100;

        if (remainderCents === 0) {
          payments = payments.filter((p) => {
            const totalPosted = p.postedByLassie + p.postedByPractice;
            return (
              totalPosted >= postedCents &&
              // if there are no cents specified, we want to match any amount
              // within the dollar
              totalPosted < postedCents + 100
            );
          });
        } else {
          payments = payments.filter(
            (p) => p.eobPayment.paymentAmount === Math.round(postedCents),
          );
        }
      }
    }

    timer.elapsed();

    return {
      payments,
    };
  }

  if (groupBy === "Claims") {
    timer.check("Grouping by claims...");
    let claimsResult = results
      .filter(({ claim, task }) => {
        const isPosted =
          claim.status === "POSTED_BY_PRACTICE" ||
          claim.status === "POSTED_BY_US" ||
          (claim.status === "SKIPPED" &&
            task?.completedAt !== null &&
            claim.paymentAmount !== 0);

        return isPosted;
      })
      .map(({ claim, task }) => {
        // biome-ignore lint/style/noNonNullAssertion: in query
        const payment = paymentMap.get(claim.paymentId)!;
        // biome-ignore lint/style/noNonNullAssertion: in query
        const processedDate = new Date(claim.processedAt!).toDateString();

        const claimsProcessedAt =
          claim.status === "SKIPPED" &&
          task !== null &&
          task.completedAt !== null
            ? task.completedAt
            : processedDate;

        return {
          eobClaim: claim,
          eobPayment: payment,
          claimsProcessedAt,
          claimPaymentAmount: claim.paymentAmount,
          postedByLassie:
            claim.status === "POSTED_BY_US" ? claim.paymentAmount : 0,
          postedByPractice:
            claim.status === "POSTED_BY_PRACTICE" ||
            (claim.status === "SKIPPED" &&
              task?.completedAt !== null &&
              claim.paymentAmount !== 0)
              ? claim.paymentAmount
              : 0,
        };
      })
      .filter((claim) => claim.eobPayment !== undefined)
      .sort((a, b) => {
        return (
          new Date(b.claimsProcessedAt).getTime() -
          new Date(a.claimsProcessedAt).getTime()
        );
      });

    timer.elapsed();

    if (claimNumber) {
      // TODO sort by relevance
      claimsResult = claimsResult.filter((c) =>
        c.eobClaim.claimNumber
          .toLowerCase()
          .includes(claimNumber.toLowerCase()),
      );
    }

    if (postedAmount) {
      const postedCents =
        Number(postedAmount.replace("$", "").replace(",", "")) * 100;
      if (!Number.isNaN(postedCents)) {
        const remainderCents = postedCents % 100;

        if (remainderCents === 0) {
          claimsResult = claimsResult.filter(
            (c) =>
              c.claimPaymentAmount >= postedCents &&
              // if there are no cents specified, we want to match any amount
              // within the dollar
              c.claimPaymentAmount < postedCents + 100,
          );
        } else {
          claimsResult = claimsResult.filter(
            (c) => c.claimPaymentAmount === postedCents,
          );
        }
      }
    }

    return {
      claims: claimsResult,
    };
  }

  throw new Error(
    "[Invariant] No group by available for posted (claims or payments)",
  );
}

async function handlePayerGroups(
  _options: DiskAction.PayerGroups["options"],
): Promise<DiskAction.PayerGroups["response"]> {
  const selectedPractice = LocalStorage.get("selectedPractice");

  if (!selectedPractice) {
    throw new Error("No selected practice");
  }

  const payerGroupsQuery = db
    .select({
      payerGroup: Tables.payerGroups,
    })
    .from(Tables.eobPayments)
    .innerJoin(
      Tables.payerGroups,
      and(
        eq(Tables.eobPayments.payerGroupId, Tables.payerGroups.id),
        eq(Tables.eobPayments.practiceUuid, selectedPractice),
      ),
    )
    .where(eq(Tables.payerGroups.practiceUuid, selectedPractice))
    .groupBy(Tables.payerGroups.id)
    .orderBy(desc(count(Tables.eobPayments.id)));

  const payerGroupsResult = await payerGroupsQuery.execute();

  const payerGroups = payerGroupsResult.map((pg) => pg.payerGroup);

  return { payerGroups };
}

async function handleFamily(
  options: DiskAction.Family["options"],
): Promise<DiskAction.Family["response"]> {
  const { familyId } = options;
  const patientResult = await db
    .select({
      id: Tables.patients.id,
      bookId: Tables.patients.bookId,
      firstName: Tables.patients.firstName,
      lastName: Tables.patients.lastName,
      dateOfBirth: Tables.patients.dateOfBirth,
      familyId: Tables.patients.familyId,
      plans: Tables.patients.plans,
      createdAt: Tables.patients.createdAt,
      updatedAt: Tables.patients.updatedAt,
      deletedAt: Tables.patients.deletedAt,
      lastViewedAt: Tables.patients.lastViewedAt,
      balance: Tables.patients.balance,
      isGuarantor: Tables.patients.isGuarantor,
    })
    .from(Tables.patients)
    .where(eq(Tables.patients.familyId, familyId))
    .groupBy(Tables.patients.id)
    .execute();

  // TODO: FIX THIS TYPE
  return { family: patientResult } as any;
}

async function handleBalances(
  options: DiskAction.Balances["options"],
): Promise<DiskAction.Balances["response"]> {
  const timer = new Timer("Balances RPC Timer");

  const { type, pagination, filters } = options;

  // pagination
  const offsetBy = (pagination.page - 1) * pagination.size;

  const selectedPractice = LocalStorage.get("selectedPractice");

  if (!selectedPractice) {
    throw new Error("No selected practice");
  }

  const patients = await db
    .select()
    .from(Tables.patients)
    .limit(pagination.size)
    .offset(offsetBy)
    .where(() => {
      const conditions: SQLWrapper[] = [
        isNotNull(Tables.patients.balance),
        eq(Tables.patients.isGuarantor, true),
        eq(Tables.patients.practiceUuid, selectedPractice),
        sql`json_extract(${Tables.patients.balance}, '$.openFamilyBalance') = 0`,
        // less than 0 for credits, greater than 0 for debits
        type === "debit"
          ? sql`json_extract(${Tables.patients.balance}, '$.totalFamilyBalance') > 0`
          : sql`json_extract(${Tables.patients.balance}, '$.totalFamilyBalance') < 0`,
        sql`json_extract(${Tables.patients.balance}, '$.resolved') = 0`,
        sql`json_extract(${Tables.patients.balance}, '$.archivedAt') is NULL`,
      ];

      if (filters?.lastName) {
        conditions.push(
          like(Tables.patients.lastName, `%${filters.lastName}%`),
        );
      }

      return and(...conditions);
    })
    .orderBy(
      sql`json_extract(${Tables.patients.balance}, '$.lastClaimPostedAt') DESC NULLS LAST,
          json_extract(${Tables.patients.balance}, '$.totalFamilyBalance') ${type === "debit" ? sql`DESC` : sql`ASC`}`,
    )
    .execute();

  const totals = await db
    .select({
      debitsCount: sql<number>`
      COUNT(CASE WHEN json_extract(${Tables.patients.balance}, '$.totalFamilyBalance') > 0 THEN 1 END)
    `,
      creditsCount: sql<number>`
      COUNT(CASE WHEN json_extract(${Tables.patients.balance}, '$.totalFamilyBalance') < 0 THEN 1 END)
    `,
      debitsSum: sql<number>`
      COALESCE(SUM(CASE 
        WHEN json_extract(${Tables.patients.balance}, '$.totalFamilyBalance') > 0 
        THEN CAST(json_extract(${Tables.patients.balance}, '$.totalFamilyBalance') AS FLOAT) 
        END), 0)
    `,
      creditsSum: sql<number>`
      COALESCE(SUM(CASE 
        WHEN json_extract(${Tables.patients.balance}, '$.totalFamilyBalance') < 0 
        THEN CAST(json_extract(${Tables.patients.balance}, '$.totalFamilyBalance') AS FLOAT) 
        END), 0)
    `,
    })
    .from(Tables.patients)
    .where(
      and(
        isNotNull(Tables.patients.balance),
        eq(Tables.patients.isGuarantor, true),
        eq(Tables.patients.practiceUuid, selectedPractice),
        sql`json_extract(${Tables.patients.balance}, '$.openFamilyBalance') = 0`,
        sql`json_extract(${Tables.patients.balance}, '$.resolved') = 0`,
        sql`json_extract(${Tables.patients.balance}, '$.archivedAt') is NULL`,
        filters?.lastName
          ? like(Tables.patients.lastName, `%${filters.lastName}%`)
          : undefined,
      ),
    )
    .execute();

  timer.check("TOTALS QUERY");

  timer.elapsed();

  return {
    patients,

    // totals
    debitsCount: totals[0].debitsCount,
    creditsCount: totals[0].creditsCount,
    debitsAmount: totals[0].debitsSum,
    creditsAmount: totals[0].creditsSum,

    // TODO: FIX THIS TYPE
  } as any;
}

const UNIFIED_PAYMENT_DATE = sql`COALESCE(${Tables.bankTransactions.accruedAt}, ${Tables.eobPayments.paymentDate})`;

function getUnifiedPaymentsConditions(
  tab: DiskAction.UnifiedPayments["options"]["tab"],
  selectedPractice: string,
  postingSubquery: SubqueryWithSelection<any, any>,
  statusSubquery: SubqueryWithSelection<any, any>,
  query?: Omit<UnifiedPaymentsFilters, "page">,
  isInternalUser?: boolean,
) {
  const conditions: (SQLWrapper | SQL | undefined)[] = [
    or(
      isNull(Tables.eobPayments.id),
      not(eq(Tables.eobPayments.paymentMethod, "NON_PAYMENT")),
    ),
    or(
      eq(Tables.eobPayments.practiceUuid, selectedPractice),
      eq(Tables.bankTransactions.practiceUuid, selectedPractice),
    ),
    or(
      isNull(Tables.bankTransactions.id),
      isNull(Tables.bankTransactions.deletedAt),
    ),
    lte(sql`COALESCE(${Tables.bankTransactions.amount}, 0)`, 0),
  ];

  const ONE_YEAR_AGO = subYears(new Date(), 1).toISOString();
  const SEVEN_DAYS_AGO = subDays(new Date(), 7).toISOString();

  const MISSING_EOB_START_DATE = isInternalUser
    ? // internal users see all missing EOBs
      new Date("1970-01-01").toISOString()
    : new Date("2024-11-21").toISOString();

  if (tab === "Needs Review") {
    conditions.push(
      or(
        // pdf only -> mark posted
        and(
          sql<boolean>`${Tables.eobPayments.pdfPath} IS NOT NULL AND COALESCE(${statusSubquery.total_claims}, 0) = 0`,
          isNull(Tables.eobPayments.markedPostedByPracticeAt),
          not(
            and(
              not(eq(Tables.eobPayments.paymentMethod, "ACH")),
              isNull(Tables.eobPayments.receivedByPracticeAt),
            ) as SQLWrapper,
          ),
          gte(UNIFIED_PAYMENT_DATE, MISSING_EOB_START_DATE),
        ),
        // missing EOB -> mark posted
        and(
          isNull(Tables.eobPayments.id),
          isNull(Tables.bankTransactions.markedPostedByPracticeAt),

          // ignore "awaiting" EOBs
          between(UNIFIED_PAYMENT_DATE, MISSING_EOB_START_DATE, SEVEN_DAYS_AGO),
        ),
        // pending tasks -> resolve tasks
        sql`COALESCE(pending.pending_tasks, 0) > 0`,
      ),
    );

    conditions.push(
      gte(UNIFIED_PAYMENT_DATE, postingSubquery.postingStartDate),
      gte(UNIFIED_PAYMENT_DATE, ONE_YEAR_AGO),
      lte(UNIFIED_PAYMENT_DATE, endOfDay(new Date()).toISOString()),
    );
  }

  if (tab === "Missing Transaction") {
    conditions.push(
      and(
        isNull(Tables.eobPayments.bankTransactionId),
        isNull(Tables.bankTransactions.id),
        and(
          eq(Tables.eobPayments.paymentMethod, "ACH"),
          isNull(Tables.eobPayments.receivedByPracticeAt),
        ),
        gte(Tables.eobPayments.paymentDate, ONE_YEAR_AGO),
        lte(Tables.eobPayments.paymentDate, SEVEN_DAYS_AGO),
        gte(UNIFIED_PAYMENT_DATE, postingSubquery.postingStartDate),
      ),
    );
  }

  if (tab === "Paper Checks") {
    conditions.push(
      and(
        isNull(Tables.eobPayments.bankTransactionId),
        isNull(Tables.bankTransactions.id),
        isNull(Tables.eobPayments.receivedByPracticeAt),
        gte(UNIFIED_PAYMENT_DATE, postingSubquery.postingStartDate),
      ),
    );

    conditions.push(
      and(
        not(eq(Tables.eobPayments.paymentMethod, "ACH")),
        gte(Tables.eobPayments.paymentDate, ONE_YEAR_AGO),
      ),
    );
  }

  if (query) {
    if (query.payerGroupId) {
      conditions.push(
        or(
          eq(Tables.eobPayments.payerGroupId, query.payerGroupId),
          eq(Tables.bankTransactions.payerGroupId, query.payerGroupId),
        ),
      );
    }

    if (query.transactionStatus) {
      if (query.transactionStatus === "Received") {
        conditions.push(
          or(
            isNotNull(Tables.bankTransactions.accruedAt),
            isNotNull(Tables.eobPayments.receivedByPracticeAt),
          ),
        );
      } else if (query.transactionStatus === "Not Received") {
        conditions.push(
          and(
            isNull(Tables.bankTransactions.id),
            isNull(Tables.eobPayments.receivedByPracticeAt),
          ),
        );
      }
    }

    if (query.paymentMethod) {
      if (query.paymentMethod === "ACH") {
        conditions.push(
          or(
            eq(Tables.eobPayments.paymentMethod, "ACH"),
            isNotNull(Tables.bankTransactions.id),
          ),
        );
      } else {
        conditions.push(
          eq(Tables.eobPayments.paymentMethod, query.paymentMethod),
        );
      }
    }

    if (query.dateRange) {
      const dateRange = DATE_RANGE_SCHEMA.parse(query.dateRange);

      if (dateRange) {
        const { from, to } = dateRange;

        conditions.push(
          gte(UNIFIED_PAYMENT_DATE, startOfDay(subDays(from, 1)).toISOString()),
          lte(UNIFIED_PAYMENT_DATE, endOfDay(subDays(to, 1)).toISOString()),
        );
      }
    }

    if (query.paymentNumber) {
      conditions.push(
        or(
          like(Tables.eobPayments.paymentNumber, `%${query.paymentNumber}%`),
          like(Tables.bankTransactions.traceNumber, `%${query.paymentNumber}%`),
        ),
      );
    }

    const paymentAmount = query.paymentAmount;

    if (paymentAmount) {
      const paymentCents =
        Number(paymentAmount.replace("$", "").replace(",", "")) * 100;
      if (!Number.isNaN(paymentCents)) {
        const remainderCents = paymentCents % 100;

        if (remainderCents === 0) {
          conditions.push(
            gte(
              sql<number>`COALESCE(${Tables.eobPayments.paymentAmount}, -${Tables.bankTransactions.amount})`,
              paymentCents,
            ),
            lt(
              sql<number>`COALESCE(${Tables.eobPayments.paymentAmount}, -${Tables.bankTransactions.amount})`,
              paymentCents + 100,
            ),
          );
        } else {
          conditions.push(
            eq(
              sql<number>`COALESCE(${Tables.eobPayments.paymentAmount}, -${Tables.bankTransactions.amount})`,
              Math.round(paymentCents),
            ),
          );
        }
      }
    }
  }

  return and(...conditions);
}

async function handleUnifiedPayments(
  options: DiskAction.UnifiedPayments["options"],
): Promise<DiskAction.UnifiedPayments["response"]> {
  const timer = new Timer(`Unified Payments RPC Timer ${options.tab}`);

  const selectedPractice = LocalStorage.get("selectedPractice");

  if (!selectedPractice) {
    throw new Error("No selected practice");
  }

  const { tab, pagination, query } = options;

  const offsetBy = (pagination.page - 1) * pagination.size;

  // Subquery to get the posted amount and total amount for each payment
  const statusSubquery = db
    .select({
      paymentId: Tables.eobClaims.paymentId,
      total_claims: sql<number>`COALESCE(COUNT(${Tables.eobClaims.id}), 0)`.as(
        "total_claims",
      ),
      posted: sql<number>`SUM(CASE 
      WHEN ${Tables.eobClaims.status} IN ('POSTED_BY_US', 'POSTED_BY_PRACTICE', 'SKIPPED')
      THEN ${Tables.eobClaims.paymentAmount} 
      ELSE 0 
    END)`.as("posted"),
      amount: sql<number>`SUM(${Tables.eobClaims.paymentAmount})`.as("amount"),
    })
    .from(Tables.eobClaims)
    .where(eq(Tables.eobClaims.practiceUuid, selectedPractice))
    .groupBy(Tables.eobClaims.paymentId)
    .as("status");

  const pendingSubquery = db
    .select({
      paymentId: Tables.tasks.paymentId,
      pending_post_claims: sql<number>`COUNT(CASE 
      WHEN ${Tables.tasks.type} = 'POST_CLAIM'
      AND ${Tables.tasks.completedAt} IS NULL 
      AND ${Tables.tasks.deletedAt} IS NULL
      THEN ${Tables.tasks.id} 
      ELSE NULL 
    END)`.as("pending_post_claims"),
      pending_tasks: sql<number>`COUNT(CASE 
      WHEN (${Tables.tasks.type} = 'POST_CLAIM'
      OR ${Tables.tasks.type} = 'RESOLVE_PAYMENT'
      OR (${Tables.tasks.type} = 'OTHER' AND ${Tables.tasks.claimId} IS NULL))
      AND ${Tables.tasks.completedAt} IS NULL 
      AND ${Tables.tasks.deletedAt} IS NULL
      THEN ${Tables.tasks.id} 
      ELSE NULL 
    END)`.as("pending_tasks"),
    })
    .from(Tables.tasks)
    .where(eq(Tables.tasks.practiceUuid, selectedPractice))
    .groupBy(Tables.tasks.paymentId)
    .as("pending");

  const postingSubquery = db
    .select({
      payerGroupId: Tables.payerGroups.id,
      postingStartDate: Tables.payerGroups.postingStartDate,
    })
    .from(Tables.payerGroups)
    .where(eq(Tables.payerGroups.practiceUuid, selectedPractice))
    .as("posting");

  const unifiedPaymentsQuery = db
    .select({
      total: sql<number>`COUNT(*) OVER()`.as("total"),
      key: sql<string>`COALESCE(
        NULLIF(COALESCE(${Tables.eobPayments.id}, 'missing') || '-' || COALESCE(${Tables.bankTransactions.id}, 'missing'), 'missing-missing'),
        'missing'
      )`,
      payment: Tables.eobPayments,
      bankTransaction: Tables.bankTransactions,
      status: {
        fullyPosted:
          sql<boolean>`(eobPayments.paymentAmount + COALESCE(eobPayments.adjustmentAmount, 0) = COALESCE(status.posted, 0)) AND COALESCE(pending.pending_post_claims, 0) = 0`.as(
            "fullyPosted",
          ),
        partiallyPosted: sql<boolean>`COALESCE(status.posted, 0) != 0`.as(
          "partiallyPosted",
        ),
        complete:
          sql<boolean>`${Tables.eobPayments.paymentAmount} + COALESCE(${Tables.eobPayments.adjustmentAmount}, 0) = COALESCE(status.amount, 0)`.as(
            "complete",
          ),
      },
      beforeLassie: sql<boolean>`${postingSubquery.postingStartDate} > ${UNIFIED_PAYMENT_DATE}`,
      pdfOnly:
        sql<boolean>`${Tables.eobPayments.pdfPath} IS NOT NULL AND COALESCE(${statusSubquery.total_claims}, 0) = 0`.as(
          "pdfOnly",
        ),
      pendingPostClaimTasks: pendingSubquery.pending_post_claims,
      pendingTasks: pendingSubquery.pending_tasks,
    })
    .from(Tables.eobPayments)
    .fullJoin(
      Tables.bankTransactions,
      and(
        eq(Tables.eobPayments.bankTransactionId, Tables.bankTransactions.id),
        isNull(Tables.bankTransactions.deletedAt),
      ),
    )
    .leftJoin(
      statusSubquery,
      eq(Tables.eobPayments.id, statusSubquery.paymentId),
    )
    .leftJoin(
      pendingSubquery,
      eq(Tables.eobPayments.id, pendingSubquery.paymentId),
    )
    .leftJoin(
      postingSubquery,
      eq(
        sql`COALESCE(${Tables.eobPayments.payerGroupId}, ${Tables.bankTransactions.payerGroupId})`,
        postingSubquery.payerGroupId,
      ),
    )
    .where(
      getUnifiedPaymentsConditions(
        tab,
        selectedPractice,
        postingSubquery,
        statusSubquery,
        query,
        options.isInternalUser ?? false,
      ),
    )
    .orderBy(
      sql`COALESCE(${Tables.bankTransactions.accruedAt}, ${Tables.eobPayments.paymentDate}) DESC`,
    )
    .limit(pagination.size)
    .offset(offsetBy);

  const unifiedPaymentsResult = await unifiedPaymentsQuery.execute();

  const total = unifiedPaymentsResult?.[0]?.total ?? 0;

  timer.check(
    `Query done. Page ${pagination.page} of ${Math.ceil(
      total / pagination.size,
    )}. ${pagination.size} of ${total}`,
  );

  const unifiedPayments = unifiedPaymentsResult
    .map((up) => {
      const status: Client.UnifiedPayment["status"] = {
        transactionStatus: getTransactionStatusLabel(
          up.bankTransaction,
          up.payment,
          up.status,
          up.beforeLassie,
        ),
        postedStatus: getPostedStatusLabel(
          up.bankTransaction,
          up.payment,
          up.status,
          up.beforeLassie,
        ),
        eobStatus: getEobStatusLabel(
          up.bankTransaction,
          up.payment,
          up.status,
          up.beforeLassie,
          up.pdfOnly,
        ),
      };

      return {
        key: up.key,
        payment: up.payment,
        bankTransaction: up.bankTransaction,
        status,
        beforeLassie: up.beforeLassie,
        pendingPostClaimTasks: up.pendingPostClaimTasks,
        pendingTasks: up.pendingTasks,
      };
    })
    .filter((up) => up !== null);

  timer.check("Mapping done");

  return { unifiedPayments, total };
}

async function handleNeedsReviewCount(
  _options: DiskAction.NeedsReviewCount["options"],
): Promise<DiskAction.NeedsReviewCount["response"]> {
  const timer = new Timer("Needs Review Count RPC Timer");

  const selectedPractice = LocalStorage.get("selectedPractice");

  if (!selectedPractice) {
    throw new Error("No selected practice");
  }

  // Subquery to get the posted amount and total amount for each payment
  const statusSubquery = db
    .select({
      paymentId: Tables.eobClaims.paymentId,
      total_claims: sql<number>`COALESCE(COUNT(${Tables.eobClaims.id}), 0)`.as(
        "total_claims",
      ),
      posted: sql<number>`SUM(CASE 
      WHEN ${Tables.eobClaims.status} IN ('POSTED_BY_US', 'POSTED_BY_PRACTICE', 'SKIPPED')
      THEN ${Tables.eobClaims.paymentAmount} 
      ELSE 0 
    END)`.as("posted"),
      amount: sql<number>`SUM(${Tables.eobClaims.paymentAmount})`.as("amount"),
    })
    .from(Tables.eobClaims)
    .where(eq(Tables.eobClaims.practiceUuid, selectedPractice))
    .groupBy(Tables.eobClaims.paymentId)
    .as("status");

  const pendingSubquery = db
    .select({
      paymentId: Tables.tasks.paymentId,
      pending_post_claims: sql<number>`COUNT(CASE 
      WHEN ${Tables.tasks.type} = 'POST_CLAIM'
      AND ${Tables.tasks.completedAt} IS NULL 
      AND ${Tables.tasks.deletedAt} IS NULL
      THEN ${Tables.tasks.id} 
      ELSE NULL 
    END)`.as("pending_post_claims"),
      pending_tasks: sql<number>`COUNT(CASE 
      WHEN (${Tables.tasks.type} = 'POST_CLAIM'
      OR ${Tables.tasks.type} = 'RESOLVE_PAYMENT'
      OR (${Tables.tasks.type} = 'OTHER' AND ${Tables.tasks.claimId} IS NULL))
      AND ${Tables.tasks.completedAt} IS NULL 
      AND ${Tables.tasks.deletedAt} IS NULL
      THEN ${Tables.tasks.id} 
      ELSE NULL 
    END)`.as("pending_tasks"),
    })
    .from(Tables.tasks)
    .where(eq(Tables.tasks.practiceUuid, selectedPractice))
    .groupBy(Tables.tasks.paymentId)
    .as("pending");

  const postingSubquery = db
    .select({
      payerGroupId: Tables.payerGroups.id,
      postingStartDate: Tables.payerGroups.postingStartDate,
    })
    .from(Tables.payerGroups)
    .where(eq(Tables.payerGroups.practiceUuid, selectedPractice))
    .as("posting");

  const unifiedPaymentsResult = await db
    .select({
      key: sql<string>`COALESCE(
        NULLIF(COALESCE(${Tables.eobPayments.id}, 'missing') || '-' || COALESCE(${Tables.bankTransactions.id}, 'missing'), 'missing-missing'),
        'missing'
      )`,
      payment: Tables.eobPayments,
      bankTransaction: Tables.bankTransactions,
      status: {
        fullyPosted:
          sql<boolean>`(eobPayments.paymentAmount + COALESCE(eobPayments.adjustmentAmount, 0) = COALESCE(status.posted, 0)) AND COALESCE(pending.pending_post_claims, 0) = 0`.as(
            "fullyPosted",
          ),
        partiallyPosted: sql<boolean>`COALESCE(status.posted, 0) != 0`.as(
          "partiallyPosted",
        ),
        complete:
          sql<boolean>`${Tables.eobPayments.paymentAmount} + COALESCE(${Tables.eobPayments.adjustmentAmount}, 0) = COALESCE(status.amount, 0)`.as(
            "complete",
          ),
      },
      beforeLassie: sql<boolean>`${postingSubquery.postingStartDate} > COALESCE(${Tables.eobPayments.paymentDate}, ${Tables.bankTransactions.accruedAt})`,
    })
    .from(Tables.eobPayments)
    .fullJoin(
      Tables.bankTransactions,
      eq(Tables.eobPayments.bankTransactionId, Tables.bankTransactions.id),
    )
    .leftJoin(
      statusSubquery,
      eq(Tables.eobPayments.id, statusSubquery.paymentId),
    )
    .leftJoin(
      pendingSubquery,
      eq(Tables.eobPayments.id, pendingSubquery.paymentId),
    )
    .leftJoin(
      postingSubquery,
      eq(
        sql`COALESCE(${Tables.eobPayments.payerGroupId}, ${Tables.bankTransactions.payerGroupId})`,
        postingSubquery.payerGroupId,
      ),
    )
    .where(
      getUnifiedPaymentsConditions(
        "Needs Review",
        selectedPractice,
        postingSubquery,
        statusSubquery,
        undefined,
        false,
      ),
    )
    .execute();

  timer.elapsed();

  return { count: unifiedPaymentsResult.length };
}

async function diskQuery(
  action: DiskAction.Query,
): Promise<DiskAction.Response<typeof action>> {
  if (action.name === "patients") {
    return handlePatients(action.options);
  }
  if (action.name === "ledger") {
    return handlePatientLedger(action.options);
  }
  if (action.name === "realtime") {
    return null;
  }
  if (action.name === "patientInbox") {
    return handlePatientInbox(action.options);
  }
  if (action.name === "allPayments") {
    return handleAllPayments(action.options);
  }
  if (action.name === "payments") {
    return handlePayments(action.options);
  }
  if (action.name === "payment") {
    return handlePayment(action.options);
  }
  if (action.name === "completedPatientInbox") {
    return handleCompletedPatientInbox(action.options);
  }
  if (action.name === "posted") {
    return handlePosted(action.options);
  }
  if (action.name === "payerGroups") {
    return handlePayerGroups(action.options);
  }
  if (action.name === "family") {
    return handleFamily(action.options);
  }
  if (action.name === "balances") {
    return handleBalances(action.options);
  }
  if (action.name === "unifiedPayments") {
    return handleUnifiedPayments(action.options);
  }
  if (action.name === "needsReviewCount") {
    return handleNeedsReviewCount(action.options);
  }

  // server only
  if (action.name === "insuranceLogins") {
    return handleInsuranceLogins(action.options);
  }
  if (action.name === "bankAccounts") {
    return handleBankAccounts(action.options);
  }
  if (action.name === "search") {
    return handleSearch(action.options);
  }
  if (action.name === "recentSearches") {
    return handleRecentSearches();
  }
  if (action.name === "clearRecentSearch") {
    return handleClearRecentSearch(action.options);
  }
  if (action.name === "createBankLink") {
    return handleCreateBankLink(action.options);
  }
  if (action.name === "completeBankLink") {
    return handleCompleteBankLink(action.options);
  }
  if (action.name === "removeBankLink") {
    return handleRemoveBankLink(action.options);
  }
  if (action.name === "alerts") {
    return handleAlerts(action.options);
  }
  throw new Error("Invalid query");
}

export function baseQuery() {
  return async (action: DiskAction.Query) => {
    try {
      const data = await diskQuery(action);

      return { data };
    } catch (error) {
      return { error };
    }
  };
}
