import { addBreadcrumb } from "@sentry/react";
import { SharedService, createSharedServicePort } from "./sqlite-proxy";

/**
 * Sync worker for the current tab/context
 */
export const localSyncWorker = new ComlinkWorker<
  typeof import("../workers/sync")
>(new URL("../workers/sync.ts", import.meta.url), {
  type: "module",
  name: "lassie.sync-worker",
});

// this function is called when this instance is designated as the
// service provider. The port is created locally here but it could
// come from a different context, e.g. a Worker.
function portProvider() {
  log("appointed provider");
  const port = createSharedServicePort(localSyncWorker);
  return port;
}

// create the shared service.
log("start");
const sharedService = new SharedService("sync", portProvider);
sharedService.activate();

/**
 * Proxy to active sync worker
 */
export const syncWorker = sharedService.proxy as typeof localSyncWorker;
export type SyncWorker = typeof syncWorker;

function log(s: string) {
  if (IS_PRODUCTION) {
    addBreadcrumb({
      category: "shared-service",
      message: s,
      level: "debug",
    });
  }

  const TIME_FORMAT = {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    fractionalSecondDigits: 3,
  };
  // @ts-ignore
  const timestamp = new Date().toLocaleTimeString(undefined, TIME_FORMAT);

  if (typeof logger !== "undefined") {
    logger.info(`[shared-service] ${timestamp} ${s}\n`);
  }
}
