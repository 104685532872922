import type {
  Ehr,
  PostingMetadata,
  BankTransaction as ServerBankTransaction,
  EobClaim as ServerEobClaim,
  EobPayment as ServerEobPayment,
  Task as ServerTask,
} from "@lassie/types";

export enum TransactionStatusLabel {
  BEFORE_LASSIE = "Before Lassie",
  MISSING = "Missing",
  AWAITING = "Awaiting",
  RECEIVED_BY_PRACTICE = "Received by Practice",
  RECEIVED_IN_BANK = "Received in Bank",
}

export enum EobStatusLabel {
  PROCESSED = "Processed", // green file w/ check
  PROCESSING = "Processing", // file with exclamation point
  PDF_ONLY = "PDF Only", // file with question mark
  MISSING = "Missing", // red file with question mark or x...
  AWAITING = "Awaiting", // greyed out file
  BEFORE_LASSIE = "Before Lassie", // "-"
}

export enum PostedStatusLabel {
  BEFORE_LASSIE = "Before Lassie",
  NOT_POSTED = "Not Posted",
  QUEUED = "Queued",
  PARTIALLY_POSTED = "Partially Posted",
  POSTED = "Posted",
}

/**
 * Types between the disk and the UI
 *
 * e.g. Procedures codes are embedded onto the procedure model here,
 * but we store them separately in SQLite
 */
export namespace Client {
  export type Procedure = Omit<
    Ehr.Procedure,
    "procedureCodeId" | "providerId"
  > & {
    procedureCode: string | null;
    friendlyDescription: string | null;
    description: string | null;
    provider: {
      firstName: string;
      lastName: string;
      abbreviation: string;
    };
  };

  export type Adjustment = Omit<Ehr.Adjustment, "providerId"> & {
    provider: {
      firstName: string;
      lastName: string;
      abbreviation: string;
    };
  };

  export type Task = ServerTask;

  export type EobClaim = ServerEobClaim;

  export type EobPayment = ServerEobPayment;
  export type BankTransaction = ServerBankTransaction;

  export type EobPaymentStatus = {
    fullyPosted: boolean;
    partiallyPosted: boolean;
    complete: boolean;
  };

  export type UnifiedPayment = {
    key: string;
    payment: EobPayment | null;
    bankTransaction: Client.BankTransaction | null;
    status: {
      transactionStatus: TransactionStatusLabel;
      postedStatus: PostedStatusLabel;
      eobStatus: EobStatusLabel;
    };
    beforeLassie: boolean;
    pendingPostClaimTasks: number;
    pendingTasks: number;

    // if true, the payment has been removed from the list
    // use for animating out of view
    removed?: boolean;
  };

  // todo rename to be more specific
  export type Payment = {
    payment: Client.EobPayment;
    claims: (Client.EobClaim & { hasClaimProcedures: boolean })[];
    bankTransaction: Client.BankTransaction | null;
    tasks: ServerTask[];
  };

  // todo rename to be use-case driven
  export type Eob = {
    claim: EobClaim;
    ehrClaimId: string;
    payment: EobPayment;
    task: Task | null;
  };

  export type Patient = Ehr.Patient;

  export type InboxTask = Omit<
    Task,
    "claimId" | "paymentId" | "bankTransactionId" | "deletedAt"
  >;

  export type PatientInboxTask = InboxTask & {
    patient: Patient;
    eobClaim: EobClaim;
    eobPayment: EobPayment;
  };

  /** Patients from the inbox have tasks embedded on them */
  export type InboxPatient = Patient & {
    tasks: InboxTask[];
  };

  /** Eobs from the inbox have tasks embedded on them */
  export type InboxPayment = Omit<Payment, "claims" | "tasks"> & {
    task: InboxTask | null;
    claims: (Client.EobClaim & { task: InboxTask | null })[];
  };

  export type PostedPayment = {
    eobPayment: EobPayment & { task: Client.InboxTask | null };
    /** date claims were processed */
    claimsProcessedAt: string;
    /** amount posted */
    claimPaymentAmount: number;
    postedByLassie: number;
    postedByPractice: number;
  };

  export type PostedClaim = {
    eobClaim: ServerEobClaim;
    eobPayment: EobPayment & { task: Client.InboxTask | null };
    /** date claims were processed */
    claimsProcessedAt: string;
    /** amount posted */
    claimPaymentAmount: number;
    postedByLassie: number;
    postedByPractice: number;
  };

  export type BookEntry = Ehr.BookEntry & {
    modelId: string | null;
  };
  export type PatientPayment = Ehr.PatientPayment;
  export type InsurancePayment = Ehr.InsurancePayment & {
    detailsId: string;
  };
  export type Claim = Ehr.Claim & {
    postingMetadata: PostingMetadata[];
  };

  export type SearchResult = {
    type: "patient" | "payment" | "claim";
    rank: number;
    item: Patient | Payment | Claim;
  };
}
