import { useLocation, useNavigate, useSearch } from "@tanstack/react-router";
import { useCallback } from "react";
import { z } from "zod";

export const BALANCES_TABS = ["debit", "credit"] as const;
export type BalanceTab = (typeof BALANCES_TABS)[number];

export const BALANCES_FILTERS_SCHEMA = z.object({
  tab: z.enum(BALANCES_TABS).catch("debit"),
  query: z.string().optional().catch(undefined),
  page: z.number().catch(1),
});

export const BALANCES_FILTER_SEARCH_PARAMS_SCHEMA =
  BALANCES_FILTERS_SCHEMA.omit({
    tab: true,
  }).catch({
    page: 1,
    query: undefined,
  });

export type BalancesFilters = z.infer<
  typeof BALANCES_FILTER_SEARCH_PARAMS_SCHEMA
>;

/**
 * Parses the current tab from the router location
 */
export function useBalancesTab(): BalanceTab {
  const { pathname } = useLocation();
  // could be /patients/balances or /patients/credits
  const tab = pathname.split("/patients/").at(-1);
  return tab === "balances" ? "debit" : "credit";
}

export function useBalances() {
  const navigate = useNavigate();
  const tab = useBalancesTab();
  const search = useSearch({
    from: "/_dashboard/patients/_balances",
  });

  const setQuery = useCallback(
    (query: string) => {
      navigate({
        from: `/patients/${tab === "debit" ? "balances" : "credits"}`,
        to: window.location.pathname,
        search: (prev) => ({
          ...prev,
          query,
          page: 1,
        }),
        replace: true,
      });
    },
    [navigate, tab],
  );

  const setPage = useCallback(
    (page: number) => {
      navigate({
        from: `/patients/${tab === "debit" ? "balances" : "credits"}`,
        to: window.location.pathname,
        search: (prev) => ({ ...prev, page }),
      });
    },
    [navigate, tab],
  );

  return {
    // Current state
    tab,
    page: search.page,
    query: search.query,

    // Actions
    setQuery,
    setPage,
  };
}
