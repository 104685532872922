import z from "zod";
import {
  baseLedgerItemSchema,
  deletableSchema,
  objectMetaSchema,
} from "./generic";
import { balanceSchema } from "./rest";

/*
 * Procedures
 */
export const procedureSchema = z.object({
  id: z.string(),
  ehrNumber: z.number(),
  procedureCodeId: z.string(),
  dateOfService: z.string(),
  tooth: z.string().nullable(),
  claimIds: z.array(z.string()).nullable(),
  ledgerAmount: z.number(),
  patientId: z.string(),
  providerId: z.string(),
  ...objectMetaSchema.shape,
  ...deletableSchema.shape,
  ...baseLedgerItemSchema.shape,
});

export const procedureCodeSchema = z.object({
  id: z.string(),
  procedureCode: z.string(),
  description: z.string(),
  abbreviatedDescription: z.string().nullable(),
  friendlyDescription: z.string().nullable(),
  ...objectMetaSchema.shape,
  ...deletableSchema.shape,
});

/**
 * Claim Procedures
 */
export const claimProcedureSchema = z.object({
  id: z.string(),
  ehrNumber: z.number(),
  claimId: z.string(),
  patientId: z.string(),
  procedureId: z.string(),
  submittedAmount: z.number().nullish(),
  itemizedAmount: z.number().nullish(),
  deductibleAmount: z.number().nullish(),
  note: z.string().nullish(),
  ...objectMetaSchema.shape,
  ...deletableSchema.shape,
});

/*
 * Insurance Payments
 */
export const insurancePaymentDetailsSchema = z.object({
  id: z.string(),
  paymentDate: z.string(),
  paymentAmount: z.number(),
  paymentType: z.string().nullish(),
  paymentMethod: z.enum(["ACH", "VCC", "CHECK", "NON_PAYMENT"]).nullish(),
  paymentNumber: z.string().nullish(),
  carrierName: z.string().nullish(),
  dateIssued: z.string().nullish(),
  entryDate: z.string().nullish(),
});

export const insurancePaymentSchema = z.object({
  id: z.string(),
  claimId: z.string(),
  claimProcedureId: z.string().nullish(),
  patientId: z.string(),
  providerId: z.string(),
  paymentAmount: z.number(),
  details: insurancePaymentDetailsSchema,
  ...objectMetaSchema.shape,
  ...deletableSchema.shape,
  ...baseLedgerItemSchema.shape,
});

/*
 * Patient Payments
 */
export const patientPaymentDetailsSchema = z.object({
  id: z.string(),
  paymentDate: z.string(),
  paymentAmount: z.number(),
  paymentNumber: z.string().nullish(),
  paymentType: z.string(),
  entryDate: z.string().nullish(),
  note: z.string().nullish(),
});

export const patientPaymentSchema = z.object({
  id: z.string(),
  patientId: z.string(),
  procedureId: z.string().nullish(),
  paymentAmount: z.number(),
  details: patientPaymentDetailsSchema,
  ...objectMetaSchema.shape,
  ...deletableSchema.shape,
  ...baseLedgerItemSchema.shape,
});

/*
 * Providers
 */
export const providerSchema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  abbreviation: z.string(),
  ...objectMetaSchema.shape,
  ...deletableSchema.shape,
});

/*
 * Adjustments
 */
export const adjustmentSchema = z.object({
  id: z.string(),
  claimId: z.string().nullish(),
  claimProcedureId: z.string().nullish(),
  patientId: z.string(),
  providerId: z.string(),
  adjustmentAmount: z.number(),
  adjustmentDirection: z.enum(["CREDIT", "DEBIT"]),
  adjustmentDate: z.string(),
  adjustmentType: z.string(),
  note: z.string().nullish(),
  procedureId: z.string().nullish(),
  ...objectMetaSchema.shape,
  ...deletableSchema.shape,
  ...baseLedgerItemSchema.shape,
});

/*
 * Patients
 */
export const planSchema = z.object({
  id: z.string(),
  payerName: z.string().nullish(),
  groupName: z.string().nullish(),
  groupNumber: z.string().nullish(),
  ehrNumber: z.number().nullish(),
  ehrSubscriberId: z.string().nullish(),
});

export const patientSchema = z.object({
  id: z.string(),
  bookId: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  dateOfBirth: z.string().nullable(),
  familyId: z.string(),
  plans: z.array(planSchema),
  isGuarantor: z.boolean(),
  balance: balanceSchema.nullable(),
  ...objectMetaSchema.shape,
  ...deletableSchema.shape,
});

/*
 * Claims
 */

export const claimSchema = z.object({
  id: z.string(),
  patientId: z.string(),
  ledgerAmount: z.number(),
  creditAdjustmentAmount: z.number(),
  debitAdjustmentAmount: z.number(),
  estimatedPaymentAmount: z.number(),
  receivedDate: z.string().nullable(),
  sentDate: z.string().nullable(),
  claimType: z.enum([
    "PRIMARY",
    "SECONDARY",
    "PRE_AUTH",
    "CAPITATION",
    "OTHER",
  ]),
  claimStatus: z.enum([
    "BATCHED",
    "UNSENT",
    "RECEIVED",
    "SENT",
    "HOLD_UNTIL_PRIMARY_RECEIVED",
  ]),
  claimNote: z.string().nullish(),
  internalNote: z.string().nullish(),
  plan: planSchema,
  ...objectMetaSchema.shape,
  ...deletableSchema.shape,
});

/*
 * Books
 */
export const bookSchema = z.object({
  id: z.string(),
  bookType: z.enum(["PRACTICE", "PATIENT"]),
  ...objectMetaSchema.shape,
});

export const bookEntrySchema = z.object({
  id: z.string(),
  bookId: z.string(),
  debitAmount: z.number(),
  creditAmount: z.number(),
  journalEntryType: z.enum([
    "EHR_PROCEDURE",
    "EHR_PAYMENT",
    "EHR_INSURANCE_PAYMENT",
    "EHR_ADJUSTMENT",
    "EHR_OTHER",
  ]),
  entryDate: z.string(),
  ...objectMetaSchema.shape,
  ...deletableSchema.shape,
  ...baseLedgerItemSchema.shape,
});

/**
 * EHR Types
 */
export namespace Ehr {
  export type Claim = z.infer<typeof claimSchema>;
  export type Procedure = z.infer<typeof procedureSchema>;
  export type ClaimProcedure = z.infer<typeof claimProcedureSchema>;
  export type ProcedureCode = z.infer<typeof procedureCodeSchema>;
  export type InsurancePaymentDetails = z.infer<
    typeof insurancePaymentDetailsSchema
  >;
  export type InsurancePayment = z.infer<typeof insurancePaymentSchema>;
  export type PatientPaymentDetails = z.infer<
    typeof patientPaymentDetailsSchema
  >;
  export type PatientPayment = z.infer<typeof patientPaymentSchema>;
  export type Adjustment = z.infer<typeof adjustmentSchema>;
  export type Plan = z.infer<typeof planSchema>;
  export type Patient = z.infer<typeof patientSchema>;
  export type Provider = z.infer<typeof providerSchema>;
  export type Book = z.infer<typeof bookSchema>;
  export type BookEntry = z.infer<typeof bookEntrySchema>;
}
