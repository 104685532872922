import type { Client } from "./client-types";

export enum PaymentsInboxType {
  NONE = "NONE",
  NEEDS_REVIEW = "NEEDS_REVIEW",
}

// utils
export function isPaymentComplete(payment: Client.InboxPayment) {
  if (payment.task?.completedAt === null) {
    return false;
  }

  if (payment.claims.some((claim) => claim.task?.completedAt === null)) {
    return false;
  }

  return true;
}

export function getPaymentNextTask(payment: Client.InboxPayment) {
  const claimTask = payment.claims.find(
    (claim) => claim.task?.completedAt === null,
  );

  if (claimTask) {
    return claimTask;
  }

  return payment.task;
}
