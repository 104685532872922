/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as ResetImport } from './routes/reset'
import { Route as LogoutImport } from './routes/logout'
import { Route as LoginImport } from './routes/login'
import { Route as AuthorizeImport } from './routes/authorize'
import { Route as DashboardImport } from './routes/_dashboard'
import { Route as DashboardIndexImport } from './routes/_dashboard/index'
import { Route as DashboardInsuranceLoginsImport } from './routes/_dashboard/insurance-logins'
import { Route as DashboardSearchIndexImport } from './routes/_dashboard/search/index'
import { Route as DashboardPostedIndexImport } from './routes/_dashboard/posted/index'
import { Route as DashboardPatientsIndexImport } from './routes/_dashboard/patients/index'
import { Route as DashboardBankAccountsIndexImport } from './routes/_dashboard/bank-accounts/index'
import { Route as DashboardPaymentsInboxImport } from './routes/_dashboard/payments/_inbox'
import { Route as DashboardPatientsTasksImport } from './routes/_dashboard/patients/tasks'
import { Route as DashboardPatientsInboxImport } from './routes/_dashboard.patients.inbox'
import { Route as DashboardPatientsBalancesImport } from './routes/_dashboard/patients/_balances'
import { Route as DashboardPaymentsInboxIndexImport } from './routes/_dashboard/payments/_inbox/index'
import { Route as DashboardPaymentsPaymentIdIndexImport } from './routes/_dashboard/payments/$paymentId/index'
import { Route as DashboardPatientsTasksIndexImport } from './routes/_dashboard/patients/tasks/index'
import { Route as DashboardPatientsPatientIdIndexImport } from './routes/_dashboard/patients/$patientId/index'
import { Route as DashboardPaymentsInboxPaperChecksImport } from './routes/_dashboard/payments/_inbox/paper-checks'
import { Route as DashboardPaymentsInboxNeedsReviewImport } from './routes/_dashboard/payments/_inbox/needs-review'
import { Route as DashboardPaymentsInboxMissingTransactionImport } from './routes/_dashboard/payments/_inbox/missing-transaction'
import { Route as DashboardPatientsTasksReviewImport } from './routes/_dashboard/patients/tasks/review'
import { Route as DashboardPatientsTasksRefileImport } from './routes/_dashboard/patients/tasks/refile'
import { Route as DashboardPatientsTasksFileSecondaryImport } from './routes/_dashboard/patients/tasks/file-secondary'
import { Route as DashboardPatientsTasksDoneImport } from './routes/_dashboard/patients/tasks/done'
import { Route as DashboardPatientsTasksAdjustImport } from './routes/_dashboard/patients/tasks/adjust'
import { Route as DashboardPatientsBalancesFamilyIdImport } from './routes/_dashboard/patients/balances/$familyId'
import { Route as DashboardPatientsBalancesCreditsImport } from './routes/_dashboard/patients/_balances/credits'
import { Route as DashboardPatientsBalancesBalancesImport } from './routes/_dashboard/patients/_balances/balances'
import { Route as DashboardPaymentsPaymentIdClaimsEobClaimIdImport } from './routes/_dashboard/payments/$paymentId/claims.$eobClaimId'
import { Route as DashboardPatientsPatientIdVisitsClaimIdImport } from './routes/_dashboard/patients/$patientId/visits.$claimId'

// Create Virtual Routes

const DashboardPaymentsImport = createFileRoute('/_dashboard/payments')()
const DashboardPatientsImport = createFileRoute('/_dashboard/patients')()

// Create/Update Routes

const ResetRoute = ResetImport.update({
  id: '/reset',
  path: '/reset',
  getParentRoute: () => rootRoute,
} as any)

const LogoutRoute = LogoutImport.update({
  id: '/logout',
  path: '/logout',
  getParentRoute: () => rootRoute,
} as any)

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const AuthorizeRoute = AuthorizeImport.update({
  id: '/authorize',
  path: '/authorize',
  getParentRoute: () => rootRoute,
} as any)

const DashboardRoute = DashboardImport.update({
  id: '/_dashboard',
  getParentRoute: () => rootRoute,
} as any)

const DashboardPaymentsRoute = DashboardPaymentsImport.update({
  id: '/payments',
  path: '/payments',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardPatientsRoute = DashboardPatientsImport.update({
  id: '/patients',
  path: '/patients',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardIndexRoute = DashboardIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardInsuranceLoginsRoute = DashboardInsuranceLoginsImport.update({
  id: '/insurance-logins',
  path: '/insurance-logins',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardSearchIndexRoute = DashboardSearchIndexImport.update({
  id: '/search/',
  path: '/search/',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardPostedIndexRoute = DashboardPostedIndexImport.update({
  id: '/posted/',
  path: '/posted/',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardPatientsIndexRoute = DashboardPatientsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => DashboardPatientsRoute,
} as any)

const DashboardBankAccountsIndexRoute = DashboardBankAccountsIndexImport.update(
  {
    id: '/bank-accounts/',
    path: '/bank-accounts/',
    getParentRoute: () => DashboardRoute,
  } as any,
)

const DashboardPaymentsInboxRoute = DashboardPaymentsInboxImport.update({
  id: '/_inbox',
  getParentRoute: () => DashboardPaymentsRoute,
} as any)

const DashboardPatientsTasksRoute = DashboardPatientsTasksImport.update({
  id: '/tasks',
  path: '/tasks',
  getParentRoute: () => DashboardPatientsRoute,
} as any)

const DashboardPatientsInboxRoute = DashboardPatientsInboxImport.update({
  id: '/inbox',
  path: '/inbox',
  getParentRoute: () => DashboardPatientsRoute,
} as any)

const DashboardPatientsBalancesRoute = DashboardPatientsBalancesImport.update({
  id: '/_balances',
  getParentRoute: () => DashboardPatientsRoute,
} as any)

const DashboardPaymentsInboxIndexRoute =
  DashboardPaymentsInboxIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => DashboardPaymentsInboxRoute,
  } as any)

const DashboardPaymentsPaymentIdIndexRoute =
  DashboardPaymentsPaymentIdIndexImport.update({
    id: '/$paymentId/',
    path: '/$paymentId/',
    getParentRoute: () => DashboardPaymentsRoute,
  } as any)

const DashboardPatientsTasksIndexRoute =
  DashboardPatientsTasksIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => DashboardPatientsTasksRoute,
  } as any)

const DashboardPatientsPatientIdIndexRoute =
  DashboardPatientsPatientIdIndexImport.update({
    id: '/$patientId/',
    path: '/$patientId/',
    getParentRoute: () => DashboardPatientsRoute,
  } as any)

const DashboardPaymentsInboxPaperChecksRoute =
  DashboardPaymentsInboxPaperChecksImport.update({
    id: '/paper-checks',
    path: '/paper-checks',
    getParentRoute: () => DashboardPaymentsInboxRoute,
  } as any)

const DashboardPaymentsInboxNeedsReviewRoute =
  DashboardPaymentsInboxNeedsReviewImport.update({
    id: '/needs-review',
    path: '/needs-review',
    getParentRoute: () => DashboardPaymentsInboxRoute,
  } as any)

const DashboardPaymentsInboxMissingTransactionRoute =
  DashboardPaymentsInboxMissingTransactionImport.update({
    id: '/missing-transaction',
    path: '/missing-transaction',
    getParentRoute: () => DashboardPaymentsInboxRoute,
  } as any)

const DashboardPatientsTasksReviewRoute =
  DashboardPatientsTasksReviewImport.update({
    id: '/review',
    path: '/review',
    getParentRoute: () => DashboardPatientsTasksRoute,
  } as any)

const DashboardPatientsTasksRefileRoute =
  DashboardPatientsTasksRefileImport.update({
    id: '/refile',
    path: '/refile',
    getParentRoute: () => DashboardPatientsTasksRoute,
  } as any)

const DashboardPatientsTasksFileSecondaryRoute =
  DashboardPatientsTasksFileSecondaryImport.update({
    id: '/file-secondary',
    path: '/file-secondary',
    getParentRoute: () => DashboardPatientsTasksRoute,
  } as any)

const DashboardPatientsTasksDoneRoute = DashboardPatientsTasksDoneImport.update(
  {
    id: '/done',
    path: '/done',
    getParentRoute: () => DashboardPatientsTasksRoute,
  } as any,
)

const DashboardPatientsTasksAdjustRoute =
  DashboardPatientsTasksAdjustImport.update({
    id: '/adjust',
    path: '/adjust',
    getParentRoute: () => DashboardPatientsTasksRoute,
  } as any)

const DashboardPatientsBalancesFamilyIdRoute =
  DashboardPatientsBalancesFamilyIdImport.update({
    id: '/balances/$familyId',
    path: '/balances/$familyId',
    getParentRoute: () => DashboardPatientsRoute,
  } as any)

const DashboardPatientsBalancesCreditsRoute =
  DashboardPatientsBalancesCreditsImport.update({
    id: '/credits',
    path: '/credits',
    getParentRoute: () => DashboardPatientsBalancesRoute,
  } as any)

const DashboardPatientsBalancesBalancesRoute =
  DashboardPatientsBalancesBalancesImport.update({
    id: '/balances',
    path: '/balances',
    getParentRoute: () => DashboardPatientsBalancesRoute,
  } as any)

const DashboardPaymentsPaymentIdClaimsEobClaimIdRoute =
  DashboardPaymentsPaymentIdClaimsEobClaimIdImport.update({
    id: '/$paymentId/claims/$eobClaimId',
    path: '/$paymentId/claims/$eobClaimId',
    getParentRoute: () => DashboardPaymentsRoute,
  } as any)

const DashboardPatientsPatientIdVisitsClaimIdRoute =
  DashboardPatientsPatientIdVisitsClaimIdImport.update({
    id: '/$patientId/visits/$claimId',
    path: '/$patientId/visits/$claimId',
    getParentRoute: () => DashboardPatientsRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_dashboard': {
      id: '/_dashboard'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof DashboardImport
      parentRoute: typeof rootRoute
    }
    '/authorize': {
      id: '/authorize'
      path: '/authorize'
      fullPath: '/authorize'
      preLoaderRoute: typeof AuthorizeImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/logout': {
      id: '/logout'
      path: '/logout'
      fullPath: '/logout'
      preLoaderRoute: typeof LogoutImport
      parentRoute: typeof rootRoute
    }
    '/reset': {
      id: '/reset'
      path: '/reset'
      fullPath: '/reset'
      preLoaderRoute: typeof ResetImport
      parentRoute: typeof rootRoute
    }
    '/_dashboard/insurance-logins': {
      id: '/_dashboard/insurance-logins'
      path: '/insurance-logins'
      fullPath: '/insurance-logins'
      preLoaderRoute: typeof DashboardInsuranceLoginsImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/': {
      id: '/_dashboard/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof DashboardIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/patients': {
      id: '/_dashboard/patients'
      path: '/patients'
      fullPath: '/patients'
      preLoaderRoute: typeof DashboardPatientsImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/patients/_balances': {
      id: '/_dashboard/patients/_balances'
      path: '/patients'
      fullPath: '/patients'
      preLoaderRoute: typeof DashboardPatientsBalancesImport
      parentRoute: typeof DashboardPatientsRoute
    }
    '/_dashboard/patients/inbox': {
      id: '/_dashboard/patients/inbox'
      path: '/inbox'
      fullPath: '/patients/inbox'
      preLoaderRoute: typeof DashboardPatientsInboxImport
      parentRoute: typeof DashboardPatientsImport
    }
    '/_dashboard/patients/tasks': {
      id: '/_dashboard/patients/tasks'
      path: '/tasks'
      fullPath: '/patients/tasks'
      preLoaderRoute: typeof DashboardPatientsTasksImport
      parentRoute: typeof DashboardPatientsImport
    }
    '/_dashboard/payments': {
      id: '/_dashboard/payments'
      path: '/payments'
      fullPath: '/payments'
      preLoaderRoute: typeof DashboardPaymentsImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/payments/_inbox': {
      id: '/_dashboard/payments/_inbox'
      path: '/payments'
      fullPath: '/payments'
      preLoaderRoute: typeof DashboardPaymentsInboxImport
      parentRoute: typeof DashboardPaymentsRoute
    }
    '/_dashboard/bank-accounts/': {
      id: '/_dashboard/bank-accounts/'
      path: '/bank-accounts'
      fullPath: '/bank-accounts'
      preLoaderRoute: typeof DashboardBankAccountsIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/patients/': {
      id: '/_dashboard/patients/'
      path: '/'
      fullPath: '/patients/'
      preLoaderRoute: typeof DashboardPatientsIndexImport
      parentRoute: typeof DashboardPatientsImport
    }
    '/_dashboard/posted/': {
      id: '/_dashboard/posted/'
      path: '/posted'
      fullPath: '/posted'
      preLoaderRoute: typeof DashboardPostedIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/search/': {
      id: '/_dashboard/search/'
      path: '/search'
      fullPath: '/search'
      preLoaderRoute: typeof DashboardSearchIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/patients/_balances/balances': {
      id: '/_dashboard/patients/_balances/balances'
      path: '/balances'
      fullPath: '/patients/balances'
      preLoaderRoute: typeof DashboardPatientsBalancesBalancesImport
      parentRoute: typeof DashboardPatientsBalancesImport
    }
    '/_dashboard/patients/_balances/credits': {
      id: '/_dashboard/patients/_balances/credits'
      path: '/credits'
      fullPath: '/patients/credits'
      preLoaderRoute: typeof DashboardPatientsBalancesCreditsImport
      parentRoute: typeof DashboardPatientsBalancesImport
    }
    '/_dashboard/patients/balances/$familyId': {
      id: '/_dashboard/patients/balances/$familyId'
      path: '/balances/$familyId'
      fullPath: '/patients/balances/$familyId'
      preLoaderRoute: typeof DashboardPatientsBalancesFamilyIdImport
      parentRoute: typeof DashboardPatientsImport
    }
    '/_dashboard/patients/tasks/adjust': {
      id: '/_dashboard/patients/tasks/adjust'
      path: '/adjust'
      fullPath: '/patients/tasks/adjust'
      preLoaderRoute: typeof DashboardPatientsTasksAdjustImport
      parentRoute: typeof DashboardPatientsTasksImport
    }
    '/_dashboard/patients/tasks/done': {
      id: '/_dashboard/patients/tasks/done'
      path: '/done'
      fullPath: '/patients/tasks/done'
      preLoaderRoute: typeof DashboardPatientsTasksDoneImport
      parentRoute: typeof DashboardPatientsTasksImport
    }
    '/_dashboard/patients/tasks/file-secondary': {
      id: '/_dashboard/patients/tasks/file-secondary'
      path: '/file-secondary'
      fullPath: '/patients/tasks/file-secondary'
      preLoaderRoute: typeof DashboardPatientsTasksFileSecondaryImport
      parentRoute: typeof DashboardPatientsTasksImport
    }
    '/_dashboard/patients/tasks/refile': {
      id: '/_dashboard/patients/tasks/refile'
      path: '/refile'
      fullPath: '/patients/tasks/refile'
      preLoaderRoute: typeof DashboardPatientsTasksRefileImport
      parentRoute: typeof DashboardPatientsTasksImport
    }
    '/_dashboard/patients/tasks/review': {
      id: '/_dashboard/patients/tasks/review'
      path: '/review'
      fullPath: '/patients/tasks/review'
      preLoaderRoute: typeof DashboardPatientsTasksReviewImport
      parentRoute: typeof DashboardPatientsTasksImport
    }
    '/_dashboard/payments/_inbox/missing-transaction': {
      id: '/_dashboard/payments/_inbox/missing-transaction'
      path: '/missing-transaction'
      fullPath: '/payments/missing-transaction'
      preLoaderRoute: typeof DashboardPaymentsInboxMissingTransactionImport
      parentRoute: typeof DashboardPaymentsInboxImport
    }
    '/_dashboard/payments/_inbox/needs-review': {
      id: '/_dashboard/payments/_inbox/needs-review'
      path: '/needs-review'
      fullPath: '/payments/needs-review'
      preLoaderRoute: typeof DashboardPaymentsInboxNeedsReviewImport
      parentRoute: typeof DashboardPaymentsInboxImport
    }
    '/_dashboard/payments/_inbox/paper-checks': {
      id: '/_dashboard/payments/_inbox/paper-checks'
      path: '/paper-checks'
      fullPath: '/payments/paper-checks'
      preLoaderRoute: typeof DashboardPaymentsInboxPaperChecksImport
      parentRoute: typeof DashboardPaymentsInboxImport
    }
    '/_dashboard/patients/$patientId/': {
      id: '/_dashboard/patients/$patientId/'
      path: '/$patientId'
      fullPath: '/patients/$patientId'
      preLoaderRoute: typeof DashboardPatientsPatientIdIndexImport
      parentRoute: typeof DashboardPatientsImport
    }
    '/_dashboard/patients/tasks/': {
      id: '/_dashboard/patients/tasks/'
      path: '/'
      fullPath: '/patients/tasks/'
      preLoaderRoute: typeof DashboardPatientsTasksIndexImport
      parentRoute: typeof DashboardPatientsTasksImport
    }
    '/_dashboard/payments/$paymentId/': {
      id: '/_dashboard/payments/$paymentId/'
      path: '/$paymentId'
      fullPath: '/payments/$paymentId'
      preLoaderRoute: typeof DashboardPaymentsPaymentIdIndexImport
      parentRoute: typeof DashboardPaymentsImport
    }
    '/_dashboard/payments/_inbox/': {
      id: '/_dashboard/payments/_inbox/'
      path: '/'
      fullPath: '/payments/'
      preLoaderRoute: typeof DashboardPaymentsInboxIndexImport
      parentRoute: typeof DashboardPaymentsInboxImport
    }
    '/_dashboard/patients/$patientId/visits/$claimId': {
      id: '/_dashboard/patients/$patientId/visits/$claimId'
      path: '/$patientId/visits/$claimId'
      fullPath: '/patients/$patientId/visits/$claimId'
      preLoaderRoute: typeof DashboardPatientsPatientIdVisitsClaimIdImport
      parentRoute: typeof DashboardPatientsImport
    }
    '/_dashboard/payments/$paymentId/claims/$eobClaimId': {
      id: '/_dashboard/payments/$paymentId/claims/$eobClaimId'
      path: '/$paymentId/claims/$eobClaimId'
      fullPath: '/payments/$paymentId/claims/$eobClaimId'
      preLoaderRoute: typeof DashboardPaymentsPaymentIdClaimsEobClaimIdImport
      parentRoute: typeof DashboardPaymentsImport
    }
  }
}

// Create and export the route tree

interface DashboardPatientsBalancesRouteChildren {
  DashboardPatientsBalancesBalancesRoute: typeof DashboardPatientsBalancesBalancesRoute
  DashboardPatientsBalancesCreditsRoute: typeof DashboardPatientsBalancesCreditsRoute
}

const DashboardPatientsBalancesRouteChildren: DashboardPatientsBalancesRouteChildren =
  {
    DashboardPatientsBalancesBalancesRoute:
      DashboardPatientsBalancesBalancesRoute,
    DashboardPatientsBalancesCreditsRoute:
      DashboardPatientsBalancesCreditsRoute,
  }

const DashboardPatientsBalancesRouteWithChildren =
  DashboardPatientsBalancesRoute._addFileChildren(
    DashboardPatientsBalancesRouteChildren,
  )

interface DashboardPatientsTasksRouteChildren {
  DashboardPatientsTasksAdjustRoute: typeof DashboardPatientsTasksAdjustRoute
  DashboardPatientsTasksDoneRoute: typeof DashboardPatientsTasksDoneRoute
  DashboardPatientsTasksFileSecondaryRoute: typeof DashboardPatientsTasksFileSecondaryRoute
  DashboardPatientsTasksRefileRoute: typeof DashboardPatientsTasksRefileRoute
  DashboardPatientsTasksReviewRoute: typeof DashboardPatientsTasksReviewRoute
  DashboardPatientsTasksIndexRoute: typeof DashboardPatientsTasksIndexRoute
}

const DashboardPatientsTasksRouteChildren: DashboardPatientsTasksRouteChildren =
  {
    DashboardPatientsTasksAdjustRoute: DashboardPatientsTasksAdjustRoute,
    DashboardPatientsTasksDoneRoute: DashboardPatientsTasksDoneRoute,
    DashboardPatientsTasksFileSecondaryRoute:
      DashboardPatientsTasksFileSecondaryRoute,
    DashboardPatientsTasksRefileRoute: DashboardPatientsTasksRefileRoute,
    DashboardPatientsTasksReviewRoute: DashboardPatientsTasksReviewRoute,
    DashboardPatientsTasksIndexRoute: DashboardPatientsTasksIndexRoute,
  }

const DashboardPatientsTasksRouteWithChildren =
  DashboardPatientsTasksRoute._addFileChildren(
    DashboardPatientsTasksRouteChildren,
  )

interface DashboardPatientsRouteChildren {
  DashboardPatientsBalancesRoute: typeof DashboardPatientsBalancesRouteWithChildren
  DashboardPatientsInboxRoute: typeof DashboardPatientsInboxRoute
  DashboardPatientsTasksRoute: typeof DashboardPatientsTasksRouteWithChildren
  DashboardPatientsIndexRoute: typeof DashboardPatientsIndexRoute
  DashboardPatientsBalancesFamilyIdRoute: typeof DashboardPatientsBalancesFamilyIdRoute
  DashboardPatientsPatientIdIndexRoute: typeof DashboardPatientsPatientIdIndexRoute
  DashboardPatientsPatientIdVisitsClaimIdRoute: typeof DashboardPatientsPatientIdVisitsClaimIdRoute
}

const DashboardPatientsRouteChildren: DashboardPatientsRouteChildren = {
  DashboardPatientsBalancesRoute: DashboardPatientsBalancesRouteWithChildren,
  DashboardPatientsInboxRoute: DashboardPatientsInboxRoute,
  DashboardPatientsTasksRoute: DashboardPatientsTasksRouteWithChildren,
  DashboardPatientsIndexRoute: DashboardPatientsIndexRoute,
  DashboardPatientsBalancesFamilyIdRoute:
    DashboardPatientsBalancesFamilyIdRoute,
  DashboardPatientsPatientIdIndexRoute: DashboardPatientsPatientIdIndexRoute,
  DashboardPatientsPatientIdVisitsClaimIdRoute:
    DashboardPatientsPatientIdVisitsClaimIdRoute,
}

const DashboardPatientsRouteWithChildren =
  DashboardPatientsRoute._addFileChildren(DashboardPatientsRouteChildren)

interface DashboardPaymentsInboxRouteChildren {
  DashboardPaymentsInboxMissingTransactionRoute: typeof DashboardPaymentsInboxMissingTransactionRoute
  DashboardPaymentsInboxNeedsReviewRoute: typeof DashboardPaymentsInboxNeedsReviewRoute
  DashboardPaymentsInboxPaperChecksRoute: typeof DashboardPaymentsInboxPaperChecksRoute
  DashboardPaymentsInboxIndexRoute: typeof DashboardPaymentsInboxIndexRoute
}

const DashboardPaymentsInboxRouteChildren: DashboardPaymentsInboxRouteChildren =
  {
    DashboardPaymentsInboxMissingTransactionRoute:
      DashboardPaymentsInboxMissingTransactionRoute,
    DashboardPaymentsInboxNeedsReviewRoute:
      DashboardPaymentsInboxNeedsReviewRoute,
    DashboardPaymentsInboxPaperChecksRoute:
      DashboardPaymentsInboxPaperChecksRoute,
    DashboardPaymentsInboxIndexRoute: DashboardPaymentsInboxIndexRoute,
  }

const DashboardPaymentsInboxRouteWithChildren =
  DashboardPaymentsInboxRoute._addFileChildren(
    DashboardPaymentsInboxRouteChildren,
  )

interface DashboardPaymentsRouteChildren {
  DashboardPaymentsInboxRoute: typeof DashboardPaymentsInboxRouteWithChildren
  DashboardPaymentsPaymentIdIndexRoute: typeof DashboardPaymentsPaymentIdIndexRoute
  DashboardPaymentsPaymentIdClaimsEobClaimIdRoute: typeof DashboardPaymentsPaymentIdClaimsEobClaimIdRoute
}

const DashboardPaymentsRouteChildren: DashboardPaymentsRouteChildren = {
  DashboardPaymentsInboxRoute: DashboardPaymentsInboxRouteWithChildren,
  DashboardPaymentsPaymentIdIndexRoute: DashboardPaymentsPaymentIdIndexRoute,
  DashboardPaymentsPaymentIdClaimsEobClaimIdRoute:
    DashboardPaymentsPaymentIdClaimsEobClaimIdRoute,
}

const DashboardPaymentsRouteWithChildren =
  DashboardPaymentsRoute._addFileChildren(DashboardPaymentsRouteChildren)

interface DashboardRouteChildren {
  DashboardInsuranceLoginsRoute: typeof DashboardInsuranceLoginsRoute
  DashboardIndexRoute: typeof DashboardIndexRoute
  DashboardPatientsRoute: typeof DashboardPatientsRouteWithChildren
  DashboardPaymentsRoute: typeof DashboardPaymentsRouteWithChildren
  DashboardBankAccountsIndexRoute: typeof DashboardBankAccountsIndexRoute
  DashboardPostedIndexRoute: typeof DashboardPostedIndexRoute
  DashboardSearchIndexRoute: typeof DashboardSearchIndexRoute
}

const DashboardRouteChildren: DashboardRouteChildren = {
  DashboardInsuranceLoginsRoute: DashboardInsuranceLoginsRoute,
  DashboardIndexRoute: DashboardIndexRoute,
  DashboardPatientsRoute: DashboardPatientsRouteWithChildren,
  DashboardPaymentsRoute: DashboardPaymentsRouteWithChildren,
  DashboardBankAccountsIndexRoute: DashboardBankAccountsIndexRoute,
  DashboardPostedIndexRoute: DashboardPostedIndexRoute,
  DashboardSearchIndexRoute: DashboardSearchIndexRoute,
}

const DashboardRouteWithChildren = DashboardRoute._addFileChildren(
  DashboardRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof DashboardRouteWithChildren
  '/authorize': typeof AuthorizeRoute
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/reset': typeof ResetRoute
  '/insurance-logins': typeof DashboardInsuranceLoginsRoute
  '/': typeof DashboardIndexRoute
  '/patients': typeof DashboardPatientsBalancesRouteWithChildren
  '/patients/inbox': typeof DashboardPatientsInboxRoute
  '/patients/tasks': typeof DashboardPatientsTasksRouteWithChildren
  '/payments': typeof DashboardPaymentsInboxRouteWithChildren
  '/bank-accounts': typeof DashboardBankAccountsIndexRoute
  '/patients/': typeof DashboardPatientsIndexRoute
  '/posted': typeof DashboardPostedIndexRoute
  '/search': typeof DashboardSearchIndexRoute
  '/patients/balances': typeof DashboardPatientsBalancesBalancesRoute
  '/patients/credits': typeof DashboardPatientsBalancesCreditsRoute
  '/patients/balances/$familyId': typeof DashboardPatientsBalancesFamilyIdRoute
  '/patients/tasks/adjust': typeof DashboardPatientsTasksAdjustRoute
  '/patients/tasks/done': typeof DashboardPatientsTasksDoneRoute
  '/patients/tasks/file-secondary': typeof DashboardPatientsTasksFileSecondaryRoute
  '/patients/tasks/refile': typeof DashboardPatientsTasksRefileRoute
  '/patients/tasks/review': typeof DashboardPatientsTasksReviewRoute
  '/payments/missing-transaction': typeof DashboardPaymentsInboxMissingTransactionRoute
  '/payments/needs-review': typeof DashboardPaymentsInboxNeedsReviewRoute
  '/payments/paper-checks': typeof DashboardPaymentsInboxPaperChecksRoute
  '/patients/$patientId': typeof DashboardPatientsPatientIdIndexRoute
  '/patients/tasks/': typeof DashboardPatientsTasksIndexRoute
  '/payments/$paymentId': typeof DashboardPaymentsPaymentIdIndexRoute
  '/payments/': typeof DashboardPaymentsInboxIndexRoute
  '/patients/$patientId/visits/$claimId': typeof DashboardPatientsPatientIdVisitsClaimIdRoute
  '/payments/$paymentId/claims/$eobClaimId': typeof DashboardPaymentsPaymentIdClaimsEobClaimIdRoute
}

export interface FileRoutesByTo {
  '/authorize': typeof AuthorizeRoute
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/reset': typeof ResetRoute
  '/insurance-logins': typeof DashboardInsuranceLoginsRoute
  '/': typeof DashboardIndexRoute
  '/patients': typeof DashboardPatientsIndexRoute
  '/patients/inbox': typeof DashboardPatientsInboxRoute
  '/payments': typeof DashboardPaymentsInboxIndexRoute
  '/bank-accounts': typeof DashboardBankAccountsIndexRoute
  '/posted': typeof DashboardPostedIndexRoute
  '/search': typeof DashboardSearchIndexRoute
  '/patients/balances': typeof DashboardPatientsBalancesBalancesRoute
  '/patients/credits': typeof DashboardPatientsBalancesCreditsRoute
  '/patients/balances/$familyId': typeof DashboardPatientsBalancesFamilyIdRoute
  '/patients/tasks/adjust': typeof DashboardPatientsTasksAdjustRoute
  '/patients/tasks/done': typeof DashboardPatientsTasksDoneRoute
  '/patients/tasks/file-secondary': typeof DashboardPatientsTasksFileSecondaryRoute
  '/patients/tasks/refile': typeof DashboardPatientsTasksRefileRoute
  '/patients/tasks/review': typeof DashboardPatientsTasksReviewRoute
  '/payments/missing-transaction': typeof DashboardPaymentsInboxMissingTransactionRoute
  '/payments/needs-review': typeof DashboardPaymentsInboxNeedsReviewRoute
  '/payments/paper-checks': typeof DashboardPaymentsInboxPaperChecksRoute
  '/patients/$patientId': typeof DashboardPatientsPatientIdIndexRoute
  '/patients/tasks': typeof DashboardPatientsTasksIndexRoute
  '/payments/$paymentId': typeof DashboardPaymentsPaymentIdIndexRoute
  '/patients/$patientId/visits/$claimId': typeof DashboardPatientsPatientIdVisitsClaimIdRoute
  '/payments/$paymentId/claims/$eobClaimId': typeof DashboardPaymentsPaymentIdClaimsEobClaimIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_dashboard': typeof DashboardRouteWithChildren
  '/authorize': typeof AuthorizeRoute
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/reset': typeof ResetRoute
  '/_dashboard/insurance-logins': typeof DashboardInsuranceLoginsRoute
  '/_dashboard/': typeof DashboardIndexRoute
  '/_dashboard/patients': typeof DashboardPatientsRouteWithChildren
  '/_dashboard/patients/_balances': typeof DashboardPatientsBalancesRouteWithChildren
  '/_dashboard/patients/inbox': typeof DashboardPatientsInboxRoute
  '/_dashboard/patients/tasks': typeof DashboardPatientsTasksRouteWithChildren
  '/_dashboard/payments': typeof DashboardPaymentsRouteWithChildren
  '/_dashboard/payments/_inbox': typeof DashboardPaymentsInboxRouteWithChildren
  '/_dashboard/bank-accounts/': typeof DashboardBankAccountsIndexRoute
  '/_dashboard/patients/': typeof DashboardPatientsIndexRoute
  '/_dashboard/posted/': typeof DashboardPostedIndexRoute
  '/_dashboard/search/': typeof DashboardSearchIndexRoute
  '/_dashboard/patients/_balances/balances': typeof DashboardPatientsBalancesBalancesRoute
  '/_dashboard/patients/_balances/credits': typeof DashboardPatientsBalancesCreditsRoute
  '/_dashboard/patients/balances/$familyId': typeof DashboardPatientsBalancesFamilyIdRoute
  '/_dashboard/patients/tasks/adjust': typeof DashboardPatientsTasksAdjustRoute
  '/_dashboard/patients/tasks/done': typeof DashboardPatientsTasksDoneRoute
  '/_dashboard/patients/tasks/file-secondary': typeof DashboardPatientsTasksFileSecondaryRoute
  '/_dashboard/patients/tasks/refile': typeof DashboardPatientsTasksRefileRoute
  '/_dashboard/patients/tasks/review': typeof DashboardPatientsTasksReviewRoute
  '/_dashboard/payments/_inbox/missing-transaction': typeof DashboardPaymentsInboxMissingTransactionRoute
  '/_dashboard/payments/_inbox/needs-review': typeof DashboardPaymentsInboxNeedsReviewRoute
  '/_dashboard/payments/_inbox/paper-checks': typeof DashboardPaymentsInboxPaperChecksRoute
  '/_dashboard/patients/$patientId/': typeof DashboardPatientsPatientIdIndexRoute
  '/_dashboard/patients/tasks/': typeof DashboardPatientsTasksIndexRoute
  '/_dashboard/payments/$paymentId/': typeof DashboardPaymentsPaymentIdIndexRoute
  '/_dashboard/payments/_inbox/': typeof DashboardPaymentsInboxIndexRoute
  '/_dashboard/patients/$patientId/visits/$claimId': typeof DashboardPatientsPatientIdVisitsClaimIdRoute
  '/_dashboard/payments/$paymentId/claims/$eobClaimId': typeof DashboardPaymentsPaymentIdClaimsEobClaimIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/authorize'
    | '/login'
    | '/logout'
    | '/reset'
    | '/insurance-logins'
    | '/'
    | '/patients'
    | '/patients/inbox'
    | '/patients/tasks'
    | '/payments'
    | '/bank-accounts'
    | '/patients/'
    | '/posted'
    | '/search'
    | '/patients/balances'
    | '/patients/credits'
    | '/patients/balances/$familyId'
    | '/patients/tasks/adjust'
    | '/patients/tasks/done'
    | '/patients/tasks/file-secondary'
    | '/patients/tasks/refile'
    | '/patients/tasks/review'
    | '/payments/missing-transaction'
    | '/payments/needs-review'
    | '/payments/paper-checks'
    | '/patients/$patientId'
    | '/patients/tasks/'
    | '/payments/$paymentId'
    | '/payments/'
    | '/patients/$patientId/visits/$claimId'
    | '/payments/$paymentId/claims/$eobClaimId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/authorize'
    | '/login'
    | '/logout'
    | '/reset'
    | '/insurance-logins'
    | '/'
    | '/patients'
    | '/patients/inbox'
    | '/payments'
    | '/bank-accounts'
    | '/posted'
    | '/search'
    | '/patients/balances'
    | '/patients/credits'
    | '/patients/balances/$familyId'
    | '/patients/tasks/adjust'
    | '/patients/tasks/done'
    | '/patients/tasks/file-secondary'
    | '/patients/tasks/refile'
    | '/patients/tasks/review'
    | '/payments/missing-transaction'
    | '/payments/needs-review'
    | '/payments/paper-checks'
    | '/patients/$patientId'
    | '/patients/tasks'
    | '/payments/$paymentId'
    | '/patients/$patientId/visits/$claimId'
    | '/payments/$paymentId/claims/$eobClaimId'
  id:
    | '__root__'
    | '/_dashboard'
    | '/authorize'
    | '/login'
    | '/logout'
    | '/reset'
    | '/_dashboard/insurance-logins'
    | '/_dashboard/'
    | '/_dashboard/patients'
    | '/_dashboard/patients/_balances'
    | '/_dashboard/patients/inbox'
    | '/_dashboard/patients/tasks'
    | '/_dashboard/payments'
    | '/_dashboard/payments/_inbox'
    | '/_dashboard/bank-accounts/'
    | '/_dashboard/patients/'
    | '/_dashboard/posted/'
    | '/_dashboard/search/'
    | '/_dashboard/patients/_balances/balances'
    | '/_dashboard/patients/_balances/credits'
    | '/_dashboard/patients/balances/$familyId'
    | '/_dashboard/patients/tasks/adjust'
    | '/_dashboard/patients/tasks/done'
    | '/_dashboard/patients/tasks/file-secondary'
    | '/_dashboard/patients/tasks/refile'
    | '/_dashboard/patients/tasks/review'
    | '/_dashboard/payments/_inbox/missing-transaction'
    | '/_dashboard/payments/_inbox/needs-review'
    | '/_dashboard/payments/_inbox/paper-checks'
    | '/_dashboard/patients/$patientId/'
    | '/_dashboard/patients/tasks/'
    | '/_dashboard/payments/$paymentId/'
    | '/_dashboard/payments/_inbox/'
    | '/_dashboard/patients/$patientId/visits/$claimId'
    | '/_dashboard/payments/$paymentId/claims/$eobClaimId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  DashboardRoute: typeof DashboardRouteWithChildren
  AuthorizeRoute: typeof AuthorizeRoute
  LoginRoute: typeof LoginRoute
  LogoutRoute: typeof LogoutRoute
  ResetRoute: typeof ResetRoute
}

const rootRouteChildren: RootRouteChildren = {
  DashboardRoute: DashboardRouteWithChildren,
  AuthorizeRoute: AuthorizeRoute,
  LoginRoute: LoginRoute,
  LogoutRoute: LogoutRoute,
  ResetRoute: ResetRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_dashboard",
        "/authorize",
        "/login",
        "/logout",
        "/reset"
      ]
    },
    "/_dashboard": {
      "filePath": "_dashboard.tsx",
      "children": [
        "/_dashboard/insurance-logins",
        "/_dashboard/",
        "/_dashboard/patients",
        "/_dashboard/payments",
        "/_dashboard/bank-accounts/",
        "/_dashboard/posted/",
        "/_dashboard/search/"
      ]
    },
    "/authorize": {
      "filePath": "authorize.tsx"
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/logout": {
      "filePath": "logout.tsx"
    },
    "/reset": {
      "filePath": "reset.tsx"
    },
    "/_dashboard/insurance-logins": {
      "filePath": "_dashboard/insurance-logins.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/": {
      "filePath": "_dashboard/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/patients": {
      "filePath": "_dashboard/patients",
      "parent": "/_dashboard",
      "children": [
        "/_dashboard/patients/_balances",
        "/_dashboard/patients/inbox",
        "/_dashboard/patients/tasks",
        "/_dashboard/patients/",
        "/_dashboard/patients/balances/$familyId",
        "/_dashboard/patients/$patientId/",
        "/_dashboard/patients/$patientId/visits/$claimId"
      ]
    },
    "/_dashboard/patients/_balances": {
      "filePath": "_dashboard/patients/_balances.tsx",
      "parent": "/_dashboard/patients",
      "children": [
        "/_dashboard/patients/_balances/balances",
        "/_dashboard/patients/_balances/credits"
      ]
    },
    "/_dashboard/patients/inbox": {
      "filePath": "_dashboard.patients.inbox.tsx",
      "parent": "/_dashboard/patients"
    },
    "/_dashboard/patients/tasks": {
      "filePath": "_dashboard/patients/tasks.tsx",
      "parent": "/_dashboard/patients",
      "children": [
        "/_dashboard/patients/tasks/adjust",
        "/_dashboard/patients/tasks/done",
        "/_dashboard/patients/tasks/file-secondary",
        "/_dashboard/patients/tasks/refile",
        "/_dashboard/patients/tasks/review",
        "/_dashboard/patients/tasks/"
      ]
    },
    "/_dashboard/payments": {
      "filePath": "_dashboard/payments",
      "parent": "/_dashboard",
      "children": [
        "/_dashboard/payments/_inbox",
        "/_dashboard/payments/$paymentId/",
        "/_dashboard/payments/$paymentId/claims/$eobClaimId"
      ]
    },
    "/_dashboard/payments/_inbox": {
      "filePath": "_dashboard/payments/_inbox.tsx",
      "parent": "/_dashboard/payments",
      "children": [
        "/_dashboard/payments/_inbox/missing-transaction",
        "/_dashboard/payments/_inbox/needs-review",
        "/_dashboard/payments/_inbox/paper-checks",
        "/_dashboard/payments/_inbox/"
      ]
    },
    "/_dashboard/bank-accounts/": {
      "filePath": "_dashboard/bank-accounts/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/patients/": {
      "filePath": "_dashboard/patients/index.tsx",
      "parent": "/_dashboard/patients"
    },
    "/_dashboard/posted/": {
      "filePath": "_dashboard/posted/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/search/": {
      "filePath": "_dashboard/search/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/patients/_balances/balances": {
      "filePath": "_dashboard/patients/_balances/balances.tsx",
      "parent": "/_dashboard/patients/_balances"
    },
    "/_dashboard/patients/_balances/credits": {
      "filePath": "_dashboard/patients/_balances/credits.tsx",
      "parent": "/_dashboard/patients/_balances"
    },
    "/_dashboard/patients/balances/$familyId": {
      "filePath": "_dashboard/patients/balances/$familyId.tsx",
      "parent": "/_dashboard/patients"
    },
    "/_dashboard/patients/tasks/adjust": {
      "filePath": "_dashboard/patients/tasks/adjust.tsx",
      "parent": "/_dashboard/patients/tasks"
    },
    "/_dashboard/patients/tasks/done": {
      "filePath": "_dashboard/patients/tasks/done.tsx",
      "parent": "/_dashboard/patients/tasks"
    },
    "/_dashboard/patients/tasks/file-secondary": {
      "filePath": "_dashboard/patients/tasks/file-secondary.tsx",
      "parent": "/_dashboard/patients/tasks"
    },
    "/_dashboard/patients/tasks/refile": {
      "filePath": "_dashboard/patients/tasks/refile.tsx",
      "parent": "/_dashboard/patients/tasks"
    },
    "/_dashboard/patients/tasks/review": {
      "filePath": "_dashboard/patients/tasks/review.tsx",
      "parent": "/_dashboard/patients/tasks"
    },
    "/_dashboard/payments/_inbox/missing-transaction": {
      "filePath": "_dashboard/payments/_inbox/missing-transaction.tsx",
      "parent": "/_dashboard/payments/_inbox"
    },
    "/_dashboard/payments/_inbox/needs-review": {
      "filePath": "_dashboard/payments/_inbox/needs-review.tsx",
      "parent": "/_dashboard/payments/_inbox"
    },
    "/_dashboard/payments/_inbox/paper-checks": {
      "filePath": "_dashboard/payments/_inbox/paper-checks.tsx",
      "parent": "/_dashboard/payments/_inbox"
    },
    "/_dashboard/patients/$patientId/": {
      "filePath": "_dashboard/patients/$patientId/index.tsx",
      "parent": "/_dashboard/patients"
    },
    "/_dashboard/patients/tasks/": {
      "filePath": "_dashboard/patients/tasks/index.tsx",
      "parent": "/_dashboard/patients/tasks"
    },
    "/_dashboard/payments/$paymentId/": {
      "filePath": "_dashboard/payments/$paymentId/index.tsx",
      "parent": "/_dashboard/payments"
    },
    "/_dashboard/payments/_inbox/": {
      "filePath": "_dashboard/payments/_inbox/index.tsx",
      "parent": "/_dashboard/payments/_inbox"
    },
    "/_dashboard/patients/$patientId/visits/$claimId": {
      "filePath": "_dashboard/patients/$patientId/visits.$claimId.tsx",
      "parent": "/_dashboard/patients"
    },
    "/_dashboard/payments/$paymentId/claims/$eobClaimId": {
      "filePath": "_dashboard/payments/$paymentId/claims.$eobClaimId.tsx",
      "parent": "/_dashboard/payments"
    }
  }
}
ROUTE_MANIFEST_END */
