import type { Socket } from "@lassie/types";
import * as Sentry from "@sentry/react";
import { BootstrapStatus, LoadingVariant } from "../components/splash";
import { __dangerous_truncate_db } from "../workers/sync";
import { fingerprint } from "./fingerprint";
import {
  DbInitStatus,
  InitializerError,
  type InitializerErrorBlob,
} from "./initializer-types";
import { LocalStorage } from "./local-storage";
import type { SharedSocketClient } from "./socket-proxy";
import { CLIENT_VERSION, VersionComparison, compareVersions } from "./version";
import { type SyncWorker, localSyncWorker, syncWorker } from "./workers";

export type RecoveredResult = Parameters<Socket.ServerEvents["recovered"]>[0];

// TODO: move to app logger with topics
// TODO: add sentry breadcrumb in prod
const initializerLogger = {
  log: (...args: any[]) => {
    logger.info("[initializer]", ...args);
  },
  warn: (...args: any[]) => {
    logger.warn("[initializer]", ...args);
  },
  error: (...args: any[]) => {
    logger.error("[initializer]", ...args);
  },
};

export type BootstrapResult = Exclude<
  Awaited<ReturnType<SyncWorker["bootstrap"]>>,
  null
>;

export type InitializerOptions = {
  accessToken: string;
  socket: SharedSocketClient;
  onSyncFinished: (result: BootstrapResult) => void;
  onStatusChange: (status: BootstrapStatus) => void;
  onModeChange: (mode: LoadingVariant) => void;
  onError: (error: InitializerErrorBlob) => void;
  onRecover: (data: RecoveredResult) => void;
};

export class Initializer {
  private clientId = LocalStorage.get("clientId");
  private version = LocalStorage.get("version");
  private lastSyncedAt = LocalStorage.get("lastSyncedAt");
  private selectedPractice = LocalStorage.get("selectedPractice");
  private socket: SharedSocketClient;
  private accessToken: string;

  private globalWorker: typeof syncWorker;
  private localWorker: typeof localSyncWorker;

  #mode: LoadingVariant = LoadingVariant.RECOVERY;
  #status: BootstrapStatus = BootstrapStatus.LOADING;

  get status() {
    return this.#status;
  }

  set status(status: BootstrapStatus) {
    this.#status = status;
    this.onStatusChange(status);
  }

  get mode() {
    return this.#mode;
  }

  set mode(mode: LoadingVariant) {
    this.#mode = mode;
    this.onModeChange(mode);
  }

  // listeners
  private onSyncFinished: (result: BootstrapResult) => void;
  private onStatusChange: (status: BootstrapStatus) => void;
  private onModeChange: (mode: LoadingVariant) => void;
  private onRecover: (data: RecoveredResult) => void;
  private _onError: (error: InitializerErrorBlob) => void;

  constructor(options: InitializerOptions) {
    this.accessToken = options.accessToken;
    this.socket = options.socket;
    this.socket.setAccessToken(this.accessToken);
    this.onSyncFinished = options.onSyncFinished;
    this.onStatusChange = options.onStatusChange;
    this.onModeChange = options.onModeChange;
    this.onRecover = options.onRecover;
    this._onError = options.onError;
    this.globalWorker = syncWorker;
    this.localWorker = localSyncWorker;
  }

  onError(error: InitializerErrorBlob) {
    this.status = BootstrapStatus.ERROR;
    Sentry.captureMessage(JSON.stringify(error), "error");
    this._onError(error);
  }

  getMode(): LoadingVariant {
    if (
      !this.version ||
      !this.selectedPractice ||
      !this.lastSyncedAt?.[this.selectedPractice] ||
      !this.clientId
    ) {
      return LoadingVariant.BOOTSTRAP;
    }

    if (
      compareVersions(this.version, CLIENT_VERSION) ===
      VersionComparison.NEEDS_FULL_UPGRADE
    ) {
      return LoadingVariant.UPGRADE;
    }

    return LoadingVariant.RECOVERY;
  }

  async recover() {
    try {
      if (IS_DEMO) {
        const DEMO_CONFIG_URL = IS_DEVELOPMENT
          ? "/demo-config.json"
          : "https://assets.golassie.com/demo/config.json";

        // fetch json config from server
        const response = await fetch(DEMO_CONFIG_URL);
        const demoJson = await response.json();

        if (!demoJson.practices) {
          initializerLogger.error("No practices found in demo config");
          throw new Error("No practices found in demo config");
        }

        this.onRecover({
          key: "demo-key",
          recovered: true,
          clientId: "demo-client-id",
          user_data: {
            email: "demo@golassie.com",
            uuid: "demo-uuid",
            type: "EXTERNAL",
          },
          intercom_user: {
            email: "demo@golassie.com",
            user_hash: "demo-user-hash",
          },
          practices: demoJson.practices,
        });

        LocalStorage.set("clientId", "demo-client-id");

        return {
          key: "demo-key",
          recovered: true,
        };
      }

      const recoverPromise = new Promise<RecoveredResult>((resolve, reject) => {
        const timeout = window.setTimeout(() => {
          console.error("RECOVERY TIMED OUT");
          reject(new Error("Recovery timed out"));
        }, 10000);

        this.socket.on("recovered", (response: RecoveredResult) => {
          initializerLogger.log("RECOVERED", response);
          window.clearTimeout(timeout);
          resolve(response);
        });

        this.socket.emit("recover", {
          clientId: this.clientId,
          deviceProfile: fingerprint(),
        });
      });

      const response = await recoverPromise;

      const { key, clientId, practices, recovered } = response;
      LocalStorage.set("clientId", clientId);

      if (practices) {
        this.onRecover(response);
        await this.globalWorker.setUser(response.user_data);
        await this.localWorker.setUser(response.user_data);
      }

      return { key, recovered };
    } catch (error) {
      this.onError({
        type: InitializerError.RECOVERY_FAILED,
        message: error instanceof Error ? error.message : "Unknown error",
      });

      return { key: null, recovered: false };
    }
  }

  updatePracticeData(bootstrapResult: BootstrapResult) {
    const { selectedPractice, practices, error } = bootstrapResult;

    if (error) {
      // report error and abort
      this.onError(error);
      return;
    }

    // update selected practice
    LocalStorage.set("selectedPractice", selectedPractice);

    const selectedPracticeMetadata = practices.find(
      (p) => p.uuid === selectedPractice,
    );

    if (selectedPracticeMetadata) {
      LocalStorage.set("selectedPracticeMetadata", selectedPracticeMetadata);
    }
  }

  async sync() {
    const lastSyncedAtRecords = LocalStorage.get("lastSyncedAt");
    const lastSyncedAt = this.selectedPractice
      ? (lastSyncedAtRecords?.[this.selectedPractice] ?? null)
      : null;

    // TODO: remove log
    initializerLogger.log("CALLING BOOTSTRAP FOR SYNC");
    const bootstrapResult = await this.globalWorker.bootstrap({
      selectedPractice: this.selectedPractice,
      lastSyncedAt,
    });

    if (!bootstrapResult) {
      initializerLogger.warn("bootstrap failed");
      return null;
    }

    if (bootstrapResult.error) {
      if (bootstrapResult.error.type === InitializerError.DATABASE_CORRUPTED) {
        this.onError(bootstrapResult.error);
        await this.__reset();
        return null;
      }

      this.onError(bootstrapResult.error);
      return null;
    }

    // update selected practice and metadata
    this.updatePracticeData(bootstrapResult);

    // notify listeners
    this.onSyncFinished(bootstrapResult);

    // mark as bootstrapped to unblock worker
    this.globalWorker.markBootstrapFinished();

    return bootstrapResult;
  }

  async initialize() {
    this.mode = this.getMode();

    const hasInitializedSQLite = await this.globalWorker.hasInitializedSQLite();

    if (hasInitializedSQLite) {
      initializerLogger.log("Host DB is ready before initialization!");
      this.status = BootstrapStatus.SYNCING;
    }

    if (
      // we should clean the db if we are upgrading
      // since the schema may have changed
      this.mode === LoadingVariant.UPGRADE
    ) {
      await this.upgrade();
    }

    initializerLogger.log("INITIALIZING");
    // STEP 0 - setup access token for worker and socket
    await this.globalWorker.setAccessToken(this.accessToken);
    await this.localWorker.setAccessToken(this.accessToken);

    let key: string | null = null;
    let recovered = false;
    let initStatus = DbInitStatus.NONE;
    let attempts = 0;

    const MAX_ATTEMPTS = 3;

    // STEP 1 - initialize the database
    while (initStatus !== DbInitStatus.INITIALIZED && attempts < MAX_ATTEMPTS) {
      if (attempts > 0) {
        initializerLogger.log("KEY INVALID, ATTEMPTING RESET");
        // requires an app reset, revert status to loading
        this.status = BootstrapStatus.LOADING;
        this.mode = LoadingVariant.BOOTSTRAP;
        initializerLogger.log("INVALIDATING CLIENT ID");
        // invalidate client id to force a recovery
        this.clientId = null;
        // reset lastSyncedAt
        LocalStorage.set("lastSyncedAt", {});
      }

      // recover client key from server if possible
      initializerLogger.log(
        `RECOVERING ATTEMPT ${attempts + 1} OF ${MAX_ATTEMPTS}`,
      );
      const recoveredResponse = await this.recover();

      if ((recoveredResponse as any).connected === false) {
        // if we failed to connect, we should return
        // and let the user try again
        return;
      }

      key = recoveredResponse.key;
      recovered = recoveredResponse.recovered;

      if (key === null) {
        // if we don't have a key, we can't do anything, but
        // tell the user to try logging in again.
        const errorType =
          attempts > 0
            ? InitializerError.RECOVERY_FAILED
            : InitializerError.RECOVERY_TIMED_OUT;

        this.onError({
          type: errorType,
          message: "Could not acquire client key. Abandoning session.",
        });
        return;
      }

      initializerLogger.log("CALLING WORKER INIT");

      /** we should reset if this is not the first attempt or if we failed to recover */
      const shouldReset = attempts > 0 || recovered === false;

      // start debug mode if the user has enabled it
      if (LocalStorage.get("__lassie_debug") === true) {
        try {
          this.globalWorker.startDebug();
          localSyncWorker.startDebug();
        } catch (error) {
          initializerLogger.error("FAILED TO START DEBUG MODE", error);
        }
      }

      if (shouldReset) {
        // on reset we need to clear the last synced at
        LocalStorage.set("lastSyncedAt", {});
      }

      initStatus = await this.globalWorker.init({ key, reset: shouldReset });

      ++attempts;
    }

    if (initStatus === DbInitStatus.INVALID_KEY) {
      this.onError({
        type: InitializerError.RECOVERY_FAILED,
        message: "Invalid database key. Please try logging in again.",
      });
      return;
    }

    initializerLogger.log(`Initialized in ${attempts} attempts`);

    const hasSyncedPractice =
      this.selectedPractice &&
      LocalStorage.get("lastSyncedAt")?.[this.selectedPractice];

    if (recovered && hasSyncedPractice) {
      this.status = BootstrapStatus.SYNCING;
    } else {
      // STEP 2.2B - otherwise we need to bootstrap
      this.status = BootstrapStatus.LOADING;
    }

    // STEP 3 - sync data with server
    const bootstrapResult = await this.sync();
    const selectedPractice = bootstrapResult?.selectedPractice;

    // STEP 4 - mark bootstrap as finished
    window.setTimeout(() => {
      this.status = BootstrapStatus.SUCCESS;

      // update last synced at
      if (selectedPractice) {
        const TWO_MINUTES = 2 * 60 * 1000;

        LocalStorage.updateLastSyncedAt(
          selectedPractice,
          // add a buffer to the last synced at time to account for init time
          new Date().valueOf() - TWO_MINUTES,
        );
      }

      LocalStorage.set("version", CLIENT_VERSION);
    }, 0);
  }

  async upgrade() {
    // save the practice and metadata
    const temp_selectedPractice = LocalStorage.get("selectedPractice");
    const temp_selectedPracticeMetadata = LocalStorage.get(
      "selectedPracticeMetadata",
    );
    const temp_version = LocalStorage.get("version");

    initializerLogger.log("UPGRADING, CLEARING DB");
    await this.globalWorker.__dangerous_truncate_db();
    LocalStorage.set("lastSyncedAt", {});

    // clear out local storage since the version might expect
    // certain values to be present
    localStorage.clear();

    // re-instate the practice metadata and version if they existed
    temp_selectedPractice &&
      LocalStorage.set("selectedPractice", temp_selectedPractice);
    temp_selectedPracticeMetadata &&
      LocalStorage.set(
        "selectedPracticeMetadata",
        temp_selectedPracticeMetadata,
      );
    temp_version && LocalStorage.set("version", temp_version);

    // clear client id since we want a new DB key
    this.clientId = null;
  }

  async __reset() {
    await this.globalWorker.__dangerous_truncate_db();
    LocalStorage.set("lastSyncedAt", {});

    window.location.reload();
  }
}
