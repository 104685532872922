import type {
  BankSource,
  EobPayment,
  InsuranceLogin,
  RecentSearch,
  SearchResultItem,
} from "@lassie/types";
import { sql } from "drizzle-orm";
import { db } from "../../../lib/drizzle/db";
import { Tables } from "../../../lib/drizzle/schema";
import { LocalStorage } from "../../../lib/local-storage";
import { query } from "../../../workers/sync";
import type { DiskAction } from "../types";

export async function handleInsuranceLogins(
  _payload: DiskAction.InsuranceLogins["options"],
): Promise<{ insuranceLogins: InsuranceLogin[] }> {
  return {
    insuranceLogins: [
      {
        id: "insurance-login-1",
        source: "DeltaDental.com",
        sourceName: "DELTA_DENTAL_INS",
        username: "dentist@example.com",
        password: "superSecurePassword1",
        error: null,
      },
      {
        id: "insurance-login-2",
        sourceName: "DENTAL_XCHANGE",
        source: "DentalXChange.com",
        username: "dentist@example.com",
        password: "superSecurePassword2",
        error: null,
      },
      {
        id: "insurance-login-3",
        sourceName: "AETNA_PORTAL",
        source: "AetnaDental.com",
        username: "dentist@example.com",
        password: "superSecurePassword3",
        error: null,
      },
      {
        id: "insurance-login-4",
        sourceName: "UHC_EPAYMENTS",
        source: "UHC ePayment Center",
        username: "dentist@example.com",
        password: "superSecurePassword4",
        error: null,
      },
    ],
  };
}

export async function handleBankAccounts(
  _payload: DiskAction.BankAccounts["options"],
): Promise<DiskAction.BankAccounts["response"]> {
  return {
    bankSources: [
      {
        id: 1,
        logo: null,
        createdAt: "2024-01-01",
        institutionName: "Bank of America",
        status: "ACTIVE",
        vendor: "PLAID",
        accounts: [
          {
            name: "Bank of America Checking",
            type: "CHECKING",
            mask: "1234",
            ignoredAt: null,
          },
        ],
      },
    ],
    pendingBankSources: [],
  };
}

export async function handleCreateBankLink(
  payload: DiskAction.CreateBankLink["options"],
): Promise<DiskAction.CreateBankLink["response"]> {
  return { link_token: "test", expiration: "test", request_id: "test" };
}

export async function handleCompleteBankLink(
  payload: DiskAction.CompleteBankLink["options"],
): Promise<DiskAction.CompleteBankLink["response"]> {
  return null;
}

export async function handleRemoveBankLink(
  payload: DiskAction.RemoveBankLink["options"],
): Promise<DiskAction.RemoveBankLink["response"]> {
  return null;
}

// Demo search implementation

const RECENT_SEARCHES_KEY = "com.golassie.demo.recentSearches";

// track recent searches in sessionStorage (to avoid Redux immutability)
sessionStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify([]));

export async function handleSearch(
  payload: DiskAction.Search["options"],
): Promise<{ results: SearchResultItem[]; query: string }> {
  if (!payload.query) {
    throw new Error("Query is required");
  }

  const results = await runSearch(payload.query, payload.model);

  const recentSearches = JSON.parse(
    sessionStorage.getItem(RECENT_SEARCHES_KEY) || "[]",
  );

  const dedupedRecentSearches = recentSearches.filter(
    (search: RecentSearch) => search.query !== payload.query,
  );

  dedupedRecentSearches.push({
    id: Math.floor(Math.random() * 9999999999),
    query: payload.query,
    updatedAt: new Date().toISOString(),
  });

  sessionStorage.setItem(
    RECENT_SEARCHES_KEY,
    JSON.stringify(dedupedRecentSearches),
  );

  return { results, query: payload.query };
}

export async function handleClearRecentSearch(
  payload: DiskAction.ClearRecentSearch["options"],
): Promise<DiskAction.ClearRecentSearch["response"]> {
  sessionStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify([]));
  return { success: true };
}

export async function handleRecentSearches(): Promise<
  DiskAction.RecentSearches["response"]
> {
  return JSON.parse(sessionStorage.getItem(RECENT_SEARCHES_KEY) || "[]");
}

async function runSearch(
  query: string,
  model: DiskAction.Search["options"]["model"],
): Promise<SearchResultItem[]> {
  const taggedTokens = tokenizedQuery(query);

  const skipPayments = model !== "all" && model !== "payment";
  const skipClaims = model !== "all" && model !== "claim";
  const skipPatients = model !== "all" && model !== "patient";

  const hasProcedures = taggedTokens.some((token) => token.tag === "PROCEDURE");
  const hasNumbers = taggedTokens.some((token) => token.tag === "NUMBER");
  const hasMoney = taggedTokens.some((token) => token.tag === "MONEY");
  const hasArbitraryText = taggedTokens.some((token) => token.tag === "STRING");
  const hasNumberAndText = taggedTokens.some(
    (token) => token.tag === "NUMBER_AND_TEXT",
  );

  const hasPayments = hasNumbers || hasMoney || hasNumberAndText;
  const hasClaims = hasNumbers || hasMoney || hasProcedures || hasArbitraryText;
  const hasPatients = hasArbitraryText;

  const results: SearchResultItem[] = [];

  if (!skipPayments && hasPayments) {
    const payments = await searchPayments(taggedTokens);
    results.push(...payments);
  }

  if (!skipClaims && hasClaims) {
    const claims = await searchClaims(taggedTokens);
    results.push(...claims);
  }

  if (!skipPatients && hasPatients) {
    const patients = await searchPatients(taggedTokens);
    results.push(...patients);
  }

  return results;
}

async function searchPayments(
  tokens: { token: string; tag: ReturnType<typeof tagToken> }[],
) {
  const selectedPractice = LocalStorage.get("selectedPractice");

  if (!selectedPractice) {
    throw new Error("Selected practice not found");
  }

  if (!tokens?.length) {
    return [];
  }

  // Separate tokens by type
  const numericTokens = tokens
    .filter((t) => t.tag === "NUMBER" || t.tag === "NUMBER_AND_TEXT")
    .map((t) => t.token);
  const stringTokens = tokens
    .filter((t) => t.tag === "STRING")
    .map((t) => t.token);

  // Start building the query parts
  const withClauses = [];
  const whereConditions = [sql`practiceUuid = ${selectedPractice}`];

  // Handle numeric tokens (payment numbers)
  if (numericTokens.length) {
    withClauses.push(sql`
      ranked_payments AS (
        SELECT paymentNumber,
        CASE
          WHEN paymentNumber = ${numericTokens[0]} THEN 3
          WHEN paymentNumber LIKE ${`%${numericTokens[0]}%`} THEN 2
          WHEN paymentNumber LIKE ${`%${numericTokens[0]}%`} THEN 1
          ELSE 0
        END as match_rank
        FROM eobPayments
        WHERE 
          paymentNumber = ${numericTokens[0]}
          OR paymentNumber LIKE ${`%${numericTokens[0]}%`}
          OR paymentNumber LIKE ${`%${numericTokens[0]}%`}
      )
    `);
    whereConditions.push(sql`
      EXISTS (
        SELECT 1 FROM ranked_payments rp 
        WHERE rp.paymentNumber = ep.paymentNumber
      )
    `);
  }

  // Handle string tokens (payer names)
  if (stringTokens.length) {
    withClauses.push(sql`
      payer_matches AS (
        SELECT DISTINCT payerName
        FROM eob_payments_payer_name_fts
        WHERE ${sql.join(
          stringTokens.map((token) => sql`payerName MATCH ${token}`),
          sql` AND `,
        )}
      )
    `);
    whereConditions.push(sql`
      EXISTS (
        SELECT 1 FROM payer_matches 
        WHERE payer_matches.payerName = ep.payerName
      )
    `);
  }

  // Construct the full query
  const query = sql`
    WITH ${sql.join(withClauses, sql`, `)}
    SELECT 
      ep.*,
      ${
        numericTokens.length
          ? sql`
     COALESCE((
       SELECT match_rank 
       FROM ranked_payments 
       WHERE paymentNumber = ep.paymentNumber
     ), 0)
   `
          : sql`0`
      } as score
    FROM eobPayments ep
    ${
      numericTokens.length
        ? sql`LEFT JOIN ranked_payments rp ON rp.paymentNumber = ep.paymentNumber`
        : sql``
    }
    WHERE ${sql.join(whereConditions, sql` AND `)}
    ORDER BY 
      ${numericTokens.length ? sql`match_rank DESC,` : sql``}
      ep.paymentNumber
  `;

  const results = await db.values(query);

  const columnNames = Object.keys(Tables.eobPayments).concat(["score"]);

  const mapped = results
    .map((row) =>
      Object.fromEntries(columnNames.map((name, i) => [name, row[i]])),
    )
    .map((row) => ({
      type: "payment" as const,
      item: row,
      score: row.score,
    }));

  return mapped as SearchResultItem[];
}
/**
 * Search EOB claims with ranked results:
 * 1. Exact claim number matches
 * 2. Suffix matches
 * 3. Substring/prefix matches
 * AND matching with patient name if provided
 */
async function searchClaims(
  tokens: { token: string; tag: ReturnType<typeof tagToken> }[],
) {
  const selectedPractice = LocalStorage.get("selectedPractice");

  if (!selectedPractice) {
    throw new Error("Selected practice not found");
  }

  if (!tokens?.length) {
    return [];
  }

  const numericTokens = tokens
    .filter((t) => t.tag === "NUMBER" || t.tag === "NUMBER_AND_TEXT")
    .map((t) => t.token);
  const stringTokens = tokens
    .filter((t) => t.tag === "STRING")
    .map((t) => t.token);

  const conditions = [sql`ec.practiceUuid = ${selectedPractice}`];

  // Simple direct approach for claim number ranking
  if (numericTokens.length) {
    conditions.push(sql`
      (
        claimNumber = ${numericTokens[0]} OR 
        claimNumber LIKE ${`%${numericTokens[0]}%`} OR 
        claimNumber LIKE ${`%${numericTokens[0]}%`}
      )
    `);
  }

  // Patient name matching
  if (stringTokens.length) {
    conditions.push(sql`
      EXISTS (
        SELECT 1 FROM eob_claims_patient_name_fts 
        WHERE patientName MATCH ${stringTokens.join(" AND ")}
        AND patientName = ec.patientName
      )
    `);
  }

  const query = sql`
    SELECT 
      ec.*,
      CASE
        WHEN claimNumber = ${numericTokens[0] || ""} THEN 3
        WHEN claimNumber LIKE ${`%${numericTokens[0] || ""}%`} THEN 2
        WHEN claimNumber LIKE ${`%${numericTokens[0] || ""}%`} THEN 1
        ELSE 0
      END as score,
      ep.payerName,
      ep.payerGroupId
    FROM eobClaims ec
    LEFT JOIN eobPayments ep ON ep.id = ec.paymentId
    WHERE ${sql.join(conditions, sql` AND `)}
    ORDER BY score DESC, claimNumber
    LIMIT 100
  `;

  const results = await db.values(query);

  const columnNames = Object.keys(Tables.eobClaims).concat([
    "score",
    "payerName",
    "payerGroupId",
  ]);

  const mapped = results
    .map((row) =>
      Object.fromEntries(columnNames.map((name, i) => [name, row[i]])),
    )
    .map((row) => ({
      type: "claim" as const,
      item: {
        ...row,
        payerName: row.payerName,
        payerGroupId: row.payerGroupId,
      },
      score: row.score,
    }));

  return mapped as SearchResultItem[];
}

async function searchPatients(
  tokens: { token: string; tag: ReturnType<typeof tagToken> }[],
) {
  const selectedPractice = LocalStorage.get("selectedPractice");

  if (!selectedPractice) {
    throw new Error("Selected practice not found");
  }

  if (!tokens?.length) {
    return [];
  }

  const hasNonStringTokens = tokens.some((t) => t.tag !== "STRING");

  const nameTokens = tokens
    .filter((t) => t.tag === "STRING")
    .map((t) => t.token);

  if (!nameTokens.length) return [];

  const query = sql`
    SELECT p.*, 1 as match_rank
    FROM patients p
    WHERE EXISTS (
      SELECT 1 
      FROM patients_fts 
      WHERE patients_fts.full_name = (p.firstName || ' ' || p.lastName)
        AND ${sql.join(
          nameTokens.map((token) => sql`full_name MATCH ${token}`),
          sql` AND `,
        )}
        AND p.practiceUuid = ${selectedPractice}
    )
    ORDER BY p.lastName, p.firstName
  `;

  const results = await db.values(query);
  const columnNames = Object.keys(Tables.patients);

  const mapped = results
    .map((row) =>
      Object.fromEntries(columnNames.map((name, i) => [name, row[i]])),
    )
    .map((row) => ({
      type: "patient" as const,
      item: row,
      score: hasNonStringTokens ? 1 : 9999,
    }));

  return mapped as SearchResultItem[];
}

function tokenizedQuery(query: string) {
  const tokens = query.split(/[\s\n]+/);
  const taggedTokens = tokens.map((token, index) => ({
    token,
    tag: tagToken(token),
  }));

  // Combine adjacent STRING tokens
  const combinedTokens = taggedTokens.reduce(
    (acc, current, index) => {
      if (current.tag === "STRING") {
        // If there's a previous STRING token, combine with it
        if (acc.length > 0 && acc[acc.length - 1].tag === "STRING") {
          acc[acc.length - 1].token += ` ${current.token}`;
          return acc;
        }
      }
      // Add non-STRING tokens or first STRING token as is
      acc.push(current);
      return acc;
    },
    [] as { token: string; tag: ReturnType<typeof tagToken> }[],
  );

  return combinedTokens;
}

function tagToken(token: string) {
  const isNumber = !Number.isNaN(Number(token));
  const isMoney =
    token.startsWith("$") && !Number.isNaN(Number(token.slice(1)));

  if (isNumber) {
    return "NUMBER" as const;
  }

  if (isMoney) {
    return "MONEY" as const;
  }

  if (/(?=.*\d)(?=.*[a-zA-Z])/.test(token)) {
    return "NUMBER_AND_TEXT" as const;
  }

  if (isPaymentMethod(token)) {
    return "PAYMENT_METHOD" as const;
  }

  if (token.match(/^D\d{4}$/)) {
    return "PROCEDURE" as const;
  }

  return "STRING" as const;
}

function isPaymentMethod(token: string) {
  const normalized = token.toUpperCase();

  if (normalized === "CHECK") {
    return "CHECK" as const;
  }

  if (normalized === "ACH" || normalized === "EFT") {
    return "ACH" as const;
  }

  if (normalized === "VCC") {
    return "VCC" as const;
  }

  return null;
}

export async function handleAlerts(
  payload: DiskAction.Alerts["options"],
): Promise<DiskAction.Alerts["response"]> {
  return { alerts: [] };
}
