import { type VariantProps, cva, cx } from "class-variance-authority";
import { Button, type ButtonProps } from "../button/button";

const emptyStateVariants = cva("flex flex-col items-center justify-center", {
  variants: {
    intent: {
      brand:
        "[&_[data-icon-bg]]:bg-brand-100 [&_[data-icon-bg]]:text-brand-600",
      info: "[&_[data-icon-bg]]:bg-blue-100 [&_[data-icon-bg]]:text-blue-500",
      danger: "[&_[data-icon-bg]]:bg-red-100 [&_[data-icon-bg]]:text-red-500",
      muted:
        "[&_[data-icon-bg]]:bg-slate-100 [&_[data-icon-bg]]:text-slate-500",
    },
    size: {
      md: "[&>div]:space-y-6 [&_h2]:text-xl [&_p]:text-base",
      lg: "[&>div]:space-y-8 [&_h2]:text-3xl [&_p]:text-lg",
    },
  },
  defaultVariants: {
    intent: "muted",
    size: "md",
  },
});

export interface EmptyStateProps
  extends VariantProps<typeof emptyStateVariants> {
  className?: string;
  icon?: React.ReactNode;
  title: string;
  description?: string;
  actions?: ButtonProps<"button">[];
}

export function EmptyState({
  className,
  icon,
  title,
  description,
  actions,
  intent,
  size = "md",
}: EmptyStateProps) {
  return (
    <div
      className={cx(emptyStateVariants({ intent, size }), className)}
      data-sentry-unmask
    >
      <EmptyStateIcon icon={icon} />
      <div className="w-full max-w-md">
        <div className="text-center">
          <h2 className="mt-4 font-bold text-3xl text-slate-700 leading-tight">
            {title}
          </h2>
          {description && (
            <p
              className="mt-3 mb-1 text-balance font-medium text-lg text-slate-500"
              data-sentry-unmask
            >
              {description}
            </p>
          )}
        </div>
        <EmptyStateActionTray
          actions={actions}
          intent={intent}
          size={size ?? "md"}
        />
      </div>
    </div>
  );
}

function EmptyStateIcon({ icon }: { icon?: React.ReactNode }) {
  if (!icon) return null;

  return (
    <div
      className="flex h-20 w-20 items-center justify-center rounded-full"
      data-icon-bg
    >
      {icon}
    </div>
  );
}

function EmptyStateActionTray({
  actions,
  intent,
  size,
}: {
  actions?: ButtonProps<"button">[];
  intent: VariantProps<typeof emptyStateVariants>["intent"];
  size: VariantProps<typeof emptyStateVariants>["size"];
}) {
  if (!actions) return null;

  return (
    <div className="!mt-5 !gap-3 mb-4 flex items-center justify-center">
      {actions.map((action, idx) => (
        <Button
          {...createActionProps(action, idx, intent, size)}
          key={action.children?.toString() || idx}
        />
      ))}
    </div>
  );
}

/**
 * Default props for actions in the EmptyStateActionTray based on
 * their index and passed props.
 */
function createActionProps(
  action: ButtonProps<"button">,
  idx: number,
  containerIntent: VariantProps<typeof emptyStateVariants>["intent"] = "brand",
  size: VariantProps<typeof emptyStateVariants>["size"] = "md",
): ButtonProps<"button"> {
  const isFirstAction = idx === 0;

  const variant = isFirstAction ? "primary" : "ghost";
  const intent = containerIntent !== "brand" ? containerIntent : "default";

  const btnSize = size === "md" ? "default" : "lg";

  return {
    size: btnSize,
    variant,
    intent,
    ...action,
  };
}
