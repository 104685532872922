import type { Client } from "../lib/client-types";
import { CLAIM_TYPE_ORDER } from "./selectors";

/**
 * All procedures that overlap on any of their claims
 */
interface RelatedGroup {
  claims: Client.Claim[];
  procedures: Client.Procedure[];
}

function getClaimDate(claim: Client.Claim) {
  return claim.receivedDate ?? claim.sentDate ?? claim.createdAt;
}

function claimSorter(a: Client.Claim, b: Client.Claim) {
  const dateA = getClaimDate(a);
  const dateB = getClaimDate(b);

  if (dateA === dateB) {
    return (
      CLAIM_TYPE_ORDER.indexOf(a.claimType) -
      CLAIM_TYPE_ORDER.indexOf(b.claimType)
    );
  }

  return new Date(dateA).getTime() - new Date(dateB).getTime();
}

type Node = {
  type: "procedure" | "claim";
  id: string;
  data: Client.Procedure | Client.Claim;
};

type Edge = {
  source: string;
  target: string;
  type: "claim-reference" | "same-date";
};

class VisitGraph {
  private nodes = new Map<string, Node>();
  private adjacencyList = new Map<string, Set<string>>();

  addNode(node: Node) {
    this.nodes.set(node.id, node);
    if (!this.adjacencyList.has(node.id)) {
      this.adjacencyList.set(node.id, new Set());
    }
  }

  addEdge(source: string, target: string) {
    this.adjacencyList.get(source)?.add(target);
    this.adjacencyList.get(target)?.add(source);
  }

  private dfs(start: string, visited: Set<string>, component: Set<string>) {
    visited.add(start);
    component.add(start);

    const neighbors = this.adjacencyList.get(start) || new Set();
    for (const neighbor of neighbors) {
      if (!visited.has(neighbor)) {
        this.dfs(neighbor, visited, component);
      }
    }
  }

  findConnectedComponents(): Node[][] {
    const visited = new Set<string>();
    const components: Node[][] = [];

    for (const nodeId of this.nodes.keys()) {
      if (!visited.has(nodeId)) {
        const component = new Set<string>();
        this.dfs(nodeId, visited, component);

        const componentNodes = Array.from(component)
          .map((id) => this.nodes.get(id))
          .filter((n): n is Node => n !== undefined);

        components.push(componentNodes);
      }
    }

    return components;
  }
}

export function combineClaimsAndProcedures(
  claims: Client.Claim[],
  procedures: Client.Procedure[],
): RelatedGroup[] {
  if (procedures.length === 0) {
    return [];
  }

  // Filter out procedures with no claims
  const validProcedures = procedures.filter(
    (p) => p.claimIds && p.claimIds?.length > 0,
  );

  // If no procedures have claims, return empty
  if (validProcedures.length === 0) {
    return [];
  }

  const graph = new VisitGraph();

  // Add all nodes
  procedures.forEach((proc) => {
    graph.addNode({
      type: "procedure",
      id: `proc-${proc.id}`,
      data: proc,
    });
  });

  claims.forEach((claim) => {
    graph.addNode({
      type: "claim",
      id: `claim-${claim.id}`,
      data: claim,
    });
  });

  // Add edges for claim references
  procedures.forEach((proc) => {
    proc.claimIds?.forEach((claimId) => {
      graph.addEdge(`proc-${proc.id}`, `claim-${claimId}`);
    });
  });

  // Add edges for same-date procedures
  const procsByDate = new Map<string, string[]>();
  procedures.forEach((proc) => {
    const date = proc.dateOfService;
    if (!procsByDate.has(date)) {
      procsByDate.set(date, []);
    }
    procsByDate.get(date)?.push(proc.id);
  });

  // Connect all procedures on same date
  for (const procs of procsByDate.values()) {
    for (let i = 0; i < procs.length; i++) {
      for (let j = i + 1; j < procs.length; j++) {
        graph.addEdge(`proc-${procs[i]}`, `proc-${procs[j]}`);
      }
    }
  }

  // Find connected components and convert to RelatedGroups
  return graph.findConnectedComponents().map((component) => {
    const procedures = component
      .filter((n) => n.type === "procedure")
      .map((n) => n.data as Client.Procedure);

    const claims = component
      .filter((n) => n.type === "claim")
      .map((n) => n.data as Client.Claim)
      .sort(claimSorter);

    return { claims, procedures };
  });
}
