import { getDateLabel } from "@lassie/ui/date-picker";
import { getDateRangeLabel } from "@lassie/ui/date-range-input";
import type { HistoryLocation, ReactNode } from "@tanstack/react-router";
import { desc, inArray } from "drizzle-orm";
import {
  DATE_RANGE_SCHEMA,
  POSTED_FILTERS_SCHEMA,
} from "../../context/posted-context";
import { db } from "../drizzle/db";
import { Tables } from "../drizzle/schema";
import { formatPaymentMethod } from "../string";

/**
 * Paths that have specific breadcrumbs/cannot inherit from previous paths
 */
const ROOT_PATHS = [
  "/posted",
  "/search",
  "/patients",
  "/patients/tasks",
  "/patients/tasks/file-secondary",
  "/patients/tasks/refile",
  "/patients/tasks/adjust",
  "/patients/tasks/review",
  "/patients/tasks/done",
  "/patients/balances",
  "/patients/credits",
  "/payments",
  "/payments/needs-review",
  "/payments/missing-transaction",
  "/payments/paper-checks",
  "/insurance-logins",
  "/bank-accounts",
] as const;

/** models that can be breadcrumb roots */
type RootModel =
  | "Patients"
  | "Insurance Logins"
  | "Bank Accounts"
  | "Payments"
  | "Posted"
  | "Search"
  | "Patient Balances";
/** models that can be breadcrumb paths */
type AllModels = RootModel | "Visits";

function getLabelFromRootPath(path: string) {
  if (path === "/posted") {
    return "Posted";
  }

  if (path.startsWith("/patients")) {
    return "Patients";
  }

  if (path.startsWith("/payments")) {
    return "Payments";
  }

  if (path.startsWith("/search")) {
    return "Search";
  }

  if (path.startsWith("/insurance-logins")) {
    return "Insurance Logins";
  }

  if (path.startsWith("/bank-accounts")) {
    return "Bank Accounts";
  }

  return path;
}

function getToFromRootPath(path: string) {
  if (path === "/posted") {
    return null;
  }

  if (path.startsWith("/patients")) {
    return "/patients";
  }

  if (path.startsWith("/payments")) {
    return "/payments";
  }

  return null;
}

function getDateLabelFromSearchParams(search: string) {
  const searchParams = new URLSearchParams(search);
  const { dateRange: dateRangeStr, reportInterval } =
    POSTED_FILTERS_SCHEMA.parse(Object.fromEntries(searchParams.entries()));

  const dateRange = DATE_RANGE_SCHEMA.parse(dateRangeStr);

  if (reportInterval === "Daily") {
    return getDateLabel(dateRange.from, true);
  }

  return getDateRangeLabel(
    dateRange,
    reportInterval === "Weekly" ? "week" : "month",
    true,
  );
}

function getSearchLabelFromSearchParams(search: string) {
  const searchParams = new URLSearchParams(search);
  const query = searchParams.get("q");

  if (!query) {
    return "Results";
  }

  if (query.startsWith('"') && query.endsWith('"')) {
    return query.slice(1, -1);
  }

  return query;
}

export type Crumb = {
  to: string | null;
  label: ReactNode;
};

/**
 * Wraps \@tanstack/history to provide browsing context for users.
 *
 * @example
 * ```ts
 * // outside react
 * const historyManager = new HistoryManager();
 * // in router root component
 * const { history } = useRouter();
 *
 * useEffect(() => {
 *    const unsubscribe = router.subscribe((state) => {
 *      historyManager.push(state.location);
 *    });
 *
 *    return () => unsubscribe();
 * }, []);
 * ```
 */
export class HistoryManager {
  constructor(
    /** history stored like `[oldest, ..., newest]` */
    private history: HistoryLocation[] = [],
  ) {}

  flush() {
    this.history = [];
  }

  push(location: HistoryLocation) {
    this.history.push(location);
  }

  async getHistoryBreadcrumbs(): Promise<Crumb[]> {
    // this.getHistoryBreadcrumbs2();

    let locations: (HistoryLocation & {
      root: boolean;
      model: AllModels | null;
    })[] = [];

    for (let i = 0; i < this.history.length; i++) {
      const location = this.history[i];

      if (this.isResetPathname(location)) {
        // we can stop looking for more paths
        locations = [
          {
            ...location,
            root: true,
            model: this.getModel(location),
          },
        ];
      }

      if (this.isSkipPath(location)) {
        continue;
      }

      const existingIndex = locations.findIndex(
        (l) => l.pathname === location.pathname,
      );

      if (existingIndex !== -1) {
        // if the pathname already exists, we truncate the history
        locations = locations.slice(0, existingIndex + 1);
      } else {
        locations.push({
          ...location,
          root: false,
          model: this.getModel(location),
        });
      }
    }

    if (!locations.length) {
      const currentLocation = {
        pathname: window.location.pathname,
        state: {},
        href: window.location.href,
        hash: window.location.hash,
        search: window.location.search,
      };
      locations = [
        {
          ...currentLocation,
          root: true,
          model: this.getModel(currentLocation),
        },
      ];
    }

    const [resetLocation, ...otherLocations] = locations;

    let finalLocations = otherLocations;
    const patientLocations = finalLocations.filter(
      (location) => location.model === "Patients",
    );

    logger.info("patientLocations", patientLocations);

    if (patientLocations.length > 1) {
      // if there are multiple patient locations, keep only the first and last
      const firstPatientLocation = patientLocations[0];
      const lastPatientLocation = patientLocations[patientLocations.length - 1];

      if (firstPatientLocation === lastPatientLocation) {
        // if the first and last patient locations are the same, keep only that one
        finalLocations = finalLocations.filter(
          (location) =>
            location.model !== "Patients" || location === firstPatientLocation,
        );
      } else {
        // keep non-patient locations, plus first and last patient locations
        finalLocations = finalLocations.filter(
          (location) =>
            location.model !== "Patients" ||
            location === firstPatientLocation ||
            location === lastPatientLocation,
        );
      }
    }

    const paymentLocations = finalLocations.filter(
      (location) =>
        location.model === "Payments" && location.pathname.includes("py_"),
    );

    if (paymentLocations.length > 1) {
      // if there are multiple payment locations, keep only the last
      const lastPaymentLocation = paymentLocations[paymentLocations.length - 1];

      finalLocations = finalLocations.filter(
        (location) =>
          (location.model !== "Payments" &&
            !location.pathname.includes("py_")) ||
          location.pathname === lastPaymentLocation.pathname,
      );
    }

    const unhydratedBreadcrumbs = finalLocations.map((location) => ({
      to: location.pathname,
      model: location.model,
      label: location.pathname,
    }));

    const breadcrumbs = await this.hydrateBreadcrumbs(unhydratedBreadcrumbs);

    return [
      ...(![
        "/payments",
        "/payments/paper-checks",
        "/payments/needs-review",
        "/payments/missing-transaction",
      ].includes(resetLocation.pathname)
        ? [
            {
              to: getToFromRootPath(resetLocation.pathname),
              label: getLabelFromRootPath(resetLocation.pathname),
            },
          ]
        : []),
      ...(resetLocation.pathname.startsWith("/patients/tasks")
        ? [
            {
              to: resetLocation.href,
              label: "Tasks",
            },
          ]
        : []),
      ...(resetLocation.pathname.startsWith("/patients/balances")
        ? [
            {
              to: resetLocation.href,
              label: "Balances",
            },
          ]
        : []),
      ...(resetLocation.pathname.startsWith("/patients/credits")
        ? [
            {
              to: resetLocation.href,
              label: "Credits",
            },
          ]
        : []),
      ...([
        "/payments",
        "/payments/paper-checks",
        "/payments/needs-review",
        "/payments/missing-transaction",
      ].includes(resetLocation.pathname)
        ? [
            {
              to: resetLocation.href,
              label: "Payments",
            },
          ]
        : []),
      ...(resetLocation.model === "Posted"
        ? [
            {
              to: resetLocation.href,
              label: getDateLabelFromSearchParams(resetLocation.search),
            },
          ]
        : []),
      ...(resetLocation.model === "Search"
        ? [
            {
              to: resetLocation.href,
              label: getSearchLabelFromSearchParams(resetLocation.search),
            },
          ]
        : []),
      ...breadcrumbs,
    ];
  }

  private async hydrateBreadcrumbs(
    breadcrumbs: (Crumb & {
      model: AllModels | null;
    })[],
  ) {
    const patientIds = breadcrumbs
      .filter((crumb) => crumb.model === "Patients")
      .map((crumb) => crumb.to?.match(/epat_\w+/)?.[0])
      .filter((crumb) => crumb !== undefined);

    const familyIds = breadcrumbs
      .filter((crumb) => crumb.model === "Patient Balances")
      .map((crumb) => crumb.to?.match(/efam_\w+/)?.[0])
      .filter((crumb) => crumb !== undefined);

    const paymentIds = breadcrumbs
      .filter((crumb) => crumb.model === "Payments")
      .map((crumb) => crumb.to?.match(/py_\w+/)?.[0])
      .filter((crumb) => crumb !== undefined);

    const patients = await db
      .select({
        id: Tables.patients.id,
        firstName: Tables.patients.firstName,
        lastName: Tables.patients.lastName,
      })
      .from(Tables.patients)
      .where(inArray(Tables.patients.id, patientIds));

    const familyPatients = await db
      .select({
        id: Tables.patients.id,
        firstName: Tables.patients.firstName,
        lastName: Tables.patients.lastName,
      })
      .from(Tables.patients)
      .where(inArray(Tables.patients.familyId, familyIds))
      .orderBy(desc(Tables.patients.isGuarantor))
      .limit(1);

    const payments = await db
      .select({
        id: Tables.eobPayments.id,
        paymentMethod: Tables.eobPayments.paymentMethod,
        paymentNumber: Tables.eobPayments.paymentNumber,
      })
      .from(Tables.eobPayments)
      .where(inArray(Tables.eobPayments.id, paymentIds));

    const hydratedBreadcrumbs = breadcrumbs.map((crumb) => {
      if (crumb.model === "Patients") {
        const patient = patients.find(
          (patient) => patient.id === crumb.to?.match(/epat_\w+/)?.[0],
        );

        if (!patient) {
          return {
            ...crumb,
            label: "Error Loading Patient",
          };
        }

        return {
          ...crumb,
          label: `${patient.firstName} ${patient.lastName}`,
        };
      }

      if (crumb.model === "Patient Balances") {
        const family = familyPatients?.[0];

        if (!family) {
          return {
            ...crumb,
            label: "Error Loading Family",
          };
        }

        return {
          ...crumb,
          label: `${family.lastName} Family`,
        };
      }

      if (crumb.model === "Payments") {
        const payment = payments.find(
          (payment) => payment.id === crumb.to?.match(/py_\w+/)?.[0],
        );

        if (!payment) {
          return {
            ...crumb,
            label: "Error Loading Payment",
          };
        }

        return {
          ...crumb,
          label: `${formatPaymentMethod(payment.paymentMethod)} ${
            payment.paymentNumber
          }`,
        };
      }

      return crumb;
    });

    return hydratedBreadcrumbs;
  }

  /**
   * Check if pathname resets the breadcrumbs.
   *
   * If there is only one slash (e.g. `/posted`), it means the pathname is a root path.
   * Otherwise (e.g. `/payments/123`), it means the pathname is a nested path.
   *
   * @param pathname
   * @returns
   */
  private isResetPathname(path: HistoryLocation) {
    return (
      ROOT_PATHS.some((rootPath) => path.pathname === rootPath) ||
      path.pathname.split("").filter((char) => char === "/").length === 1
    );
  }

  /**
   * Check if path is a skip path.
   *
   * We skip paths that don't show breadcrumbs. E.g. visits and claims act as popups
   */
  private isSkipPath(path: HistoryLocation) {
    return (
      path.pathname.includes("/visits/") || path.pathname.includes("/claims/")
    );
  }

  private getModel(path: HistoryLocation): RootModel | null {
    if (path.pathname.startsWith("/patients/balances/")) {
      return "Patient Balances";
    }

    if (path.pathname.startsWith("/patients")) {
      return "Patients";
    }

    if (path.pathname.startsWith("/payments")) {
      return "Payments";
    }

    if (path.pathname.startsWith("/posted")) {
      return "Posted";
    }

    if (path.pathname.startsWith("/insurance-logins")) {
      return "Insurance Logins";
    }

    if (path.pathname.startsWith("/search")) {
      return "Search";
    }

    return null;
  }
}
